import { Box, Container } from "@mui/material";
import ElevationScroll from "../element/elevation";
import Footer from "./Footer.component";
import Navbar from "./Navbar.component";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
  banners?: React.ReactElement;
}

const Layout = (props: Props) => {
  return (
    <div>
      <ElevationScroll {...props}>
        <Navbar />
      </ElevationScroll>
      {props.banners}
      <Container>
        <Box sx={{ mb: 10 }}>{props.children}</Box>
      </Container>
      <Footer />
    </div>
  );
};

export default Layout;
