import { useLocation } from "react-router-dom";
import AccountLayout from "../../components/AccountLayout.component";
import { useEffect } from "react";

function ChangePassword() {
  const { pathname } = useLocation();
  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex
    .slice(1)
    .replace("-", " ")}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  return (
    <AccountLayout>
      <>
        <h2 style={{ color: "##1C1B1F" }}>Change Password</h2>
        <p style={{ color: "#787579" }}>Let's get you started with DrugStoc</p>
        <div
          style={{
            border: "1px solid #c9c5ca",
            borderRadius: 8,
            paddingBottom: 40,
          }}
        >
          <div style={{ paddingLeft: 16, borderBottom: "1px solid #c9c5ca" }}>
            <h3 style={{ color: "##1C1B1F" }}>Password Reset</h3>
          </div>
          <div style={{ paddingLeft: 16 }}>
            <h3 style={{ color: "##1C1B1F" }}>
              <label htmlFor="password">New Password</label>
            </h3>
            <div>
              <input
                className="inputNewPassword"
                type="password"
                name=""
                id="password"
                style={{
                  border: "1px solid #C9C5CA",
                  borderRadius: 8,
                  height: 45,
                  width: "calc(100% - 16px)",
                  textIndent: 10,
                }}
              />
            </div>
          </div>
          <div style={{ paddingLeft: 16 }}>
            <h3 style={{ color: "##1C1B1F" }}>
              <label htmlFor="confirmPassword">Confirm Password</label>
            </h3>
            <div>
              <input
                className="inputConfirmPassword"
                type="password"
                name=""
                id="confirmPassword"
                style={{
                  border: "1px solid #C9C5CA",
                  borderRadius: 8,
                  height: 45,
                  width: "calc(100% - 16px)",
                  textIndent: 10,
                }}
              />
            </div>
          </div>
        </div>
      </>
    </AccountLayout>
  );
}

export default ChangePassword;
