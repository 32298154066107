import { Alert, Box, Divider, Stack } from "@mui/material";
import AccountLayout from "../../components/AccountLayout.component";
import { BUSINESS_PROFILE, USER_PROFILE } from "../../constants/_api.constant";
import Cards from "../../element/card";
import { Content, Title } from "../../styles";
import { useConnect } from "../../utilities/connectHooks";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { Link as Ln } from "react-router-dom";
import { useEffect } from "react";

function AccountHome() {
  const navigate = useNavigate();
  const { data: personal } = useConnect({ path: USER_PROFILE });
  const handleClick = () => {
    navigate("/account/shipping_address");
  };
  const { data: business } = useConnect({ path: BUSINESS_PROFILE });

  const { pathname } = useLocation();
  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1)}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  const checkLicence =
    business?.category === "pharmacy"
      ? "Please Upload your Premise License For The Purpose of Industry Regulations"
      : business?.category === "doctors-office"
      ? "Please Upload your Premise License For The Purpose of Industry Regulations"
      : business?.category === "hospital"
      ? "Please Upload your Premise License For The Purpose of Industry Regulations"
      : business?.category === "nursing-home"
      ? "Please Upload your Premise License For The Purpose of Industry Regulations"
      : business?.category === "private-patient"
      ? "Please Upload your Premise License For The Purpose of Industry Regulations"
      : business?.category === "doctor"
      ? "Please Upload your Practice License For The Purpose of Industry Regulations"
      : business?.category === "nurse"
      ? "Please Upload your Practice License For The Purpose of Industry Regulations"
      : business?.category === "pharmacist"
      ? "Please Upload your Practice License For The Purpose of Industry Regulations"
      : "Please We Are Reviewing Your Premise License";
  const check =
    business?.category === "pharmacy"
      ? "Please Upload your Practice License For The Purpose of Industry Regulations"
      : business?.category === "doctors-office"
      ? "Please Upload your Practice License For The Purpose of Industry Regulations"
      : business?.category === "hospital"
      ? "Please Upload your Practice License For The Purpose of Industry Regulations"
      : business?.category === "nursing-home"
      ? "Please Upload your Practice License For The Purpose of Industry Regulations"
      : business?.category === "private-patient"
      ? "Please Upload your Practice License For The Purpose of Industry Regulations"
      : "Please We Are Reviewing Your Premise License";

  const styleLicense = {
    display: business?.premise_license ? "none" : "block",
  };

  return (
    <div id="personalInfo">
      <AccountLayout>
        <Box sx={{ mb: "40px" }}>
          <Box sx={{ marginBottom: 5 }}>
            <Title variant="h4" sx={{ paddingTop: 1, paddingBottom: 1 }}>
              Welcome to your account
            </Title>
            <Content>Let's get you started with DrugStoc</Content>
          </Box>
          {!business?.verified_date ? (
            !business?.is_verified ? (
              <Box>
                {/* <Content>Before you place your order</Content> */}
                {business?.premise_license == null ? (
                  <Ln to="/account/business">
                    <Alert
                      sx={{ marginBottom: 4, marginTop: 1 }}
                      severity="error"
                    >
                      {checkLicence}
                    </Alert>
                  </Ln>
                ) : (
                  <Ln to="/account/business">
                    <Alert
                      sx={{ marginBottom: 4, marginTop: 1 }}
                      severity="warning"
                    >
                      Please We Are Reviewing Your Premise License
                    </Alert>
                  </Ln>
                )}
                {business?.practice_license === null ? (
                  <Ln to="/account/business">
                    <Alert
                      sx={{ marginBottom: 4, marginTop: 1 }}
                      severity="error"
                    >
                      Please Upload your Practice License For the Purpose of
                      Industry Regulations
                    </Alert>
                  </Ln>
                ) : (
                  <Ln to="/account/business">
                    <Alert
                      sx={{ marginBottom: 4, marginTop: 1 }}
                      severity="warning"
                    >
                      Please We Are Reviewing Your Practice License
                    </Alert>
                  </Ln>
                )}
              </Box>
            ) : null
          ) : (
            <>
              <Ln to="/account/business">
                <Alert
                  sx={{ marginBottom: 4, marginTop: 1 }}
                  severity="warning"
                >
                  Your Premise License is being verified...
                </Alert>
              </Ln>
              <Ln to="/account/business">
                <Alert
                  sx={{ marginBottom: 4, marginTop: 1 }}
                  severity="warning"
                >
                  Your Practice License is being verified...
                </Alert>
              </Ln>
            </>
          )}

          <Cards
            title="Personal Information"
            subtitle="Addresses, contact information and password"
          >
            <Box>
              <Stack
                direction="row"
                spacing={2}
                divider={
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ display: { xs: "none", md: "flex" } }}
                  />
                }
                sx={{
                  justifyContent: { md: "space-between" },
                  flexDirection: { xs: "column", md: "row" },
                }}
              >
                <Box sx={{ width: "50%", marginBottom: 1 }}>
                  <Title>Email Address</Title>
                  {personal?.email ? (
                    <Content sx={{ fontSize: 12 }}>{personal?.email}</Content>
                  ) : (
                    <Content>........</Content>
                  )}
                </Box>
                <Box
                  sx={{
                    width: { xs: "100%", md: "50%" },
                    marginBottom: 1,
                    marginLeft: { xs: "0!important", md: "16px!important" },
                    borderTop: { xs: "1px solid rgba(0, 0, 0, 0.12)" },
                  }}
                >
                  <Title>Phone Number</Title>
                  {personal?.phone ? (
                    <Content sx={{ fontSize: 12 }}>{personal?.phone}</Content>
                  ) : (
                    <Content>........</Content>
                  )}
                </Box>
              </Stack>
              <Divider />
              <Stack
                spacing={2}
                sx={{
                  justifyContent: "space-between",
                  marginTop: 2,
                  xs: {
                    alignItems: "flex-end",
                    flexDirection: "column",
                  },
                  sm: {
                    alignItems: "center",
                    flexDirection: "row!important",
                  },
                }}
              >
                <Box>
                  <Title>Add an Address</Title>
                  <Content sx={{ fontSize: 12 }}>
                    Adding an address allows for quicker checkout and
                    approximation of delivery times
                  </Content>
                </Box>
                <Button
                  sx={{
                    width: 150,
                    fontSize: 14,
                    padding: "0 100px",
                    borderRadius: 100,
                    height: "51px",
                  }}
                  variant="outlined"
                  disableElevation
                  onClick={handleClick}
                >
                  Address
                </Button>
              </Stack>
            </Box>
          </Cards>
          <Box sx={{ marginTop: 4 }}>
            <div className="purchase-history">
              <Cards title="Purchase History" empty></Cards>
            </div>
          </Box>
          <Box sx={{ marginTop: 4 }}>
            <Cards title="DrugStoc Credit" empty></Cards>
          </Box>
        </Box>
      </AccountLayout>
    </div>
  );
}

export default AccountHome;
