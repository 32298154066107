import { FC } from "react";
import Business from "../pages/Account/Account.Business.page";
import ChangePassword from "../pages/Account/Account.ChangePassword.page";
import Favorite from "../pages/Account/Account.Favorite.page";
import AccountHome from "../pages/Account/Account.Home.page";
import PersonalInfo from "../pages/Account/Account.PersonalInfo.page";
import PurchaseHistory from "../pages/Account/Account.PurchaseHistory.page";
import Shipping from "../pages/Account/Account.Shipping.page";
import Wallet from "../pages/Account/Account.Wallet.page";
import Cart from "../pages/Cart.page";
import Error from "../pages/Error.page";
import Home from "../pages/Home.page";
import Login from "../pages/Login.page";
import Results from "../pages/Results.page";
import Register from "../pages/Register.page";
import Manufacturer from "../pages/Manufacturer.page";
import ProductDetail from "../pages/ProductDetail.page";
import Checkout from "../pages/Checkout.page";
import Manufacturers from '../pages/Manufacturers'
import ProductManufacturers from "../pages/ProductManufacturers";

interface route {
  path: string;
  component: FC;
  protected: boolean;
}

export const routers: route[] = [
  { path: "*", component: Error, protected: false },
  { path: "", component: Home, protected: false },
  { path: "/manufacturer", component: Manufacturer, protected: false },
  { path: "/manufacturer/:slug/:page/:id", component: Manufacturers, protected: false },
  { path: "/manufacturer/:slug/:id/:slug", component: ProductManufacturers, protected: false },
  { path: "/category/:slug", component: Results, protected: false },
  { path: "/products/:slug", component: ProductDetail, protected: false },
  { path: "/search", component: Results, protected: false },
  { path: "/login", component: Login, protected: false },
  { path: "/register", component: Register, protected: false },
  { path: "/cart", component: Cart, protected: true },
  { path: "/checkout", component: Checkout, protected: true },
  { path: "/account", component: AccountHome, protected: true },
  {
    path: "/account/purchase_history",
    component: PurchaseHistory,
    protected: true,
  },
  { path: "/account/wallet", component: Wallet, protected: true },
  { path: "/account/personal_info", component: PersonalInfo, protected: true },
  { path: "/account/business", component: Business, protected: true },
  { path: "/account/shipping_address", component: Shipping, protected: true },
  { path: "/account/favorite", component: Favorite, protected: true },
  {
    path: "/account/Change-password",
    component: ChangePassword,
    protected: true,
  },
];
