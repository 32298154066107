import { Box, Pagination, PaginationItem } from "@mui/material";
import "./Account.General.css";
import {
  Badge,
  CogIcon,
  EmptyState,
  IconButton,
  majorScale,
  Menu,
  Popover,
  Position,
  SearchIcon,
  Table,
} from "evergreen-ui";
import moment from "moment";
import AccountLayout from "../../components/AccountLayout.component";
import { Content, Title } from "../../styles";
import { priceFormatDecimal } from "../../utilities/priceFormatter";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

function PurchaseHistory() {
  const [pageQuery, setPageQuery] = useState(1);
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        "https://staging.drugstoc.com/api/v2/shopping/order/sales-order",
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [pageQuery]);

  const location = useLocation();
  const param = location.pathname;
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);

  const pathArr = param?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex
    .slice(1)
    .replace("_", " ")}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  const handlePageChange = (e: any, value: any) => {
    setPageQuery(value);
  };

  const pagination = (
    <Pagination
      page={page}
      count={Math.ceil(data?.count / 100)}
      color="primary"
      shape="rounded"
      onChange={handlePageChange}
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      renderItem={(item: any) => (
        <PaginationItem
          component={Link}
          to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
          {...item}
        />
      )}
    />
  );

  return (
    <AccountLayout>
      <Box sx={{ mb: "40px" }}>
        <Title variant="h4" sx={{ paddingTop: 1, paddingBottom: 1 }}>
          Purchase History
        </Title>
        <Content>Let's get you started with DrugStoc</Content>
        <Box mt={5} sx={{ overflow: "auto" }}>
          {/* {`(min-width:600px) matches: ${matches}`} */}
          <Table style={{ width: "800px" }}>
            <Table.Head>
              <Table.TextHeaderCell>order id</Table.TextHeaderCell>
              <Table.TextHeaderCell>status</Table.TextHeaderCell>
              <Table.TextHeaderCell>NUMBER OF ITEMS</Table.TextHeaderCell>
              <Table.TextHeaderCell>total amount</Table.TextHeaderCell>
              <Table.TextHeaderCell>Date</Table.TextHeaderCell>
              <Table.TextHeaderCell>action</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body height="auto">
              {isLoading ? (
                data?.results.length === 0 ? (
                  <EmptyState
                    background="light"
                    title="No tracked events for this Source"
                    orientation="horizontal"
                    icon={<SearchIcon color="#C1C4D6" />}
                    iconBgColor="#EDEFF5"
                    description="Events appear when the Source starts sending data to Segment about your users and their activity."
                  />
                ) : (
                  data?.results.map((profile: any) => (
                    <Table.Row key={profile.id} isSelectable>
                      <Table.TextCell>{profile.order_no}</Table.TextCell>
                      <Table.TextCell>
                        <Badge
                          color={
                            profile.status === "pending" ? "blue" : "green"
                          }
                        >
                          {profile.status}
                        </Badge>
                      </Table.TextCell>
                      <Table.TextCell textAlign="center">
                        {profile.items.length} Items
                      </Table.TextCell>
                      <Table.TextCell isNumber>
                        {priceFormatDecimal(profile.total_amount)}
                      </Table.TextCell>
                      <Table.TextCell>
                        {moment(profile.created_at).fromNow()}
                      </Table.TextCell>
                      <Table.TextCell isNumber>
                        <Popover
                          position={Position.BOTTOM_RIGHT}
                          content={
                            <Menu>
                              <Menu.Group>
                                <Menu.Item
                                  onSelect={() => console.log("oooop")}
                                >
                                  Re-Order
                                </Menu.Item>
                                <Menu.Item
                                  onSelect={() => console.log("oooow")}
                                >
                                  View Details
                                </Menu.Item>
                              </Menu.Group>
                            </Menu>
                          }
                        >
                          <IconButton
                            icon={CogIcon}
                            marginRight={majorScale(2)}
                          />
                        </Popover>
                      </Table.TextCell>
                    </Table.Row>
                  ))
                )
              ) : null}
            </Table.Body>
          </Table>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
            top: "50px",
          }}
        >
          {isLoading
            ? data?.next
              ? pagination
              : data?.previous
              ? pagination
              : null
            : null}
        </Box>
      </Box>
    </AccountLayout>
  );
}

export default PurchaseHistory;
