import { Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import AccountLayout from "../../components/AccountLayout.component";
import { USER_PROFILE } from "../../constants/_api.constant";
import { PersonalDataForm } from "../../constants/_form.constant";
import Cards from "../../element/card";
import FormRender from "../../forms/formRender";
import { Content, Title } from "../../styles";
import { request, useConnect } from "../../utilities/connectHooks";
import { useEffect } from "react";

function PersonalInfo() {
  const { data } = useConnect({ path: USER_PROFILE });

  const submitData = (e: any) => {
    request.patch({ path: USER_PROFILE, trigger: USER_PROFILE, payload: e });
  };

  const { pathname } = useLocation();

  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1).replace('_', ' ')}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  return (
    <AccountLayout>
      <Box>
        <Box sx={{ marginBottom: 5 }}>
          <Title variant="h4" sx={{ paddingTop: 1, paddingBottom: 1 }}>
            Personal Info
          </Title>
          <Content>Let’s get you started with DrugStoc</Content>
        </Box>
        <Grid item xs={12} md={9}>
          <Cards title="Update personal details">
            <Box mt={2} mb={2}>
              {data ? (
                <FormRender
                  data={PersonalDataForm}
                  onSubmitForm={submitData}
                  initialState={data}
                />
              ) : null}
            </Box>
          </Cards>
        </Grid>
      </Box>
    </AccountLayout>
  );
}

export default PersonalInfo;
