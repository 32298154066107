import { Box, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import AccountLayout from "../../components/AccountLayout.component";
import { BUSINESS_PROFILE } from "../../constants/_api.constant";
import { UpdateBusinessDate } from "../../constants/_form.constant";
import Cards from "../../element/card";
import FormRender from "../../forms/formRender";
import FileUpload from "../../forms/_fileUpload";
import { Content, Title } from "../../styles";
import { request, useConnect } from "../../utilities/connectHooks";
import "./Account.Business.css";
import { useEffect } from "react";

function Business() {
  const { data } = useConnect({ path: BUSINESS_PROFILE });

  const submitData = (e: any) => {
    request.patch({
      path: BUSINESS_PROFILE,
      trigger: BUSINESS_PROFILE,
      payload: e,
    });
  };

  const { pathname } = useLocation();

  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1)}`;

  useEffect(() => {
    document.title = `${title} information | DrugStoc`;
  }, [title]);

  return (
    <AccountLayout>
      <Box sx={{ mb: 7 }}>
        <Box sx={{ marginBottom: 5 }}>
          <Title variant="h4" sx={{ paddingTop: 1, paddingBottom: 1 }}>
            Business Info
          </Title>
          <Content>Let's get you started with DrugStoc</Content>
        </Box>
        <Cards title="License Information">
          <Box mt={2}>
            <Grid container justifyContent="center" spacing={3}>
              {data?.category === "pharmacist" ? (
                <Grid item>
                  <FileUpload
                    path="/api/v2/business/profile"
                    trigger="/api/v2/business/profile"
                    initialImage={data?.practice_license}
                    name="practice_license"
                  />
                </Grid>
              ) : data?.category === "doctor" ? (
                <Grid item>
                  <FileUpload
                    path="/api/v2/business/profile"
                    trigger="/api/v2/business/profile"
                    initialImage={data?.practice_license}
                    name="practice_license"
                  />
                </Grid>
              ) : data?.category === "nurse" ? (
                <Grid item>
                  <FileUpload
                    path="/api/v2/business/profile"
                    trigger="/api/v2/business/profile"
                    initialImage={data?.practice_license}
                    name="practice_license"
                  />
                </Grid>
              ) : data?.category === "pharmacy" ? (
                <>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.practice_license}
                      name="practice_license"
                    />
                  </Grid>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.premise_license}
                      name="premise_license"
                    />
                  </Grid>
                </>
              ) : data?.category === "doctors-office" ? (
                <>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.practice_license}
                      name="practice_license"
                    />
                  </Grid>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.premise_license}
                      name="premise_license"
                    />
                  </Grid>
                </>
              ) : data?.category === "hospital" ? (
                <>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.practice_license}
                      name="practice_license"
                    />
                  </Grid>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.premise_license}
                      name="premise_license"
                    />
                  </Grid>
                </>
              ) : data?.category === "nursing-home" ? (
                <>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.practice_license}
                      name="practice_license"
                    />
                  </Grid>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.premise_license}
                      name="premise_license"
                    />
                  </Grid>
                </>
              ) : data?.category === "private-patient" ? (
                <>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.practice_license}
                      name="practice_license"
                    />
                  </Grid>
                  <Grid item>
                    <FileUpload
                      path="/api/v2/business/profile"
                      trigger="/api/v2/business/profile"
                      initialImage={data?.premise_license}
                      name="premise_license"
                    />
                  </Grid>
                </>
              ) : null}
            </Grid>
          </Box>
        </Cards>

        <Box mb={5} />
        <Cards title="Business Information">
          <Box mt={3}>
            {data ? (
              <FormRender
                data={UpdateBusinessDate}
                onSubmitForm={submitData}
                initialState={data}
              />
            ) : null}
          </Box>
        </Cards>
      </Box>
    </AccountLayout>
  );
}

export default Business;
