import { Autocomplete, Box, Link, Stack } from "@mui/material";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Content, Search, SearchIconWrapper, StyledInputBase } from "../styles";
import { SEARCH_PRODUCT } from "../constants/_api.constant";
import { useNavigate, Link as Ln, Outlet } from "react-router-dom";

function SearchBar() {
  const navigate = useNavigate();
  const [query, setQuery] = useState("");
  const [data, setData] = useState<any>();

  useEffect(() => {
    const fetchAPI = async () => {
      const res: Response = await fetch(
        `https://staging.drugstoc.com${SEARCH_PRODUCT}${query}`
      );
      const data = await res.json();
      setData(data);
    };
    fetchAPI();
  }, [query]);

  const filter = (e: any) => {
    setQuery(e.target?.value);
  };

  const search = (data: any) => {
    return data?.results.filter((item: any) =>
      item.name.toLowerCase().includes(query)
    );
  };

  function searchPage(e: any) {
    if (e.key === "Enter") {
      navigate(`/search?q=${e.target.value}`);
    }
  }

  return (
    <Autocomplete
      freeSolo
      disableClearable
      id="combo-box-demo"
      getOptionLabel={(option: any) =>
        typeof option === "string" ? option : option.name
      }
      options={data !== undefined ? search(data) : []}
      noOptionsText="No Result found!!!"
      filterOptions={(x) => x}
      onKeyDown={searchPage}
      onInputChange={filter}
      sx={{ width: "60ch", position: 'relative', zIndex: 3 }}
      renderOption={(props, option: any) => (
        <Ln to={`/products/${option.slug}`}>
          <Link
            key={option.id}
            variant="caption"
            underline="none"
            href={`/products/${option.slug}`}
          >
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 12, padding: 2 } }}
              {...props}
            >
              <img loading="lazy" width="90" src={option.image} alt="" />
              <Stack>
                <Content sx={{ color: "black", fontWeight: "700" }}>
                  {option.name}
                </Content>
                <Content sx={{ fontSize: 12 }}>{option.desc}</Content>
                <Content sx={{ fontSize: 10, fontWeight: "400" }}>
                  Category: {option.category}
                </Content>
              </Stack>
            </Box>
          </Link>
          <Outlet />
        </Ln>
      )}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params;
        return (
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              {...params.InputProps}
              {...rest}
              placeholder="Search…"
            />
          </Search>
        );
      }}
    />
  );
}

export default SearchBar;
