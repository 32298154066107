import { AddShoppingCart } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  Grid,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../components/Layout.component";
import { AddToCartButton, Content, IconButton, Title } from "../styles";
import { getResultUrl, useConnect } from "../utilities/connectHooks";
import { priceFormat } from "../utilities/priceFormatter";
import {
  FavoriteBorder,
  Refresh,
  ContentCopy,
  Share,
} from "@mui/icons-material";
import {
  BUSINESS_PROFILE,
  CART_ITEMS,
  FAVORITE_PRODUCT,
  RANDOM_PRODUCT,
} from "../constants/_api.constant";
import { Delete, Favorite } from "../element/icons";
import { request } from "../utilities/connectHooks";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import CardMedia from "@mui/material/CardMedia";
import Loading from "./Loading";
import Products from "../components/Products.component";
import axios from "axios";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterIcon,
  TwitterShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";

interface Props {
  quantity_in_cart?: any;
  is_favorite?: boolean;
  in_cart?: any;
  id?: any;
  trigger: string;
  addItemToCart?: (arg0: { quantity: number; product_id: any }) => Promise<any>;
  loadCartItem?: () => void;
  addItemToFav?: (arg0: { product_id: any }) => Promise<any>;
  deleteItemToFav?: (arg0: any) => Promise<any>;
  updateItemInCart?: (
    arg0: { quantity: any; product_id: any },
    arg1: any
  ) => Promise<any>;
  deleteItemInCart?: (arg0: any) => Promise<any>;
  slug?: any;
  image?: any;
  title?: any;
  price?: any;
  description?: any;
  quantity?: number;

  in_fav?: boolean;
  href?: any;
  manufacturer?: any;
}

const ProductDetail: any = (props: Props) => {
  const router = useLocation();
  const { url } = getResultUrl(router);
  const [isLoading, setIsLoading] = useState(false);
  const { slug } = useParams();
  const { data: detailData } = useConnect({ path: url });
  const category = detailData?.category;
  const [alternativeProducts, setAlternativeProducts] = useState([]);
  const { pathname } = useLocation();
  let path: any;
  if (document.location.origin === "http://localhost:3000") {
    path = `https://app.drugstoc.com${pathname}`;
  } else {
    path = window.location.href;
  }
  const [copy, setCopy] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [copy1, setCopy1] = useState("");
  const [isOpen1, setIsOpen1] = useState(false);
  const { data: verify } = useConnect({ path: BUSINESS_PROFILE });
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const style = {
    position: "absolute" as "absolute",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    // height: 200,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    // px: 4,
    // py: 2,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  };

  const handleCopyClick = () => {
    if (isOpen === true) {
      setIsOpen(false);
      setCopy("");
    } else if (isOpen === false) {
      setIsOpen(true);
      setCopy("Copied");
    }
  };

  const handleCopyMouseLeave = () => {
    if (isOpen === true) {
      setIsOpen(false);
      setCopy("");
    }
  };

  const handleCopyClick1 = () => {
    if (isOpen1 === true) {
      setIsOpen1(false);
      setCopy1("");
    } else if (isOpen1 === false) {
      setIsOpen1(true);
      setCopy1("URL Copied");
    }
  };

  const handleCopyMouseLeave1 = () => {
    if (isOpen1 === true) {
      setIsOpen1(false);
      setCopy1("");
      toast("Link copied to clipboard", {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  useEffect(() => {
    document.title = `${detailData?.name} | Product Detail`;
  });

  useEffect(() => {
    const fetchAPI = async () => {
      const res: any = await axios({
        method: "GET",
        url: "https://staging.drugstoc.com/api/v2/inventory/products",
      });
      setAlternativeProducts(
        res.data?.results.filter((item: any) => {
          return item.category === category;
        })
      );
      setIsLoading(true);
    };
    fetchAPI();
  }, [category, slug]);

  const [loading_increase, setLoadingIncrease] = useState(false);
  const [inFav, setFav] = useState(false);
  const [inCart, setCart] = useState(false);
  const [loading_cart, setLoadingCart] = useState(false);
  const [loading_fav, setLoadingFav] = useState(false);
  const [loading_del, setLoadingDelete] = useState(false);
  const [value, setValue] = useState(props.quantity_in_cart);
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  function add_to_cart() {
    let data = {
      quantity: 1,
      product_id: detailData?.id,
    };
    setLoadingCart(true);
    request
      .post({ path: CART_ITEMS, trigger: CART_ITEMS, payload: data })
      .then((res) => {
        if (res) {
          setLoadingIncrease(false);
          setLoadingCart(false);
          setCart(true);
        } else {
          setLoadingCart(false);
        }
      });
  }

  function add_to_fav() {
    let data = {
      product_id: props?.id,
    };
    setLoadingFav(true);
    request
      .post({ path: FAVORITE_PRODUCT, trigger: "", payload: data })
      .then(() => {
        setFav(true);
        setLoadingFav(false);
      });
  }

  function delete_fav() {
    setLoadingDelete(true);
    request
      .delete({
        path: `${FAVORITE_PRODUCT}/${props?.id}`,
        trigger: FAVORITE_PRODUCT,
      })
      .then(() => {
        setLoadingDelete(false);
      });
  }

  function increase_item() {
    setLoadingIncrease(true);
    let data = {
      quantity: Math.abs(value),
      product_id: detailData?.id,
    };
    request
      .patch({
        path: `${CART_ITEMS}/${detailData?.id}`,
        trigger: CART_ITEMS,
        payload: data,
      })
      .then((resp) => {
        if (resp) {
          setValue(value);
        }
        setLoadingIncrease(false);
      });
    const customId = "custom-id-yes";

    if (value > 1) {
      try {
        toast(`${Math.abs(value)} quantities added to Cart!`, {
          toastId: customId,
        });
      } catch {
        console.log(null);
      }
    } else if (value !== 0) {
      try {
        toast(`${Math.abs(value)} quantities added to Cart!`, {
          toastId: customId,
        });
      } catch {
        console.log(null);
      }
    }
  }

  return (
    <Layout>
      <Box mt={{ xs: 5, md: 10 }}>
        {isLoading ? (
          <Box>
            <Grid container spacing={4}>
              <Grid item md={8} xs={12} sx={{ position: "relative" }}>
                <Box
                  sx={{
                    position: "absolute",
                    top: 40,
                    right: 5,
                    cursor: "pointer",
                  }}
                >
                  <Box
                    style={{
                      visibility: isOpen ? "visible" : "hidden",
                      position: "absolute",
                      top: -20,
                      right: 2.5,
                      fontSize: 13,
                      color: "#8692A",
                    }}
                  >
                    {copy.toUpperCase()}
                  </Box>
                  <CopyToClipboard text={path}>
                    <Button
                      onClick={handleCopyClick}
                      onMouseLeave={handleCopyMouseLeave}
                      sx={{ position: "relative", zIndex: 2 }}
                    >
                      <ContentCopy />
                    </Button>
                  </CopyToClipboard>
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: 40,
                    right: 60,
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                >
                  <Button onClick={handleOpen}>
                    <Share />
                  </Button>
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Typography>Share</Typography>
                      <Box sx={style}>
                        <h3 style={{ marginTop: 16 }}>Share</h3>
                        <Box>
                          <FacebookShareButton
                            url={path}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <FacebookIcon size={32} borderRadius={8} />
                          </FacebookShareButton>
                          <TwitterShareButton
                            url={path}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <TwitterIcon size={32} borderRadius={8} />
                          </TwitterShareButton>
                          <WhatsappShareButton
                            url={path}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <WhatsappIcon size={32} borderRadius={8} />
                          </WhatsappShareButton>
                          <LinkedinShareButton
                            url={path}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <LinkedinIcon size={32} borderRadius={8} />
                          </LinkedinShareButton>
                          <EmailShareButton
                            url={path}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <EmailIcon size={32} borderRadius={8} />
                          </EmailShareButton>
                          <TelegramShareButton
                            url={path}
                            style={{ marginLeft: 4, marginRight: 4 }}
                          >
                            <TelegramIcon size={32} borderRadius={8} />
                          </TelegramShareButton>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "row",
                          }}
                        >
                          <TextField
                            id="outlined-read-only-input"
                            // label="Read Only"
                            defaultValue={path}
                            InputProps={{
                              readOnly: true,
                            }}
                            sx={{
                              width: { sm: "400px" },
                              my: 4,
                              backgroundColor: isOpen1 ? "yellow" : "#f0f0f0",
                            }}
                          />
                        </Box>
                        <Button
                          variant="contained"
                          sx={{ mb: 2 }}
                          onClick={handleCopyClick1}
                          onMouseLeave={handleCopyMouseLeave1}
                          endIcon={<ContentCopy />}
                        >
                          {isOpen1 ? copy1 : "Copy"}
                        </Button>
                      </Box>
                    </div>
                  </Modal>
                </Box>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <TransformWrapper>
                    <TransformComponent>
                      <CardMedia
                        component="img"
                        loading="lazy"
                        sx={{
                          width: "100%",
                          height: { xs: 200, md: 250 },
                          borderRadius: 2,
                          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                          cursor: "pointer",
                          objectFit: "contain",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "auto",
                        }}
                        alt={`${props.description} from ${props.manufacturer}`}
                        src={
                          detailData?.image
                            ? detailData?.image
                            : "https://cdn.dribbble.com/users/2224455/screenshots/4428927/loading.gif"
                        }
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Title
                  variant="h6"
                  sx={{
                    lineHeight: 1.4,
                  }}
                >
                  {detailData?.name ? (
                    detailData?.name
                  ) : (
                    <span
                      style={{
                        backgroundColor: "#8692a6",
                        color: "transparent",
                        borderRadius: 10,
                      }}
                    >
                      Xxxxxx xxxx x xx
                    </span>
                  )}
                </Title>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Title sx={{ whiteSpace: "break-spaces" }}>
                    SKU:{" "}
                    {detailData?.SKU ? (
                      detailData?.SKU
                    ) : (
                      <span
                        style={{
                          backgroundColor: "#8692a6",
                          color: "transparent",
                          borderRadius: 10,
                        }}
                      >
                        #xxx-xxx-xxxxxxxxxx
                      </span>
                    )}
                  </Title>
                </Content>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Title>
                    MANUFACTURER:{" "}
                    {detailData?.manufacturer ? (
                      detailData?.manufacturer
                    ) : detailData?.manufacturer !== null ? (
                      <span
                        style={{
                          backgroundColor: "#8692a6",
                          color: "transparent",
                          borderRadius: 10,
                        }}
                      >
                        XXX XXXXXXXXXXX
                      </span>
                    ) : (
                      "Unknown"
                    )}
                  </Title>
                </Content>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  DESCRIPTION:{" "}
                  {detailData?.desc ? (
                    detailData?.desc
                  ) : (
                    <span
                      style={{
                        backgroundColor: "#8692a6",
                        color: "transparent",
                        borderRadius: 10,
                      }}
                    >
                      Xxxxxxxxxx xxxxx xxxxxxxx xxx xxxxxx
                    </span>
                  )}
                </Content>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  CATEGORY:{" "}
                  {detailData?.category ? (
                    detailData?.category
                  ) : (
                    <span
                      style={{
                        backgroundColor: "#8692a6",
                        color: "transparent",
                        borderRadius: 10,
                      }}
                    >
                      XXXXXXXXX XXXXXX
                    </span>
                  )}
                </Content>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  QUANTITIES:{" "}
                  {detailData?.quantity ? (
                    detailData?.quantity
                  ) : detailData?.quantity !== 0 ? (
                    <span
                      style={{
                        backgroundColor: "#8692a6",
                        color: "transparent",
                        borderRadius: 10,
                      }}
                    >
                      xxx
                    </span>
                  ) : (
                    0
                  )}
                </Content>
                <Divider sx={{ marginTop: 2.5, marginBottom: 2.5 }} />
                <Typography
                  variant="h6"
                  component="h5"
                  sx={{
                    filter: detailData?.price ? "blur(0px)" : "blur(5px)",
                  }}
                >
                  {detailData?.price
                    ? priceFormat(detailData?.price)
                    : "PRICE NOT AVAILABLE"}
                </Typography>
                <Stack
                  direction="row"
                  sx={{ marginTop: 2, height: 40 }}
                  spacing={1}
                >
                  {detailData?.quantity_in_cart === undefined &&
                  inCart === false ? (
                    <AddToCartButton
                      variant="contained"
                      disabled={detailData?.quantity === 0}
                      onClick={add_to_cart}
                      loading={loading_cart}
                      sx={{ width: "100%", fontSize: 12, fontWeight: "900" }}
                      disableElevation
                    >
                      {detailData?.quantity === 0
                        ? "Out of Stock"
                        : "Add to cart"}
                    </AddToCartButton>
                  ) : (
                    <Stack direction="row" width="100%" spacing={3}>
                      <Tooltip title="Add to Cart">
                        <IconButton
                          onClick={add_to_cart}
                          variant="contained"
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            position: "relative",
                            "&:hover": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            },
                          }}
                        >
                          <AddShoppingCart
                            onClick={
                              verify?.is_verified ? increase_item : add_to_cart
                            }
                            sx={{
                              position: "relative",
                              marginRight: "-24px",
                              height: "30px",
                              color: "#51b0e2",
                              "&:active": {
                                backgroundColor: "#f0f0f0",
                                boxShadow: "none",
                              },
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <TextField
                        id="standard-basic"
                        type="number"
                        variant="standard"
                        value={`${
                          isNaN(value) || value === undefined
                            ? detailData?.quantity_in_cart
                            : value
                        }`}
                        InputProps={{
                          inputProps: {
                            min: "1",
                            max: `${detailData?.quantity}`,
                            step: "1",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                        sx={{ width: "100%", display: "flex" }}
                      />
                    </Stack>
                  )}

                  {!detailData?.in_fav ? (
                    <Tooltip title="Add to Favorites">
                      <IconButton
                        onClick={add_to_fav}
                        loading={loading_fav}
                        disabled={detailData?.in_fav || inFav}
                        variant="outlined"
                      >
                        {detailData?.is_favorite || inFav ? (
                          <Favorite />
                        ) : (
                          <FavoriteBorder />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      onClick={delete_fav}
                      loading={loading_del}
                      color="error"
                      variant="outlined"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2.5, marginBottom: 2.5 }} />
            <Title
              variant="h6"
              sx={{
                lineHeight: 1.4,
              }}
            >
              <>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600 }}
                  id="alternative-products"
                >
                  Alternative Products
                </Typography>
                {alternativeProducts.length !== 0 ? (
                  isLoading ? (
                    <Grid sx={{ marginTop: 1 }} container spacing={3}>
                      {alternativeProducts?.map(
                        (element: any, index: number) => (
                          <>
                            <Grid key={index} item xs={6} sm={4} md={3}>
                              <Products
                                id={element?.id}
                                title={element?.name}
                                image={element?.image}
                                description={element?.desc}
                                price={element?.price}
                                slug={element?.slug}
                                trigger={RANDOM_PRODUCT}
                                // sku={element?.SKU}
                                quantity_in_cart={element?.quantity_in_cart}
                                in_cart={element?.in_cart}
                                quantity={element?.quantity}
                                // category={element?.category}
                                is_favorite={element?.is_favorite}
                                manufacturer={element?.manufacturer}
                              />
                            </Grid>
                          </>
                        )
                      )}
                    </Grid>
                  ) : null
                ) : (
                  <>
                    <Loading />
                    <Box
                      sx={{
                        width: "100%",
                        height: "359px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#8692A6",
                      }}
                    >
                      <Box>No Item Available On This Page</Box>
                      <Box>
                        <Button
                          variant="outlined"
                          startIcon={<Refresh />}
                          href={pathname}
                        >
                          Refresh
                        </Button>
                      </Box>
                      <Box>
                        {navigator.onLine ? null : (
                          <span style={{ color: "red", fontWeight: 300 }}>
                            Internet Connection Lost
                          </span>
                        )}
                      </Box>
                    </Box>
                  </>
                )}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                    position: "relative",
                    top: "50px",
                  }}
                >
                  {/* {pagination} */}
                </Box>
              </>
            </Title>
          </Box>
        ) : (
          <Loading />
        )}
      </Box>
    </Layout>
  );
};

export default ProductDetail;
