import { Button as FullButton, Stack } from "@mui/material";
import { Form, Formik } from "formik";
import { Forms } from "../models/_form.models";
import { optionsModel } from "../models/_options.models";
import { Button } from "../styles";
import Input from "./_input";

interface Props {
  submitButtonText?: string;
  initialState?: any;
  fullButton?: boolean;
  onSubmitForm?: (e: any) => any;
  validationSchema?: object;
  options?: { name: string; data: optionsModel[] };
  data: Forms[];
}

function FormRender({
  data,
  initialState,
  options,
  fullButton = false,
  validationSchema,
  onSubmitForm = () => {},
  submitButtonText = "Save",
}: Props) {
  let d = data.map((element: Forms) =>
    !element.row
      ? element.name
      : element?.row?.items.map((element2: Forms) =>
          element.name.concat(element2.name)
        )
  );
  let m = d.flat(1).reduce((a, v) => ({ ...a, [v]: "" }), {});

  return (
    <Formik
      initialValues={initialState === undefined ? m : initialState}
      validationSchema={validationSchema}
      onSubmit={(e) => {
        onSubmitForm(e);
      }}
    >
      <Form>
        {data.map((element: Forms, index: number) =>
          !element.row ? (
            <Input
              key={index}
              label={element.label}
              name={element.name}
              placeholder={element.placeholder}
              type={element.type}
              form_type={element.form_type}
              options={
                options?.name === element.name ? options?.data : element.options
              }
            />
          ) : (
            <Stack
              key={index}
              direction="row"
              width="100%"
              spacing={2}
              sx={{
                flexDirection: { xs: "column", alignItems: "flex-end" },
              }}
            >
              {element.row.items.map((element: Forms, index2: number) => (
                <Input
                  key={index2}
                  label={element.label}
                  name={element.name}
                  placeholder={element.placeholder}
                  type={element.type}
                  form_type={element.form_type}
                  options={
                    options?.name === element.name
                      ? options?.data
                      : element.options
                  }
                />
              ))}
            </Stack>
          )
        )}
        {fullButton ? (
          <Button type="submit" variant="contained">
            {submitButtonText}
          </Button>
        ) : (
          <FullButton
            type="submit"
            disableElevation
            sx={{ paddingLeft: 9, paddingRight: 9, borderRadius: 50 }}
            variant="contained"
          >
            {submitButtonText}
          </FullButton>
        )}
      </Form>
    </Formik>
  );
}

export default FormRender;
