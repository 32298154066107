export const priceFormat = (num: number) => {
  if (num !== undefined) {
    return "₦ " + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
};

export const priceFormatDecimal = (num: number = 0) => {
  if (num !== undefined) {
    return "₦ " + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }
};
