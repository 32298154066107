import { Box, Grid, Pagination, PaginationItem } from "@mui/material";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AccountLayout from "../../components/AccountLayout.component";
import Products from "../../components/Products.component";
import { FAVORITE_PRODUCT } from "../../constants/_api.constant";
import { Content, Title } from "../../styles";
import Loading from "../Loading";

function Favorite() {
  const location = useLocation();
  const param = location.pathname;
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const [pageQuery, setPageQuery] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [favorite, setFavorite] = useState<any>();

  const handleChange = (e: any, value: any) => {
    setPageQuery(value);
  };

  const { pathname } = useLocation();

  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1)}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response: any = await fetch(
        `https://staging.drugstoc.com/api/v2/shopping/favorite/favorite-item?page=${pageQuery}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data: any = await response.json();
      setFavorite(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [pageQuery]);

  const pagination = (
    <Pagination
      page={page}
      count={Math.ceil(favorite?.count / 100)}
      color="primary"
      shape="rounded"
      onChange={handleChange}
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      renderItem={(item: any) => (
        <PaginationItem
          component={Link}
          to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
          {...item}
        />
      )}
    />
  );

  return (
    <AccountLayout>
      <Box sx={{ mb: 5 }}>
        <Title variant="h4" sx={{ paddingTop: 1, paddingBottom: 1 }}>
          My Favorite Products
        </Title>
        <Content>Let's get you started with DrugStoc</Content>
        {isLoading ? (
          <Grid sx={{ marginTop: 1 }} container spacing={3}>
            {favorite?.results.map((element: any, index: number) => (
              <Grid key={index} item xs={6} sm={4} md={3}>
                <Products
                  id={element?.product.id}
                  title={element?.product.name}
                  image={element?.product.image}
                  description={element?.product.desc}
                  price={element?.product.price}
                  slug={element?.product.slug}
                  trigger={FAVORITE_PRODUCT}
                  in_fav={true}
                  // sku={element?.SKU}
                  quantity_in_cart={element?.product.quantity_in_cart}
                  in_cart={element?.product.in_cart}
                  quantity={element?.product.quantity}
                  // category={element?.category}
                  is_favorite={element?.product.is_favorite}
                  // manufacturer={element?.manufacturer}
                  // composition={element?.composition}
                />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Loading />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          {isLoading
            ? page === 1 && favorite?.results.length < 100
              ? null
              : pagination
            : null}
        </Box>
      </Box>
    </AccountLayout>
  );
}

export default Favorite;
