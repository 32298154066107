export const RANDOM_PRODUCT = "/api/v2/inventory/products";
export const CATEGORY_PRODUCT = "/api/v2/inventory/products/category";
export const FAVORITE_PRODUCT = "/api/v2/shopping/favorite/favorite-item";
export const CART_ITEMS = "/api/v2/shopping/cart/cart-item";
export const SHIPPING_ADDRESS = "/api/v2/shipping/shipping-address";
export const USER_PROFILE = "/api/v2/account/profile";
export const BUSINESS_PROFILE = "/api/v2/business/profile";
export const PURCHASE_HISTORY = "/api/v2/shopping/order/sales-order";
export const SEARCH_PRODUCT = "/api/v2/search/";
export const PRODUCT_CATEGORY = "/api/v2/inventory/category/list";
export const PRODUCT_MANUFACTURERS = "api/v2/inventory/manufacturer/all";
export const PRODUCT_BY_CATEGORY = "/api/v2/inventory/products/category/";
export const PRODUCT_DETAIL = "/api/v2/inventory/products/";
export const LOGIN_USER = "/api/v2/auth/login";
export const REGISTER_USER = "/api/v2/auth/register";
export const WALLET_BALANCE = "/api/v2/drugstocpay/wallet/account";
export const WALLET_TRANSACTIONS = "/api/v2/drugstocpay/transaction/";
export const BANKS = "/api/v2/drugstocpay/transaction/utils/banks";
export const VERIFY_ACCOUNT =
  "/api/v2/drugstocpay/transaction/utils/account/verify";
export const WITHDRAW = "/api/v2/drugstocpay/transaction/fund/withdrawal";
