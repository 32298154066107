import { Box, Stack } from "@mui/material";
import { useState} from "react";
import { useLocation } from "react-router-dom";
import { filterList } from "../constants/_filter.constant";
import FilterChip from "../element/filterChip";

interface Props {
  children: JSX.Element;
  setParams: React.Dispatch<React.SetStateAction<string>>;
}

interface Data {
  label: string;
  value: string;
}

function ProductListLayout({ children, setParams }: Props) {
  const [filter, setFilter] = useState<Data[]>([]);
  const { pathname } = useLocation();
  const slug = pathname.replace("/category/", "");

  const apply = (data: any) => {
    if (typeof data !== "string") {
      let rd = arrayUnique(filter, data);
      console.log(rd);
      let resp = rd.map((element) => `&${element.label}=${element.value}`);
      console.log(resp);
      setParams(`?category=` + resp.join(""));
      setFilter(rd);
    }
  };

  return (
    <Box>
      <Box mt={4} mb={2}>
        <Box
          component="img"
          sx={{
            width: "100%",
            height: 150,
            marginTop: 3,
            marginBottom: 0,
            objectFit: "cover",
          }}
          alt="ads"
          src="https://res.cloudinary.com/bizstak/image/upload/v1669739367/no_image_kej1gw.png"
        />
        {pathname === `/category/${slug}` ? (
          <>
            <h2>
              {slug
                .split("-")
                .map((item) => {
                  return item[0].toUpperCase() + item.slice(1);
                })
                .join(",")
                .replaceAll(",", " ")
                .replace(
                  "Coughcold Respiratory System",
                  "Cough, Cold & Respiratory System"
                )
                .replace(
                  "Vitaminsamino Acids Supplements",
                  "Vitamins, Amino Acids & Supplements"
                )}
            </h2>
            <p>
              All products listed are categorized under{" "}
              <strong>
                {slug
                  .replaceAll("-", " ")
                  .replace(
                    "vitaminsamino acids supplements",
                    "vitamins, amino acids & supplements"
                  )
                  .replace(
                    "coughcold respiratory system",
                    "cough, cold & respiratory system"
                  )}
              </strong>{" "}
              medication.
            </p>
          </>
        ) : null}

        <Stack
          direction="row"
          mt={2}
          spacing="21px"
          mb={2}
          sx={{
            display: "flex",
            overflowX: "auto",
            scrollbarColor: "transparent",
            scrollbarWidth: 0,
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {pathname === `/category/${slug}`
            ? filterList
                .filter((item: any) => item.name !== "Manufacturer")
                .map((element, index) => (
                  <FilterChip key={index} {...element} apply={apply} />
                ))
            : filterList
                .filter((item: any) => item.name !== "Category")
                .map((element, index) => (
                  <FilterChip key={index} {...element} apply={apply} />
                ))}
        </Stack>

        {/* <Divider /> */}
      </Box>
      {children}
    </Box>
  );
}

export default ProductListLayout;

function arrayUnique(array: Data[], data: any) {
  let a: any[] = [];
  if (data.length > 0) {
    a = array;
    let index = array.filter(
      (ar) => !data.find((rm: any) => rm.label === ar.label)
    );
    a = index.concat(data);
  } else {
    a = array;
    let index = a.filter((item) => item.label !== data.label);
    a = index.concat(data);
  }

  return a;
}
