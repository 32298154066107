import React from "react";
import { useLocation } from "react-router-dom";
import { ListItemIcon, MenuItem, Link } from "@mui/material";
import {} from "react-router-dom";
import { Content } from "../styles";

function Menu({
  title,
  Icons,
  navigation = "/login",
  onClick = () => null,
}: any) {
  const router = useLocation();

  return (
    <Link href={navigation} underline="none">
      <MenuItem
        onClick={onClick}
        sx={{
          padding: "12px 12px 12px 0px",
          background: router.pathname === navigation ? "#556aaf12" : null,
          borderLeft:
            router.pathname === navigation ? "4px solid #51b0e2" : null,
          paddingLeft: 0.5,
        }}
      >
        {/* #8692A */}
        {Icons ? (
          <ListItemIcon>
            <Icons fontSize="small" />
          </ListItemIcon>
        ) : null}
        <Content sx={{ fontSize: 12 }}>{title}</Content>
      </MenuItem>
    </Link>
  );
}

export default Menu;
