import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Slider,
  Stack,
} from "@mui/material";
import { optionsModel } from "../models/_options.models";
import { Content, FormGroup, Title } from "../styles";

interface Props {
  label: string;
  items: Array<optionsModel>;
  setSeletion: any;
  setItems: any;
  setRadio: any;
  radio: any;
  setSeletionValue: any;
  rangeValue: Array<number>;
  setRange: any;
  // setText: any;
  onChange: (data: any) => void;
  range?: number;
  type?: "CheckBox" | "Range" | "Radio";
  data?: Array<optionsModel>;
}

function FilterOption({
  data = [],
  type,
  range = 0,
  label,
  setItems,
  setSeletion,
  setSeletionValue,
  radio,
  setRadio,
  rangeValue,
  setRange,
  // setText,
  onChange,
  items,
}: Props) {
  const isChecked = (value: optionsModel) => {
    let exist = items.filter((el: optionsModel) => el.value === value.value);
    if (exist.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const selectCheckBox = (e: optionsModel) => {
    let num = e;
    let exist = items.filter((el: optionsModel) => el.value === num.value);
    if (exist.length > 0) {
      let new_list = items.filter((el: optionsModel) => el.value !== num.value);
      setItems(new_list);
      let d = new_list.map((el: optionsModel) => ({ label, value: el.value }));
      //   console.log()
      onChange(d);
      setSeletion(true);
      setSeletionValue(d);
      console.log(setSeletion);
      if (d.length === 0) {
        setSeletion(false);
      }
    } else {
      let new_push = [...items, num];
      setItems(new_push);
      let d = new_push.map((el) => ({ label, value: el.value }));
      onChange(d);
      setSeletion(true);
      if (d.length === 0) {
        setSeletion(false);
      }
    }
  };

  const radioCheckBox = (e: string) => {
    if (e !== "") {
      setSeletion(true);
    } else {
      setSeletion(false);
    }
    setRadio(e);
    onChange({
      label,
      value: e,
    });
  };

  const rangeData = (data: any, range: number) => {
    let res: number[] = [0, range];
    if (rangeValue[0] === 0 && rangeValue[1] === 100) {
      setSeletion(false);
    } else {
      setSeletion(true);
    }
    res[0] = (data?.target?.value[0] / 100) * range;
    res[1] = (data?.target?.value[1] / 100) * range;
    let resp2 = [
      (data?.target?.value[0] / range) * range,
      (data?.target?.value[1] / range) * range,
    ];
    setRange(resp2);
    onChange({
      label,
      value: res.join(","),
    });
  };

  if (type === "Radio") {
    return (
      <Box px={3} sx={{ width: 300 }}>
        <FormControl>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={radio}
            onChange={(e) => radioCheckBox(e.target.value)}
            name="radio-buttons-group"
          >
            {data.map((element, index) => (
              <FormControlLabel
                key={index}
                value={element.value}
                control={<Radio />}
                label={<Content>{element.text}</Content>}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </Box>
    );
  }

  if (type === "Range") {
    return (
      <Box>
        <Box p={2} textAlign="center">
          <Title
            variant="h5"
            sx={{
              sm: {
                fontSize: "0.79rem",
                md: { fontSize: "1rem" },
                lg: { fontSize: "1.5rem" },
              },
            }}
          >
            {/* {" "}
            0 - ₦{range}+{" "} */}
          </Title>
        </Box>
        <FormGroup sx={{ display: "flex", justifyContent: "center" }}>
          <Box px={3} sx={{ width: { xs: 200, sm: 350, lg: 400 } }}>
            <Slider
              sx={{
                width: "100%",
                marginLeft: "auto",
              }}
              key={`slider-${range}`}
              defaultValue={rangeValue}
              onChange={(e) => rangeData(e, range)}
              step={10}
              color="secondary"
            />
            <Stack direction="row" justifyContent="space-between">
              <Content>0</Content>
              <Content>{range}</Content>
            </Stack>
          </Box>
        </FormGroup>
      </Box>
    );
  }

  return (
    <FormGroup>
      <Box sx={{ maxHeight: 200, overflow: "scroll", overflowX: "hidden" }}>
        {data.map((element: any, index: number) => (
          <MenuItem key={index}>
            <FormControlLabel
              sx={{ width: 300, fontSize: 10 }}
              onSelect={() => selectCheckBox(element)}
              control={
                <Checkbox
                  size="small"
                  checked={isChecked(element)}
                  value={element.value}
                  onChange={() => selectCheckBox(element)}
                />
              }
              label={<Content>{element.text}</Content>}
            />
          </MenuItem>
        ))}
      </Box>
    </FormGroup>
  );
}

export default FilterOption;
