import { useRef, useEffect, useState } from "react";
import ListingPageComponent from "../ListingPageComponent/ListingPageComponent";
import axios from "axios";
import { RANDOM_PRODUCT } from "../../constants/_api.constant";
import Loading from "../Loading";
import { Box /* Pagination, PaginationItem, Stack */ } from "@mui/material";

function ListingPageContainer() {
  const listInnerRef = useRef();
  const [currPage, setCurrPage] = useState(1);
  const [prevPage, setPrevPage] = useState(0);
  const [userList, setUserList] = useState([]);
  const [lastList, setLastList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState("Loading a new page...");
  const [error, setError] = useState(null);
  // const isMounted = useRef(true);

  // const emp = [];
  // for (let i = 1; i <= currPage; i++) {
  //   emp.push(i);
  // }

  // const handleChange = () => {
  //   setCurrPage(currPage);
  // };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${RANDOM_PRODUCT}?page=${currPage}`);
        if (!response.data.results.length) {
          setLastList(true);
          return;
        }
        setPrevPage(currPage);
        // console.log(userList)

        setUserList([
          ...userList,
          ...response.data.results.sort((a, b) => b.quantity - a.quantity),
        ]);
      } catch (error) {
        setError(error);
        setText("Page not found");
      } finally {
        setIsLoading(true);
      }
    };
    // let newRef = isMounted.current;
    if (!lastList && prevPage !== currPage /* && newRef */) {
      fetchData();
    }

    // return () => newRef === false;
  }, [currPage, lastList, prevPage, userList /* isMounted */]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      setIsLoading(true);
      if (scrollTop + clientHeight === scrollHeight) {
        setIsLoading(false);
        setCurrPage(prevPage + 1);
      }
    }
  };

  return (
    <>
      <ListingPageComponent
        onScroll={onScroll}
        listInnerRef={listInnerRef}
        userList={userList}
      />

      {userList.length === 0 ? <Loading /> : null}

      {/* <Stack spacing={2} sx={{ mx: "auto", mt: 1 }}>
        <Pagination
          // hidePrevButton
          // hideNextButton
          count={currPage}
          page={currPage}
          color="primary"
          shape="rounded"
          onChange={handleChange}
          renderItem={(item) => (
            <PaginationItem
              component="a"
              href={`${item.page === 1 ? "#1" : `#${item.page}`}`}
              {...item}
            />
          )}
        />
      </Stack> */}
      {!isLoading ? (
        <>
          <Loading />
          <Box
            sx={{
              color: "#8692A6",
              fontSize: 14,
              mx: "auto",
              width: "100%",
              textAlign: "center",
              display: { xs: "none", lg: "block" },
            }}
          >
            {error || text}
          </Box>
        </>
      ) : null}
    </>
  );
}

export default ListingPageContainer;
