import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Products from "../../components/Products.component";
import { RANDOM_PRODUCT } from "../../constants/_api.constant";
import "./ListingPageComponent.css";

function ListingPageComponent({ onScroll, listInnerRef, userList }: any) {
  return (
    <Box className="list" onScroll={onScroll} ref={listInnerRef}>
      {userList.map((element: any, index: number) => {
        const id = index % 100 === 0 ? (100 + index) / 100 : null;
        return (
          <Grid id={`${id}`} key={index} item xs={6} sm={4} md={3} p={2}>
            <Products
              id={element?.id}
              title={element?.name}
              image={element?.image}
              description={element?.desc}
              price={element?.price}
              slug={element?.slug}
              trigger={RANDOM_PRODUCT}
              quantity_in_cart={element?.quantity_in_cart}
              in_cart={element?.in_cart}
              quantity={element?.quantity}
              is_favorite={element?.is_favorite}
              manufacturer={element?.manufacturer}
            />
          </Grid>
        );
      })}
    </Box>
  );
}

export default ListingPageComponent;
