import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CART_ITEMS,
  FAVORITE_PRODUCT,
  SEARCH_PRODUCT,
} from "../constants/_api.constant";
import { request } from "../utilities/connectHooks";
import { toast } from "react-toastify";
import Layout from "../components/Layout.component";
import {
  Box,
  Divider,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Pagination,
  PaginationItem,
  Card,
  CardMedia,
} from "@mui/material";
import { AddToCartButton, Content, IconButton, Title } from "../styles";
import { priceFormat } from "../utilities/priceFormatter";
import {
  AddShoppingCart,
  Delete,
  Favorite,
  FavoriteBorder,
} from "@mui/icons-material";
import Loading from "./Loading";
import Products from "../components/Products.component";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface Props {
  quantity_in_cart?: any;
  is_favorite?: boolean;
  in_cart?: any;
  id?: any;
  trigger: string;
  addItemToCart?: (arg0: { quantity: number; product_id: any }) => Promise<any>;
  loadCartItem?: () => void;
  addItemToFav?: (arg0: { product_id: any }) => Promise<any>;
  deleteItemToFav?: (arg0: any) => Promise<any>;
  updateItemInCart?: (
    arg0: { quantity: any; product_id: any },
    arg1: any
  ) => Promise<any>;
  deleteItemInCart?: (arg0: any) => Promise<any>;
  slug?: any;
  image?: any;
  title?: any;
  price?: any;
  description?: any;
  quantity?: number;
  in_fav?: boolean;
  href?: any;
}

const ProductManufacturers: any = (props: Props) => {
  const [loading_increase, setLoadingIncrease] = useState(false);
  const [inFav, setFav] = useState(false);
  const [inCart, setCart] = useState(false);
  const [loading_cart, setLoadingCart] = useState(false);
  const [loading_fav, setLoadingFav] = useState(false);
  const [loading_del, setLoadingDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [altProducts, setAltProducts] = useState<any>();

  // const minValue = 1;
  // const maxValue: any = props.quantity;
  const [value, setValue] = useState(props.quantity_in_cart);
  const handleChange = (e: any) => {
    // const newValue = Math.min(Math.max(e.target.value, minValue), maxValue);
    setValue(e.target.value);
  };

  const [search, setSearch] = useState<any>();
  const { pathname } = useLocation();
  // console.log(pathname.split("/"));
  const lastPathIndex = pathname.split("/");
  const slug = lastPathIndex[lastPathIndex.length - 1];
  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com/api/v2/inventory/products/${slug}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setSearch(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [slug]);

  const [pageQuery, setPageQuery] = useState(1);

  useEffect(() => {
    const fetchAPI = async () => {
      const response: any = await fetch(
        `https://staging.drugstoc.com/api/v2/search/q=${search?.desc}` ||
          `https://staging.drugstoc.com${CART_ITEMS}/${search?.id}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data: any = await response.json();
      setAltProducts(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, []);

  const location = useLocation();
  const param = location.pathname;
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);

  const handlePageChange = (e: any, value: any) => {
    setPageQuery(value);
  };

  const pagination = (
    <Pagination
      page={page}
      count={Math.ceil(search?.count / 100)}
      color="primary"
      shape="rounded"
      onChange={handlePageChange}
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      renderItem={(item: any) => (
        <PaginationItem
          component={Link}
          to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
          {...item}
        />
      )}
    />
  );

  // console.log(altProducts);

  function add_to_cart() {
    let data = {
      quantity: 1,
      product_id: search?.id,
    };
    setLoadingCart(true);
    request
      .post({ path: CART_ITEMS, trigger: CART_ITEMS, payload: data })
      .then((res) => {
        if (res) {
          setLoadingCart(false);
          setLoadingCart(false);
          setCart(true);
        } else {
          setLoadingCart(false);
        }
      });
  }

  function add_to_fav() {
    let data = {
      product_id: search?.id,
    };
    setLoadingFav(true);
    request
      .post({ path: FAVORITE_PRODUCT, trigger: "", payload: data })
      .then(() => {
        setFav(true);
        setLoadingFav(false);
      });
  }

  function delete_fav() {
    setLoadingDelete(true);
    request
      .delete({
        path: `${FAVORITE_PRODUCT}/${search?.id}`,
        trigger: FAVORITE_PRODUCT,
      })
      .then(() => {
        setLoadingDelete(false);
      });
  }

  function increase_item() {
    setLoadingIncrease(true);
    let data = {
      quantity: Math.abs(value),
      product_id: props.id,
    };
    request
      .patch({
        path: `${CART_ITEMS}/${props.id}`,
        trigger: CART_ITEMS,
        payload: data,
      })
      .then((resp) => {
        if (resp) {
          setValue(value);
        }
        setLoadingIncrease(false);
      });
    const customId = "custom-id-yes";

    if (value > 1) {
      try {
        toast(`${Math.abs(value)} quantities added to Cart!`, {
          toastId: customId,
        });
      } catch {
        console.log(null);
      }
    } else if (value !== 0) {
      try {
        toast(`${Math.abs(value)} quantities added to Cart!`, {
          toastId: customId,
        });
      } catch {
        console.log(null);
      }
    }
  }

  // const { data: searcher } = useConnect({
  //   path: `${CART_ITEMS}/${search?.id}`,
  // });
  // console.log(searcher);

  return (
    <Layout>
      <Box mt={{ xs: 5, md: 10 }}>
        {isLoading ? (
          <Box>
            <Grid container spacing={4}>
              <Grid item md={8} xs={12}>
                <Card
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                  }}
                >
                  <TransformWrapper>
                    <TransformComponent>
                      <CardMedia
                        component="img"
                        loading="lazy"
                        sx={{
                          width: "100%",
                          height: { xs: 200, md: 250 },
                          borderRadius: 2,
                          // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
                          cursor: "pointer",
                          objectFit: "contain",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "auto",
                        }}
                        alt="product image"
                        src={search?.image}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </Card>
              </Grid>
              <Grid item md={4} xs={12}>
                <Title
                  variant="h5"
                  sx={{
                    lineHeight: 1.4,
                  }}
                >
                  {search?.name}
                </Title>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Title sx={{ whiteSpace: "break-spaces" }}>
                    SKU: {search?.SKU}
                  </Title>
                </Content>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Title>MANUFACTURER: {search?.manufacturer}</Title>
                </Content>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  DESCRIPTION: {search?.desc}
                </Content>
                <Content
                  sx={{
                    fontSize: 12,
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  QUANTITIES: {search?.quantity}
                </Content>
                <Divider sx={{ marginTop: 2.5, marginBottom: 2.5 }} />
                <Typography
                  variant="h6"
                  component="h5"
                  sx={{
                    filter: search?.price ? "blur(0px)" : "blur(5px)",
                  }}
                >
                  {search?.price
                    ? priceFormat(search?.price)
                    : "PRICE NOT AVAILABLE"}
                </Typography>
                <Stack
                  direction="row"
                  sx={{ marginTop: 2, height: 40, width: "100%" }}
                  spacing={1}
                >
                  {search?.quantity_in_cart === undefined &&
                  inCart === false ? (
                    <AddToCartButton
                      variant="contained"
                      disabled={search?.quantity === 0}
                      onClick={add_to_cart}
                      loading={loading_cart}
                      sx={{ width: "100%", fontSize: 12, fontWeight: "900" }}
                      disableElevation
                    >
                      {search?.quantity === 0 ? "Out of Stock" : "Add to cart"}
                    </AddToCartButton>
                  ) : (
                    <Stack direction="row" width="100%" spacing={3}>
                      <Tooltip title="Add to Cart" sx={{ width: "100%" }}>
                        <IconButton
                          onClick={increase_item}
                          variant="contained"
                          // loading={loading_increase}
                          sx={{
                            backgroundColor: "transparent",
                            boxShadow: "none",
                            position: "relative",
                            "&:hover": {
                              backgroundColor: "transparent",
                              boxShadow: "none",
                            },
                          }}
                        >
                          <AddShoppingCart
                            id="outlined-number"
                            onClick={increase_item}
                            sx={{
                              position: "relative",
                              marginRight: "-24px",
                              height: "37.5px",
                              color: "#51b0e2",
                              "&:active": {
                                backgroundColor: "#f0f0f0",
                                boxShadow: "none",
                              },
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        type="number"
                        value={`${
                          isNaN(value) || value === undefined
                            ? search?.quantity_in_cart
                            : value
                        }`}
                        placeholder={`${search?.quantity_in_cart} items in cart`}
                        InputProps={{
                          inputProps: {
                            min: "1",
                            max: `${search?.quantity}`,
                            step: "1",
                          },
                        }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        onChange={handleChange}
                      />
                    </Stack>
                  )}
                  {!search?.in_fav ? (
                    <Tooltip title="Add to Favorites">
                      <IconButton
                        onClick={add_to_fav}
                        loading={loading_fav}
                        disabled={search?.in_fav || inFav}
                        variant="outlined"
                      >
                        {search?.is_favorite || inFav ? (
                          <Favorite />
                        ) : (
                          <FavoriteBorder />
                        )}
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      onClick={delete_fav}
                      loading={loading_del}
                      color="error"
                      variant="outlined"
                    >
                      <Delete />
                    </IconButton>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ marginTop: 2.5, marginBottom: 2.5 }} />
            <Title
              variant="h6"
              sx={{
                lineHeight: 1.4,
              }}
            >
              <>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: 600 }}
                  id="alternative-products"
                >
                  Alternative Products
                </Typography>
                {isLoading ? (
                  <Grid sx={{ marginTop: 1 }} container spacing={3}>
                    {altProducts?.results.map((element: any, index: number) => (
                      <>
                        <Grid key={index} item xs={6} sm={4} md={3}>
                          <Products
                            id={element?.id}
                            title={element?.name}
                            image={element?.image}
                            description={element?.desc}
                            price={element?.price}
                            slug={element?.slug}
                            trigger={SEARCH_PRODUCT}
                            // sku={element?.SKU}
                            quantity_in_cart={element?.quantity_in_cart}
                            in_cart={element?.in_cart}
                            quantity={element?.quantity}
                            // category={element?.category}
                            is_favorite={element?.is_favorite}
                          />
                        </Grid>
                      </>
                    ))}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "auto",
                        marginRight: "auto",
                        position: "relative",
                        top: "50px",
                      }}
                    >
                      {page === 1 && search?.result.length < 100 ? null : pagination}
                    </Box>
                  </Grid>
                ) : (
                  <Loading />
                )}
              </>
            </Title>
          </Box>
        ) : (
          <Loading />
        )}
      </Box>
    </Layout>
  );
};

export default ProductManufacturers;
