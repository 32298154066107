import { optionsModel } from "../models/_options.models";

export const ordering: optionsModel[] = [
    {
        key: 3,
        text: "Relevant Products",
        value: ""
    },
    {
        key: 3,
        text: "Newest Products",
        value: "created_at"
    },
    {
        key: 1,
        text: "Alphabetical (A - Z)",
        value: "name"
    },
    {
        key: 2,
        text: "Alphabetical  (Z - A)",
        value: "-name"
    },
    {
        key: 4,
        text: "Price: Low to High",
        value: "price"
    },
    {
        key: 4,
        text: "Price: High to Low",
        value: "-price"
    },
]

export const manufacturers: optionsModel[] = [
    {
      "key": 31,
      "value": 31,
      "text": "bito"
    },
    {
      "key": 30,
      "value": 30,
      "text": "Steroid"
    },
    {
      "key": 29,
      "value": 29,
      "text": "Anti inflamatory"
    },
    {
      "key": 28,
      "value": 28,
      "text": "Anti helmintic"
    },
    {
      "key": 27,
      "value": 27,
      "text": "Vitamins, Amino acids & SupplementsA"
    },
    {
      "key": 26,
      "value": 26,
      "text": "Protective & Disinfecting Devices"
    },
    {
      "key": 25,
      "value": 25,
      "text": "Protection Materials"
    },
    {
      "key": 24,
      "value": 24,
      "text": "Cough"
    },
    {
      "key": 23,
      "value": 23,
      "text": "Vaccines"
    },
    {
      "key": 22,
      "value": 22,
      "text": "Vitamins, Amino acids & Supplements"
    },
    {
      "key": 21,
      "value": 21,
      "text": "Sexual health"
    },
    {
      "key": 20,
      "value": 20,
      "text": "Rapid diagnostic test devices"
    },
    {
      "key": 19,
      "value": 19,
      "text": "Oncology"
    },
    {
      "key": 18,
      "value": 18,
      "text": "Medical devices and consumables"
    },
    {
      "key": 17,
      "value": 17,
      "text": "Household"
    },
    {
      "key": 16,
      "value": 16,
      "text": "Generals"
    },
    {
      "key": 15,
      "value": 15,
      "text": "GIT"
    },
    {
      "key": 14,
      "value": 14,
      "text": "Fluid & Electrolyte Balance"
    },
    {
      "key": 13,
      "value": 13,
      "text": "Expenses"
    },
    {
      "key": 12,
      "value": 12,
      "text": "Endocrine System"
    },
    {
      "key": 11,
      "value": 11,
      "text": "ENT & Opthalmic"
    },
    {
      "key": 10,
      "value": 10,
      "text": "Cough, Cold & Respiratory System"
    },
    {
      "key": 9,
      "value": 9,
      "text": "Central Nervous System"
    },
    {
      "key": 8,
      "value": 8,
      "text": "Anti-Virals"
    },
    {
      "key": 7,
      "value": 7,
      "text": "Anti septics & Disinfectants"
    },
    {
      "key": 6,
      "value": 6,
      "text": "Anti-Malarial"
    },
    {
      "key": 5,
      "value": 5,
      "text": "Anti hypertensives & Cardiovascular"
    },
    {
      "key": 4,
      "value": 4,
      "text": "Anti histamines"
    },
    {
      "key": 3,
      "value": 3,
      "text": "Anti fungals"
    },
    {
      "key": 2,
      "value": 2,
      "text": "Anti biotics"
    },
    {
      "key": 1,
      "value": 1,
      "text": "Analgesics"
    }
]
  

export const business_category: optionsModel[] = [
    { key: "pharmacy", value: "pharmacy", text: "Pharmacy" },
    { key: "doctors-office", value: "doctors-office", text: "Doctor's Office" },
    { key: "hospital", value: "hospital", text: "Hospital" },
    { key: "nursing-home", value: "nursing-home", text: "Nursing Home" },
    { key: "private-patient", value: "private-patient", text: "Private Patient" },
];

export const business_category2: optionsModel[] = [
    { key: "doctor", value: "doctor", text: "Doctor" },
    { key: "nurse", value: "nurse", text: "Nurse" },
    { key: "pharmacist", value: "pharmacist", text: "Pharmacist" },
];

export const business_location: optionsModel[] = [
    { key: "lagos", value: "lagos", text: "Lagos state" },
    { key: "ogun", value: "ogun", text: "Ogun state" },
    { key: "oyo", value: "oyo", text: "Oyo state" },
    { key: "cross-rivers", value: "cross-rivers", text: "Cross Rivers state" },
    { key: "others", value: "others", text: "Others" },
];

export const wallet_currency: optionsModel[] = [
    { key: "NGN", value: "NGN", text: "NGN Naira" }
];

export const discovery: optionsModel[] = [
    { key: "linkedIn", value: "linkedin", text: "LinkedIn" },
    { key: "instagram", value: "instagram", text: "Instagram" },
    { key: "acquisition-team", value: "acquisition-team", text: "Acquisition Team" },
    { key: "facebook", value: "facebook", text: "Facebook" },
    { key: "google-search", value: "google-search", text: "Google" },
    { key: "sales-rep", value: "sales-rep", text: "DrugStoc Sales Rep" },
    { key: "referral", value: "referral", text: "Referral" },
    { key: "others", value: "others", text: "Others" },
];

export const payment: optionsModel[] = [
    { key: "drugstoc_pay", value: "drugstoc_pay", text: "DrugStoc Pay" },
    { key: "card_payment", value: "card_payment", text: "Pay Now" },
];
