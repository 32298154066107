import { Navigate } from "react-router-dom";

export type ProtectedRouteProps = {
  isProtected: boolean;
  component: JSX.Element;
};

const PageRoute = (
  { component, isProtected }: ProtectedRouteProps,
  { data }: any
) => {
  const isAuthenticated = localStorage.getItem("token");

  if (isAuthenticated == null && isProtected) {
    return <Navigate to={{ pathname: "/login" }} />;
  } else {
    return component;
  }
};

export default PageRoute;
