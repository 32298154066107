import { ThemeProvider } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PageRoute from "./config/route.config";
import { routers } from "./config/router.config";
import { darkTheme } from "./config/theme.config";

function App({previousPage}: any) {
  // console.log(previousPage)
  return (
    <ThemeProvider theme={darkTheme}>
      <BrowserRouter>
        <Routes>
          {routers.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={
                <PageRoute
                  component={<route.component />}
                  isProtected={route.protected}
                />
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
