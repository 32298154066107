import {
  Box,
  Button,
  Divider,
  Stack,
  Pagination,
  PaginationItem,
} from "@mui/material";
import {
  Badge,
  Dialog,
  EmptyState,
  SearchIcon,
  SelectField,
  Table,
  TextInputField,
} from "evergreen-ui";
import { Formik } from "formik";
import moment from "moment";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AccountLayout from "../../components/AccountLayout.component";
import {
  BANKS,
  WALLET_BALANCE,
  WALLET_TRANSACTIONS,
  WITHDRAW,
} from "../../constants/_api.constant";
import { Content, Title } from "../../styles";
import { request, useConnect } from "../../utilities/connectHooks";
import { priceFormatDecimal } from "../../utilities/priceFormatter";

function Wallet() {
  const [open, setOpen] = useState(false);
  const { data } = useConnect({ path: WALLET_BALANCE });
  // const { data: transactions } = useConnect({ path: WALLET_TRANSACTIONS });
  const { data: banks } = useConnect({ path: BANKS });
  const [transactions, setTransactions] = useState<any>();

  const { pathname } = useLocation();

  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1)}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  // useEffect(() => {
  //   const fetchAPI = async () => {
  //     const response = await fetch(`https://staging.drugstoc.com${BANKS}`, {
  //       headers: {
  //         Authorization: `token ${localStorage.getItem("token")}`,
  //       },
  //     });
  //     const data = await response.json();
  //     setBanks(data);
  //   };
  //   fetchAPI();
  // }, [pageQuery]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com${WALLET_TRANSACTIONS}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setTransactions(data);
    };
    fetchAPI();
  }, []);

  // useEffect(() => {
  //   const fetchAPI = async () => {
  //     const response = await fetch(
  //       `https://staging.drugstoc.com${WALLET_BALANCE}`,
  //       {
  //         headers: {
  //           Authorization: `token ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );
  //     const data = await response.json();
  //     setData(data);
  //   };
  //   fetchAPI();
  // }, []);

  // const location = useLocation();
  // const param = location.pathname;
  // const query = new URLSearchParams(location.search);
  // const page = parseInt(query.get("page") || "1", 10);

  // const handlePageChange = (e: any, value: any) => {
  //   setPageQuery(value);
  // };

  // const pagination = (
  //   <Pagination
  //     page={page}
  //     count={Math.ceil(data?.count / 100)}
  //     color="primary"
  //     shape="rounded"
  //     onChange={handlePageChange}
  //     onClick={() => {
  //       window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  //     }}
  //     renderItem={(item: any) => (
  //       <PaginationItem
  //         component={Link}
  //         to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
  //         {...item}
  //       />
  //     )}
  //   />
  // );

  const Modal = () => {
    return (
      <Box>
        <Dialog
          isShown={open}
          title="Withdraw fund"
          preventBodyScrolling
          hasFooter={false}
          onCloseComplete={() => setOpen(!open)}
        >
          <Formik
            initialValues={{
              bank_code: "",
              bank_name: "",
              account_no: "",
              account_name: "",
              transaction_pin: "",
              amount: 0,
              remark: "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              request
                .post({
                  path: WITHDRAW,
                  trigger: WALLET_TRANSACTIONS,
                  payload: values,
                })
                .then((resp) => {
                  if (resp) {
                    setOpen(!open);
                  }
                });
            }}
          >
            {(formik) => (
              <form onSubmit={formik.handleSubmit}>
                <SelectField
                  required
                  label="Select Bank"
                  onChange={(el) => {
                    const { bank_code, bank_name } = JSON.parse(
                      el.target.value
                    );
                    formik.setFieldValue("bank_name", bank_name);
                    formik.setFieldValue("bank_code", bank_code);
                  }}
                >
                  <option value={0} disabled selected>
                    select your bank
                  </option>
                  {banks?.results.map((element: any, index: number) => (
                    <option key={index} value={JSON.stringify(element)}>
                      {element.bank_name}
                    </option>
                  ))}
                </SelectField>

                <Stack direction="row" width="100%" spacing={2}>
                  <TextInputField
                    width="100%"
                    label="Account number"
                    disabled={formik.values.bank_code === ""}
                    onChange={async (el: any) => {
                      formik.setFieldValue("account_no", el.target.value);
                      if (el.target.value.length === 10) {
                        let data = await request.verifyAccount(
                          formik.values.bank_code,
                          el.target.value
                        );
                        formik.setFieldValue("account_name", data.account_name);
                      }
                    }}
                    name="account_no"
                    placeholder="0000000000"
                  />
                  <TextInputField
                    width="100%"
                    label="Account Name"
                    {...formik.getFieldProps("account_name")}
                    name="account_name"
                    placeholder="name"
                    disabled
                  />
                </Stack>
                <Stack direction="row" width="100%" spacing={2}>
                  <TextInputField
                    width="100%"
                    label="Amount"
                    onChange={(el: any) =>
                      formik.setFieldValue(
                        "amount",
                        parseFloat(el.target.value)
                      )
                    }
                    placeholder="Enter Amount"
                  />
                  <TextInputField
                    width="100%"
                    label="Transaction Pin"
                    {...formik.getFieldProps("transaction_pin")}
                    placeholder="0000"
                  />
                </Stack>
                <TextInputField
                  label="Purpose"
                  {...formik.getFieldProps("remark")}
                  placeholder="Add reason for transfer"
                />
                <Button type="submit" disabled={formik.isSubmitting}>
                  Withdraw
                </Button>
              </form>
            )}
          </Formik>
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginLeft: "auto",
              marginRight: "auto",
              position: "relative",
            }}
          >
            {page === 1 && banks?.result.length < 100 ? null : pagination}
          </Box> */}
        </Dialog>

        <Button
          disableElevation
          variant="contained"
          onClick={() => setOpen(!open)}
        >
          Withdraw
        </Button>
      </Box>
    );
  };

  return (
    <AccountLayout>
      <Box>
        <Title variant="h4" sx={{ paddingTop: 1, paddingBottom: 1 }}>
          DrugStocPay
        </Title>
        <Content>Let’s get you started with DrugStoc</Content>
        <Box sx={{ marginTop: 5 }}>
          <Stack direction="row" spacing={3} justifyContent="space-between">
            <Box>
              <Title variant="h5">Wallet Balance</Title>
              <Title variant="h4">
                {priceFormatDecimal(data?.balance || 0)}
              </Title>
            </Box>
            <Stack direction="row" spacing={3} height={35}>
              <Modal />
            </Stack>
          </Stack>
          <Divider sx={{ marginTop: 2, marginBottom: 4 }} />
          <Box mt={5} sx={{ overflow: "auto" }}>
            <Table style={{ width: "800px" }}>
              <Table.Head>
                <Table.TextHeaderCell>Transaction number</Table.TextHeaderCell>
                <Table.TextHeaderCell>status</Table.TextHeaderCell>
                <Table.TextHeaderCell>amount</Table.TextHeaderCell>
                <Table.TextHeaderCell>time</Table.TextHeaderCell>
                <Table.TextHeaderCell>remark</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {transactions?.results.length === 0 ? (
                  <EmptyState
                    background="light"
                    title="No tracked events for this Source"
                    orientation="horizontal"
                    icon={<SearchIcon color="#C1C4D6" />}
                    iconBgColor="#EDEFF5"
                    description="Events appear when the Source starts sending data to Segment about your users and their activity."
                  />
                ) : (
                  transactions?.results.map((profile: any) => (
                    <Table.Row key={profile.id} isSelectable>
                      <Table.TextCell>{profile.transaction_id}</Table.TextCell>
                      <Table.TextCell>
                        <Badge
                          color={
                            profile.transaction_type === "debit"
                              ? "red"
                              : "green"
                          }
                        >
                          {profile.transaction_type}
                        </Badge>
                      </Table.TextCell>
                      <Table.TextCell isNumber>
                        {priceFormatDecimal(profile.amount)}
                      </Table.TextCell>
                      <Table.TextCell>
                        {moment(profile.created_at).fromNow()}
                      </Table.TextCell>
                      <Table.TextCell>{profile.remark}</Table.TextCell>
                    </Table.Row>
                  ))
                )}
              </Table.Body>
            </Table>
          </Box>
        </Box>
      </Box>
    </AccountLayout>
  );
}

export default Wallet;
