import { Box, Divider, Paper, Stack } from "@mui/material";
import { EmptyState, SearchIcon } from "evergreen-ui";
import { Title } from "../styles";

interface Props {
  children?: JSX.Element;
  Icon?: JSX.Element;
  title?: string;
  subtitle?: string;
  empty?: boolean;
  action?: JSX.Element;
}

function Cards({ children, Icon, title, empty, action }: Props) {
  return (
    <Paper variant="outlined" sx={{ padding: 2 }}>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
          {/* {Icon ? <Icon fontSize="large" sx={{ color: grey[500] }} /> : null} */}
          <Box>
            <Title variant="h6">{title}</Title>
          </Box>
        </Stack>
        {action ? action : null}
      </Stack>
      <Divider />
      {empty ? (
        <EmptyState
          background="light"
          title={`No item available in your ${title}`}
          orientation="horizontal"
          icon={<SearchIcon color="#C1C4D6" />}
          iconBgColor="#EDEFF5"
          description="Events appear when the Source starts sending data to Segment about your users and their activity."
        />
      ) : (
        children
      )}
    </Paper>
  );
}

export default Cards;
