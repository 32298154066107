import { Box, Card, Grid, Pagination, PaginationItem } from "@mui/material";
import { Link, Outlet, useLocation } from "react-router-dom";
import Layout from "../components/Layout.component";
import { Title } from "../styles";
import { useState, useEffect } from "react";
import Loading from "./Loading";

function Manufacturer() {
  const [manufacturerData, setManufacturerData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [pageQuery, setPageQuery] = useState(1);
  const location = useLocation();
  const param = location.pathname;
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);

  const handleChange = (e: any, value: any) => {
    setPageQuery(value);
  };

  useEffect(() => {
    document.title = `Manufacturer List | Page ${page}`;
  }, [page]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response: any = await fetch(
        `https://staging.drugstoc.com/api/v2/inventory/manufacturer/all?page=${pageQuery}`
      );
      const data: any = await response.json();
      setManufacturerData(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [pageQuery]);

  const pagination = (
    <Pagination
      page={page}
      count={Math.ceil(manufacturerData?.count / 100)}
      color="primary"
      shape="rounded"
      onChange={handleChange}
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      renderItem={(item: any) => (
        <PaginationItem
          component={Link}
          to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
          {...item}
        />
      )}
    />
  );

  return (
    <Layout>
      <Box mt={5}>
        <Title variant="h4" sx={{ mb: 5 }}>
          All Manufacturers
        </Title>
        {isLoading ? (
          <Grid container spacing={3}>
            {manufacturerData?.results.map((element: any, index: number) => {
              return (
                <Grid key={index} item md={3} xs={6} sm={4}>
                  <Card variant="outlined">
                    <Link
                      to={`/manufacturer/${element.slug}/${pageQuery}/${element.id}`}
                    >
                      <Box
                        component="img"
                        sx={{
                          width: "100%",
                          height: 200,
                          padding: 2,
                          objectFit: "contain",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                        alt="Manufacturer Images"
                        src={element.logo ? element.logo : "/logo_white.svg"}
                        id={`${element.id}`}
                      />
                    </Link>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Loading />
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          {isLoading
            ? page === 1 && manufacturerData?.results.length < 100
              ? null
              : pagination
            : null}
        </Box>
        <Outlet />
      </Box>
    </Layout>
  );
}

export default Manufacturer;
