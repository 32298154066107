import React from 'react'
import Layout from '../components/Layout.component'

function Error() {
  return (
    <Layout>
        <div>Error</div>
    </Layout>
  )
}

export default Error