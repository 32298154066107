import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import { request } from "../utilities/connectHooks";

interface Props {
    image?: string
    name: string
    path: string
    trigger: string
    initialImage: string
}

function FileUpload(props: Props) {
    const [selectedImage, setSelectedImage] = useState<any>([]);

    let data = new FormData()

    const submit = (event: any) => {
        console.log(event.target.files);
        if(event.target.files.length > 0) {
            setSelectedImage(event?.target?.files);
            data.append(props.name, event?.target?.files[0])
            request.patch({
                path: props.path, trigger: props.trigger,
                payload: data
            })
        }
    }

    return (
        <Box>
            <Box>
                {selectedImage.length > 0 ? (
                    <Box
                        component="img"
                        sx={{
                            width : "350px",
                            height : "350px",
                            objectFit: "contain"
                        }}
                        alt="ads image"
                        src={
                            props.image
                                ? props.image
                                : URL.createObjectURL(selectedImage[0])
                        }
                    />
                ) : props.initialImage !== null ? (
                    <Box
                        component="img"
                        sx={{
                            width : "350px",
                            height : "350px",
                            objectFit: "contain"
                        }}
                        alt="ads image"
                        src={`${props.initialImage}.jpg`}
                    />
                ) : (
                    <Box
                        component="img"
                        sx={{
                            width : "350px",
                            height : "350px",
                            objectFit: "contain"
                        }}
                        alt="ads image"
                        src="https://getstamped.co.uk/wp-content/uploads/WebsiteAssets/Placeholder.jpg"
                    />
                )}
            </Box>
            <Button variant="contained" fullWidth component="label">
                Upload
                <input
                    hidden
                    accept="image/*"
                    multiple
                    onChange={submit}
                    type="file"
                />
            </Button>
        </Box>
    );
}

export default FileUpload;
