import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#51b0e2",
        contrastText: '#ffffff',
      },
      secondary: {
        main: "#51b0e2",
        contrastText: '#ffffff',
      },
    },
    typography: {
      fontFamily: [
        "Inter",
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Helvetica Neue",
        "Arial,sans-serif",
      ].join(","),
    },
  });