import { Box, Container, Link } from '@mui/material'
import React from 'react'
import { Content, Title } from '../styles'

function AuthSideBar() {
    return (
        <Box>
            <Container fixed sx={{ padding: "0 90px 0px 90px !important" }}>
                <Link href='/' underline="none">
                    <Box
                        component="img"
                        sx={{
                            height: 80,
                            width: 160,
                            marginBottom: 8,
                            display: { xs: "none", sm: "block" },
                            maxHeight: { xs: 233, md: 167 },
                            maxWidth: { xs: 350, md: 250 },
                        }}
                        alt="The house from the offer."
                        src="/logo.svg"
                    />
                </Link>

                <Box sx={{ marginTop: "30px", marginBottom: "30px" }}>
                    <Title variant="h6">Reorder in seconds and initiate delivery immediately</Title>
                    <Content sx={{ width: "60%", marginTop: "5px" }}>
                        Save time on unnecessary, repetitive ordering and make reorders at the speed of light. Experience quick and professional delivery, always.
                    </Content>
                </Box>
                <Box sx={{ marginTop: "30px", marginBottom: "30px" }}>
                    <Title variant="h6">Interest-free Credit for Unlimited Expansion</Title>
                    <Content sx={{ width: "60%", marginTop: "5px" }}>
                        Get access to uninterrupted funding to expand your pharmacy. Enjoy a flexible repayment system that allows you to make daily or weekly payments.
                    </Content>
                </Box>
                <Box sx={{ marginTop: "30px", marginBottom: "30px" }}>
                    <Title variant="h6">Over 7000 SKUs of quality, anticounterfeit pharmaceuticals</Title>
                    <Content sx={{ width: "60%", marginTop: "5px" }}>
                        This is the safest place to order medications. Get all the pharmaceuticals you need at the manufacturer’s quality.
                    </Content>
                </Box>
                <Box sx={{ marginTop: "30px", marginBottom: "30px" }}>
                    <Title variant="h6"></Title>
                    <Content sx={{ width: "60%", marginTop: "5px" }}>
                        Thank you for choosing DrugStoc. We look forward to being a reliable procurement partner for your practice.
                    </Content>
                </Box>
            </Container>
        </Box>
    )
}

export default AuthSideBar