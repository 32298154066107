import * as React from "react";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Badge,
  Menu,
  Typography,
} from "@mui/material/";
import MenuIcon from "@mui/icons-material/Menu";
import { Content } from "../styles";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  Stack,
} from "@mui/material";
import { Link as Ln, Outlet } from "react-router-dom";
import { Link } from "react-router-dom";
import { Cart, Category, Manufacturers, User } from "../element/icons";
import ElevationScroll from "../element/elevation";
import SearchBar from "./SearchBar.component";
import { CART_ITEMS, PRODUCT_CATEGORY } from "../constants/_api.constant";
import { useConnect } from "../utilities/connectHooks";
import Sidebar from "../components/Sidebar/Sidebar";

interface Props {
  window?: () => Window;
}

const drawerWidth = 240;

function Navbar(props: Props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { data, isFetching, hasError } = useConnect({ path: PRODUCT_CATEGORY });
  const { data: cartItem } = useConnect({ path: CART_ITEMS });

  const Options = ({ name, href }: any) => (
    <ListItem disablePadding>
      <ListItemButton component="a" href={`/category/${href}`}>
        <Content px={2} py={0.5} sx={{ fontSize: 12 }}>
          {name}
        </Content>
      </ListItemButton>
    </ListItem>
  );

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div id="sidebar">
      <Box sx={{ flexGrow: 1 }}>
        <ElevationScroll {...props}>
          <AppBar
            sx={{
              paddingLeft: { xs: 0, md: 10 },
              paddingRight: { xs: 0, md: 10 },
              position: "fixed",
            }}
          >
            <Toolbar
              sx={{
                paddingLeft: { xs: "8px", sm: "24px" },
                paddingRight: { xs: "8px", sm: "24px" },
              }}
            >
              <Link to="/">
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component="img"
                    sx={{
                      width: 50,
                      marginRight: 0,
                      maxHeight: { xs: 35, md: 150 },
                      maxWidth: { xs: 30, sm: 30, md: 150 },
                    }}
                    alt="The house from the offer."
                    src="/logo_white_mobile.svg"
                  />
                  <Typography
                    variant="h5"
                    sx={{
                      display: {
                        xs: "none",
                        md: "block",
                        position: "relative",
                        left: -9,
                      },
                    }}
                  >
                    DrugStoc
                  </Typography>
                </Box>
              </Link>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <Stack direction="row" spacing={3}>
                  <Button
                    onClick={handleClick}
                    size="small"
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    sx={{ my: 2, color: "white", textTransform: "none" }}
                    startIcon={<Category />}
                  >
                    Category
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          left: "48%",
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                  >
                    <Box
                      sx={{
                        width: "300px",
                        maxWidth: 360,
                        maxHeight: 400,
                        overflow: "scroll",
                        overflowX: "hidden",
                        bgcolor: "background.paper",
                      }}
                    >
                      {!isFetching && hasError === undefined ? (
                        <List dense={true}>
                          <ListItem disablePadding>
                            <Content
                              textAlign="center"
                              px={2}
                              py={1}
                              sx={{ fontSize: 14, fontWeight: "700" }}
                            >
                              All Categories
                            </Content>
                          </ListItem>
                          {data.results.map((element: any, index: number) => (
                            <Ln to={`/category/${element.slug}`}>
                              <Options
                                key={index}
                                name={element.name
                                  .replace("-", " ")
                                  .replace(",", ", ")}
                                href={`${element.slug}`}
                              />
                              <Outlet />
                            </Ln>
                          ))}
                        </List>
                      ) : null}
                    </Box>
                  </Menu>
                  <Ln to="/manufacturer">
                    <Button
                      href="/manufacturer"
                      sx={{
                        my: 2,
                        color: "white",
                        textTransform: "none",
                        fontWeight: 500,
                      }}
                      startIcon={<Manufacturers />}
                    >
                      Manufacturer
                    </Button>
                    <Outlet />
                  </Ln>
                </Stack>
              </Box>
              <Box sx={{ flexGrow: 1 }} />
              <SearchBar />
              <Box sx={{ flexGrow: 5 }} />
              <Box>
                <Stack direction="row" spacing={3}>
                  <Ln to="/account">
                    <Button
                      href="/account"
                      sx={{
                        my: 2,
                        color: "white",
                        textTransform: "none",
                        display: { xs: "none", md: "flex" },
                      }}
                      startIcon={<User />}
                    >
                      Account
                    </Button>
                    <Outlet />
                  </Ln>

                  {/* ------------- */}
                  <Box
                    sx={{ ml: "0 !important", md: { ml: "24px !important" } }}
                  >
                    <Ln to="/cart">
                      <Button
                        href="/cart"
                        sx={{
                          my: 2,
                          color: "white",
                          textTransform: "none",
                          position: "relative",
                          left: "20px",
                          marginLeft: "-30px",
                          // ml: 0,
                          sm: { ml: 0, left: 0 },
                        }}
                        startIcon={
                          <Badge
                            badgeContent={cartItem?.results.length}
                            color="error"
                          >
                            <Cart />
                          </Badge>
                        }
                      ></Button>
                      <Outlet />
                    </Ln>
                  </Box>
                  {/* -------------------- */}
                </Stack>
              </Box>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon />
                </IconButton>
              </Box>
              <Box component="nav">
                <Drawer
                  container={container}
                  variant="temporary"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                  }}
                  sx={{
                    display: { xs: "block" },
                    "& .MuiDrawer-paper": {
                      boxSizing: "border-box",
                      width: drawerWidth,
                    },
                  }}
                >
                  <Sidebar />
                </Drawer>
              </Box>
            </Toolbar>
          </AppBar>
        </ElevationScroll>
        <Toolbar />
      </Box>
    </div>
  );
}

export default Navbar;
