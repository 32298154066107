import SidebarItem from "./SidebarItem";
import items from "./data/sidebar.json";
import { USER_PROFILE } from "../../constants/_api.constant";
import Divider from "@mui/material/Divider";
import { useConnect } from "../../utilities/connectHooks";
import { Button } from "@mui/material";
import Menu from "../../element/menu";

export default function Sidebar() {
  const { data: profile } = useConnect({ path: USER_PROFILE });
  const logout = () => {
    localStorage.removeItem("token");
  };

  return (
    <>
      <div className="sidebar">
        {items.map((item: any, index: number) => (
          <SidebarItem key={index} item={item} />
        ))}
        <Divider />
        <div
          style={{
            position: "relative",
            top: "20px",
            color: "#000",
            fontWeight: 600,
            fontSize: "14px",
          }}
        >
          {profile === undefined ? (
            <span style={{ paddingLeft: "1em" }}>
              <Button
                variant="contained"
                href="/login"
                sx={{ width: "87%", ml: "auto", mr: "auto" }}
              >
                Log In
              </Button>
            </span>
          ) : (
            <span style={{ paddingLeft: "1em" }}>
              <Button
                variant="outlined"
                sx={{ height: 36.5, width: "87%", ml: "auto", mr: "auto" }}
              >
                <Menu onClick={logout} title="Sign Out" />
              </Button>
            </span>
          )}
        </div>
      </div>
    </>
  );
}
