import { Box, Link } from "@mui/material";
import { Content, Title } from "../styles";
import {
  Business,
  Favorite,
  Help,
  History,
  LogOut,
  Personal,
  Shipping,
  Terms,
  Wallet,
} from "./icons";
import Menu from "./menu";
import { Link as Ln } from "react-router-dom";

function Sidebar({ user }: any) {
  const logout = () => {
    localStorage.removeItem("token");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box sx={{ borderBottom: "1px solid #d7d7d7" }}>
        <Ln to="/account">
          <Link
            href="/account"
            underline="none"
            sx={{ fontWeight: 600, color: "#000" }}
            variant="h4"
          >
            Hi, {user?.first_name ? user.first_name : "......"}
          </Link>
        </Ln>
        <Content sx={{ marginBottom: "10px !important", fontSize: 10 }}>
          Thanks for being a DrugStoc customer
        </Content>
      </Box>
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 1,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <Ln to="/account/purchase_history">
          <Menu
            Icons={History}
            title="Purchase History"
            navigation="/account/purchase_history"
          />
        </Ln>
        <Ln to="/account/wallet">
          <Menu
            Icons={Wallet}
            title="DrugStoc Pay"
            navigation="/account/wallet"
          />
        </Ln>
        {/* <Menu Icons={Document} title="Telemedicine (Bulk Order)" navigation="/account/bulk-order" /> */}
      </Box>
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 1,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <Title variant="h6" sx={{ paddingTop: 1, paddingBottom: 1 }}>
          Manage Account
        </Title>
        <Ln to="/account/personal_info">
          <Menu
            Icons={Personal}
            title="Personal information"
            navigation="/account/personal_info"
          />
        </Ln>
        <Ln to="/account/business">
          <Menu
            Icons={Business}
            title="Business information"
            navigation="/account/business"
          />
        </Ln>
        <Ln to="/account/shipping_address">
          <Menu
            Icons={Shipping}
            title="Shipping Addresses"
            navigation="/account/shipping_address"
          />
        </Ln>
      </Box>
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 1,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <Title variant="h6" sx={{ paddingTop: 1, paddingBottom: 1 }}>
          My DrugStoc
        </Title>
        {/* <Menu Icons={ReOrder} title="Reorder" navigation="/account/reorder" /> */}
        <Ln to="/account/favorite">
          <Menu
            Icons={Favorite}
            title="My Favorite"
            navigation="/account/favorite"
          />
        </Ln>
        {/* <Menu Icons={OrderHistory} title="My Orders" navigation="/account/my_order" /> */}
      </Box>
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 1,
          borderBottom: "1px solid #d7d7d7",
        }}
      >
        <Title variant="h6" sx={{ paddingTop: 1, paddingBottom: 1 }}>
          Customer Service
        </Title>
        <Ln to="/account/change-password">
          <Menu
            Icons={Help}
            title="Change Password"
            navigation="/account/change-password"
          />
        </Ln>
        <Menu Icons={Help} title="Help" />
        <Menu Icons={Terms} title="Term of use" />
      </Box>
      <Box
        sx={{
          paddingTop: 1,
          paddingBottom: 1,
        }}
      >
        <Menu onClick={logout} Icons={LogOut} title="Sign Out" />
      </Box>
    </Box>
  );
}

export default Sidebar;
