import { Badge } from "@mui/material";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useConnect } from "../../utilities/connectHooks";
import { useState } from "react";
import { CART_ITEMS } from "../../constants/_api.constant";
import "./Sidebar.css";
import { Link } from "react-router-dom";

export default function SidebarItem({ item }: any) {
  const { data: cartItem } = useConnect({ path: CART_ITEMS });

  const styles = {
    color: "#1F1C1",
  };
  const [open, setOpen] = useState(false);

  if (item.childrens) {
    return (
      <div className={open ? "sidebar-item open" : "sidebar-item"}>
        <div
          className="sidebar-name"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span>
            {item.icon && <i style={styles}></i>}
            {item.name}
          </span>
          <div
            id={open ? "open" : ""}
            className="bi-chevron-down toggle-btn"
            onClick={() => setOpen(!open)}
          >
            <MdKeyboardArrowDown size={25} />
          </div>
        </div>
        <div className="sidebar-content">
          {item.childrens.map((child: any, index: number) => (
            <SidebarItem key={index} item={child} />
          ))}
        </div>
      </div>
    );
  } else {
    return (
      <div className="main">
        <Link to={`${item.slug}` || "#"} className="sidebar-item plain">
          {item.icon && <i style={styles}></i>}
          {item.name}
        </Link>

        {item.name === "Cart" ? (
          <Badge
            sx={{ position: "relative", top: 20, left: "5px" }}
            badgeContent={cartItem?.results.length}
            color="error"
          ></Badge>
        ) : null}
      </div>
    );
  }
}
