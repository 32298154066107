import { Add, Delete, Remove } from "@mui/icons-material";
import { Box, Divider, Stack } from "@mui/material";
import { useState } from "react";
import { CART_ITEMS } from "../constants/_api.constant";
import { AddToCartButton, Content, IconButton, Title } from "../styles";
import { request } from "../utilities/connectHooks";
import { priceFormat } from "../utilities/priceFormatter";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function CartItem(props: any) {
  const [loading_decrease, setLoadingDecrease] = useState(false);
  const [loading_increase, setLoadingIncrease] = useState(false);
  const [loading_delete, setLoadingDelete] = useState(false);
  const [trackerEvent, setTrackerEvent] = useState<any>([]);
  const [action, setAction] = useState("view");

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);

  function increase_item() {
    setLoadingIncrease(true);
    let data = {
      quantity: props.data.quantity + 1,
      product_id: props.data.product.id,
    };
    request
      .patch({
        path: `${CART_ITEMS}/${props.data.product.id}`,
        trigger: CART_ITEMS,
        payload: data,
      })
      .then((resp) => {
        if (resp) {
        }
        setLoadingIncrease(false);
        setAction("increase_item");
      });
  }

  function decrease_item() {
    setLoadingDecrease(true);
    let data = {
      quantity: props.data.quantity - 1,
      product_id: props.data.product.id,
    };
    if (props.data.quantity === 1) {
      request
        .delete({
          path: `${CART_ITEMS}/${props.data.product.id}`,
          trigger: CART_ITEMS,
        })
        .then((resp) => {
          if (resp) {
          }
          setLoadingDecrease(false);
          setAction("decrease_item");
        });
    } else {
      request
        .patch({
          path: `${CART_ITEMS}/${props.data.product.id}`,
          trigger: CART_ITEMS,
          payload: data,
        })
        .then((resp) => {
          if (resp) {
          }
          setLoadingDecrease(false);
        });
    }
  }

  function delete_item() {
    setLoadingDelete(true);
    request
      .delete({
        path: `${CART_ITEMS}/${props.data.product.id}`,
        trigger: CART_ITEMS,
      })
      .then((resp) => {
        if (resp) {
        }
        setLoadingDelete(false);
        setAction("delete_item");
      });
  }

  useEffect(() => {
    const fetchTrackerEvents = async () => {
      const trackerEventData = {
        device_type: "web",
        app_page: "Cart Page",
        action,
        action_property: null,
      };
      const response = await fetch(
        `https://staging.drugstoc.com/api/v2/activity/tracking/event-tracking?page=${page}`,
        {
          method: "POST",
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
            "Content-type": "application/json",
          },
          body: JSON.stringify(trackerEventData),
        }
      );
      const data = await response.json();
      setTrackerEvent(data?.results);
    };

    fetchTrackerEvents();
  }, [action, page]);

  useEffect(() => {});

  let previousPage = document.referrer;
  const toArrPage = previousPage.split("/");
  if (toArrPage.includes("cart") && "view") {
    previousPage = "Cart Page";
  } else if (toArrPage.includes("manufacturer") && action === "view") {
    previousPage = "Manufacturer Page";
  } else if (toArrPage.includes("category") && action === "view") {
    previousPage = "Category page";
  } else if (toArrPage.includes("products") && action === "view") {
    previousPage = "Product Detail Page";
  } else if (toArrPage.includes("favorite") && action === "view") {
    previousPage = "Favorite Page";
  } else if (
    action === "delete_item" ||
    action === "increase_item" ||
    action === "decrease_item"
  ) {
    previousPage = "Click Event";
  } else if (action === "view") {
    previousPage = "Home Page";
  }

  useEffect(() => {
    const fetchTrackerEvents = async () => {
      const trackerEventData = {
        device_type: "web",
        app_page: "Current Page: Cart Page",
        action: action,
        action_property: `${
          action !== "view"
            ? "Event: Click Event"
            : `Previous Page: ${previousPage}`
        }`,
      };
      const response = await fetch(
        `https://staging.drugstoc.com/api/v2/activity/tracking/event-tracking?page=${page}`,
        {
          method: "POST",
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
            "Content-type": "application/json",
          },
          body: JSON.stringify(trackerEventData),
        }
      );
      const data = await response.json();
      setTrackerEvent(data?.results);
    };

    fetchTrackerEvents();
  }, [action, page, previousPage]);

  return (
    <Box sx={{ paddingTop: 2, paddingBottom: 1 }} id={`${props.data.id}`}>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          flexDirection: { xs: "column", sm: "row" },
          alignItems: { xs: "center" },
          mx: { xs: "auto" },
        }}
      >
        <Stack
          direction="row"
          sx={{
            flexDirection: { xs: "column" },
            // alignItems: { xs: "center" },
            mx: { xs: "auto" },
            alignItems: { xs: "center", sm: "flex-start" },
            flex: { sm: 1 },
            mt: { sm: "-12px" },
          }}
          width="70%"
          spacing={3}
        >
          <Box
            component="img"
            loading="lazy"
            sx={{
              width: 100,
              height: 100,
              objectFit: "contain",
            }}
            alt="product image"
            src={props.data.product.image}
          />
          <Box>
            <Content
              sx={{
                fontSize: 14,
                textOverflow: "ellipsis",
                overflow: "hidden",
                fontWeight: "600",
                color: "#000",
                whiteSpace: "nowrap",
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              {props.data.product.name}
            </Content>
            <Content
              sx={{
                fontSize: 12,
                textOverflow: "ellipsis",
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              {props.data.product.desc}
            </Content>
          </Box>
        </Stack>
        <Box
          sx={{
            alignContent: "center",
            marginTop: { xs: "12px" },
            flex: { sm: 1 },
            textAlign: { xs: "center", sm: "left" },
          }}
        >
          <Title
            variant="h6"
            sx={{
              textAlign: "right",
              filter: props.data.product.price ? "blur(0px)" : "blur(5px)",
            }}
          >
            {props.data.product.price
              ? priceFormat(props.data.product.price * props.data.quantity)
              : "PRICE NOT AVAILABLE"}
          </Title>
          <Content
            sx={{
              textAlign: "right",
              fontSize: 12,
              filter: props.data.product.price ? "blur(0px)" : "blur(5px)",
            }}
          >
            {props.data.product.price
              ? priceFormat(props.data.product.price)
              : "PRICE NOT AVAILABLE"}{" "}
            x {props.data.quantity} item(s)
          </Content>
        </Box>
      </Stack>
      <Stack
        direction="row"
        sx={{
          marginTop: 1,
          flexDirection: { xs: "column", lg: "row" },
          alignItems: { xs: "center" },
          mx: { xs: "auto" },
          justifyContent: { xs: "center", sm: "space-between" },
          width: "auto",
        }}
      >
        <AddToCartButton
          onClick={delete_item}
          loading={loading_delete}
          startIcon={<Delete />}
          color="error"
          disableElevation
        >
          Remove this Item
        </AddToCartButton>
        <Stack
          direction="row"
          justifyContent="space-between"
          width="36%"
          spacing={3}
        >
          <IconButton
            onClick={decrease_item}
            loading={loading_decrease}
            disableElevation
            variant="contained"
          >
            <Remove />
          </IconButton>
          <Title variant="caption">{props.data.quantity}</Title>
          <IconButton
            onClick={increase_item}
            loading={loading_increase}
            disableElevation
            variant="contained"
          >
            <Add />
          </IconButton>
        </Stack>
      </Stack>
      <Divider sx={{ marginTop: 2 }} />
    </Box>
  );
}

export default CartItem;
