import { SortOutlined } from "@mui/icons-material";
import {
  Category,
  Manufacturers,
  Money,
  MyDrugStoc,
  Quantity,
} from "../element/icons";
import { filterModel } from "../models/_filter.models";
import { manufacturers, ordering } from "./_options.constant";

export const filterList: filterModel[] = [
  // {
  //     name: "Category",
  //     icon: <Category />,
  //     searchable: true,
  //     data: manufacturers,
  //     label: "category"
  // },
  // {
  //     name: "Manufacturer",
  //     icon: <Manufacturers />,
  //     searchable: true,
  //     label: "manufacturer"
  // },
  // {
  //   name: "Composition",
  //   icon: <MyDrugStoc />,
  //   searchable: true,
  //   label: "composition",
  // },
  // {
  //   name: "Price",
  //   icon: <Money />,
  //   type: "Range",
  //   range: 5000,
  //   label: "price__range",
  // },
  // {
  //     name: "Quantity",
  //     icon: <Quantity />,
  //     type: "Range",
  //     label: "quantity__range",
  //     range: 2000
  // },
  // {
  //   name: "Order by",
  //   icon: <SortOutlined />,
  //   type: "Radio",
  //   label: "ordering",
  //   data: ordering,
  // },
];
