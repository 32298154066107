import { KeyboardArrowDown } from "@mui/icons-material";
import { Box, Chip, Menu } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { filterModel } from "../models/_filter.models";
import { optionsModel } from "../models/_options.models";
import { TextField } from "../styles";
import FilterOption from "./filterOption";
import { Category } from "./icons";

function FilterChip({
  name = "test",
  icon = <Category />,
  searchable = false,
  apply,
  ...props
}: filterModel) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [items, setItems] = useState<Array<optionsModel>>([]);
  const [select, setSeletion] = useState<boolean>(false);
  const [range, setRange] = useState([0, 100]);
  const [radio, setRadio] = useState("");
  const [selectValue, setSeletionValue] = useState(null);
  const [category, setCategory] = useState([]);
  const [text, setText] = useState("");
  const open = Boolean(anchorEl);
  const { slug } = useParams();

  useEffect(() => {
    const fetchCategoryAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com/api/v2/inventory/products/category/${slug}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setCategory(data?.results);
    };
    fetchCategoryAPI();
  }, []);

  const compositions = category?.map((item: any) => {
    return item.composition;
  });

  const composite = compositions?.join();
  const arrComposition = composite?.split(",");
  let removeDuplicate: any = arrComposition
    .filter((item: any) => {
      return item !== "";
    })
    .filter((item: any) => {
      return item.toLowerCase().includes(text);
    })
    .map((item: any) => {
      if (item.includes(" ")) {
        let char = item[item.indexOf(" ") + 1].toUpperCase();
        item = item.replace(item[item.indexOf(" ") + 1], char);
      }
      return item;
    });

  removeDuplicate = [...new Set(removeDuplicate)];
  if (removeDuplicate.length > 0 && text) {
    removeDuplicate.unshift("✔️ Keep Searching...");
  } else if (removeDuplicate.length === 0 && text) {
    removeDuplicate.unshift("❌ Invalid Search");
  } else {
    removeDuplicate.unshift("✔️ List of Composition");
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {}, [selectValue]);

  const onChange = (data: any) => {
    apply(data);
  };

  return (
    <Box
      id={`${name.toLocaleLowerCase()}`}
      sx={{
        "&::-webkit-scrollbar": {
          scrollbarColor: "transparent",
          scrollbarWidth: "none",
        },
      }}
    >
      <Chip
        icon={icon}
        sx={{ pl: 1 }}
        color={select ? "primary" : "default"}
        onClick={handleClick}
        onDelete={() => null}
        deleteIcon={<KeyboardArrowDown />}
        label={`${name}`}
      />
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: "48%",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <FilterOption
          {...props}
          setItems={setItems}
          items={items}
          setSeletion={setSeletion}
          setRadio={setRadio}
          radio={radio}
          setRange={setRange}
          rangeValue={range}
          onChange={onChange}
          setSeletionValue={setSeletionValue}
        />
      </Menu>
    </Box>
  );
}

export default FilterChip;
