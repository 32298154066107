import { Forms } from "../models/_form.models";
import {
  business_category,
  business_location,
  discovery,
  payment,
} from "./_options.constant";

export const LoginFormData: Forms[] = [
  {
    label: "Email*",
    name: "email",
    placeholder: "Enter email address",
  },
  {
    label: "Password*",
    name: "password",
    placeholder: "Enter password",
    form_type: "password",
  },
];

export const RegisterForm1: Forms[] = [
  {
    name: "",
    row: {
      column: 2,
      items: [
        {
          label: "First Name*",
          name: "first_name",
          placeholder: "Enter first name",
        },
        {
          label: "Last Name*",
          name: "last_name",
          placeholder: "Enter last name",
        },
      ],
    },
  },
  {
    label: "Email*",
    name: "email",
    placeholder: "Enter email address",
  },
  {
    label: "Phone Number",
    name: "phone",
    placeholder: "Enter phone number ",
  },
  {
    label: "Password",
    name: "password",
    placeholder: "Enter password",
    form_type: "password",
  },
];

export const RegisterForm2: Forms[] = [
  {
    label: "Business Name*",
    name: "business_name",
    placeholder: "Enter your business name",
  },
  {
    label: "Business Category*",
    name: "category",
    placeholder: "Select your business category",
    type: "Select",
    options: business_category,
  },
  {
    label: "Business Location*",
    name: "location",
    placeholder: "Select your business location",
    type: "Select",
    options: business_location,
  },
  {
    label: "How Did You Hear About Us*",
    name: "discovery",
    placeholder: "Select how you heard about us",
    type: "Select",
    options: discovery,
  },
  {
    label: "Referral Code (Optional)",
    name: "ref_code",
    placeholder: "Enter referral code of who referred you",
  },
];

export const RegisterForm3: Forms[] = [
  {
    label: `Name*`,
    name: "business_name",
    placeholder: `Enter your name`,
    bool: false,
  },
  {
    label: `Category*`,
    name: "category",
    placeholder: `Select your category`,
    type: "Select",
    options: business_category,
    bool: false,
  },
  {
    label: `Location*`,
    name: "location",
    placeholder: `Select your location`,
    type: "Select",
    options: business_location,
    bool: false,
  },
  {
    label: "How Did You Hear About Us*",
    name: "discovery",
    placeholder: "Select how you heard about us",
    type: "Select",
    options: discovery,
  },
  {
    label: "Referral Code (Optional)",
    name: "ref_code",
    placeholder: "Enter referral code of who referred you",
  },
];

export const orderForm: Forms[] = [
  {
    name: "",
    row: {
      column: 2,
      items: [
        {
          label: "Select Your Shipping Address*",
          name: "shipping",
          placeholder: "Select your shipping address",
          type: "Select",
          options: [],
        },
        {
          label: "Select Your Payment Method*",
          name: "payment_method",
          placeholder: "Select your payment method",
          type: "Select",
          options: payment,
        },
      ],
    },
  },
];

export const UpdateBusinessDate: Forms[] = [
  {
    name: "",
    row: {
      column: 3,
      items: [
        {
          label: "Business Name*",
          name: "name",
          placeholder: "Enter your business name",
        },
        {
          label: "Email*",
          name: "email",
          placeholder: "Enter your business email",
        },
        {
          label: "Phone Number*",
          name: "phone",
          placeholder: "Enter your business phone number",
        },
      ],
    },
  },
  {
    label: "About Business*",
    name: "about",
    placeholder: "Write about your business",
    type: "TextArea",
  },
  {
    label: "Address*",
    name: "address",
    placeholder: "Select your business address",
  },
  {
    name: "",
    row: {
      column: 2,
      items: [
        {
          label: "Business Category*",
          name: "category",
          placeholder: "Select your business category",
          type: "Text",
          options: business_category,
          disabled: true,
          form_type: "reset",
        },
        {
          label: "Business Location*",
          name: "location",
          placeholder: "Select your business location",
          type: "Text",
          options: business_location,
          disabled: true,
          form_type: "reset",
        },
      ],
    },
  },
];

export const shippingAddress: Forms[] = [
  {
    name: "",
    row: {
      column: 2,
      items: [
        {
          label: "First Name*",
          name: "first_name",
          placeholder: "Enter your first name",
        },
        {
          label: "Last Name*",
          name: "last_name",
          placeholder: "Enter your last name",
        },
      ],
    },
  },
  {
    name: "",
    row: {
      column: 2,
      items: [
        {
          label: "Address*",
          name: "address_line1",
          placeholder: "Enter address",
        },
        {
          label: "City*",
          name: "address_line2",
          placeholder: "Enter city",
        },
      ],
    },
  },
  {
    name: "",
    row: {
      column: 2,
      items: [
        {
          label: "Mobile Phone*",
          name: "phone_number1",
          placeholder: "Enter your mobile phone number",
        },
        {
          label: "Telephone Number*",
          name: "phone_number2",
          placeholder: "Enter your telephone number",
        },
        {
          label: "Location*",
          name: "region",
          placeholder: "Select location",
          type: "Select",
          options: business_location,
        },
      ],
    },
  },
  {
    label: "Additional Information*",
    name: "additional_information",
    placeholder: "Write about anything you would like to let us know",
    type: "TextArea",
  },
];

export const PersonalDataForm: Forms[] = [
  {
    name: "",
    row: {
      column: 2,
      items: [
        {
          label: "First Name*",
          name: "first_name",
          placeholder: "Enter your first name",
        },
        {
          label: "Last Name*",
          name: "last_name",
          placeholder: "Enter your last name",
        },
      ],
    },
  },
  {
    label: "Email*",
    name: "email",
    placeholder: "Enter email address",
  },
  {
    label: "Phone Number",
    name: "phone",
    placeholder: "Enter phone number",
  },
  {
    label: "Referral Code",
    name: "referral_code",
    placeholder: "Enter referral code",
    disabled: true,
  },
];
