import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  StyledEngineProvider,
  Pagination,
  PaginationItem,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import CartItem from "../components/CartItem.component";
import Layout from "../components/Layout.component";
import { BUSINESS_PROFILE, CART_ITEMS } from "../constants/_api.constant";
import Cards from "../element/card";
import { Content } from "../styles";
import { useConnect } from "../utilities/connectHooks";
import { Alert } from "@mui/material";
import Typography from "@mui/material/Typography";
import { priceFormat } from "../utilities/priceFormatter";
import { useState, useEffect } from "react";

function Cart() {
  const { data } = useConnect({ path: CART_ITEMS });
  const [cart, setCart] = useState<any>();

  const location = useLocation();
  const param = location.pathname;
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const [pageQuery, setPageQuery] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: any, value: any) => {
    setPageQuery(value);
  };

  const pathArr = param?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1)}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response: any = await fetch(
        `https://staging.drugstoc.com/api/v2/shopping/cart/cart-item?page=${pageQuery}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data: any = await response.json();
      setCart(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [pageQuery]);

  const pagination = (
    <Pagination
      page={page}
      count={Math.ceil(cart?.count / 100)}
      color="primary"
      shape="rounded"
      onChange={handleChange}
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      renderItem={(item: any) => (
        <PaginationItem
          component={Link}
          to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
          {...item}
        />
      )}
    />
  );

  const quantities = data?.results.map((item: any) => item.quantity);
  const products = data?.results.map((item: any) => item.product);

  const prices = products?.map((item: any) => {
    return item.price;
  }, 0);
  var arrayOfObject = quantities?.map(function (value: any, index: any) {
    return [value, prices[index]];
  });
  const aggreagePrices = arrayOfObject?.map((item: any) => {
    return item[0] * item[1];
  });
  const total = aggreagePrices?.reduce((acc: number, cur: number) => {
    return acc + cur;
  }, 0);

  if (data?.results.length === 0) {
    <Cards title="Cart is Empty" empty={data?.results.length === 0}></Cards>;
  } else {
    <Cards title="My Cart" empty={data?.results.length === 0}>
      {data?.results.map((element: any, i: number) => (
        <CartItem key={i} data={element} />
      ))}
    </Cards>;
  }
  const { data: business } = useConnect({ path: BUSINESS_PROFILE });
  // console.log(business)

  const checkLicence =
    business?.is_verified === false
      ? "Your account is pending, kindly call to verify your account & have access to pricing to checkout"
      : null;

  const redirectUrl =
    data?.results.length === 0 ? (
      <div style={{ cursor: "not-allowed", pointerEvents: "auto" }}>
        <Button variant="outlined" disabled>
          Checkout
        </Button>
      </div>
    ) : (
      <div>
        {typeof checkLicence === "string" ? (
          <div style={{ cursor: "not-allowed", pointerEvents: "auto" }}>
            <Button variant="outlined" disabled>
              Checkout
            </Button>
          </div>
        ) : (
          <div>
            <Button variant="contained" href="/checkout">
              Checkout
            </Button>
          </div>
        )}
      </div>
    );
  const isCartEmpty =
    data?.results.length === 0 ? (
      <StyledEngineProvider>
        <Cards title="Cart is Empty"></Cards>
      </StyledEngineProvider>
    ) : (
      <Cards title="My Cart" empty={data?.results.length === 0}>
        {data?.results.map((element: any, i: number) => (
          <CartItem key={i} data={element} />
        ))}
      </Cards>
    );
  return (
    <Layout>
      <Box mt={5}>
        {!business?.verified_date ? (
          !business?.is_verified ? (
            <Box>
              {/* {business?.premise_license === null ? ( */}
              <Link to="/account/business">
                <Alert
                  sx={{
                    marginBottom: 4,
                    marginTop: 1,
                    textDecoration: "underline",
                  }}
                  severity="error"
                >
                  {checkLicence}
                </Alert>
              </Link>
              {/* ) : null} */}
              {/* {business?.practice_license === null ? ( */}
              {/* <Link to="/account/business">
                <Alert
                  sx={{
                    marginBottom: 4,
                    marginTop: 1,
                    textDecoration: "underline",
                    // display: "none",
                  }}
                  severity="error"
                >
                  {checkLicence}
                </Alert>
              </Link> */}
              {/* ) : null} */}
            </Box>
          ) : null
        ) : (
          <>
            <Link to="/account/business">
              <Alert sx={{ marginBottom: 4, marginTop: 1 }} severity="warning">
                Your Premise License is being verified...
              </Alert>
            </Link>
            <Link to="/account/business">
              <Alert sx={{ marginBottom: 4, marginTop: 1 }} severity="warning">
                Your Practice License is being verified...
              </Alert>
            </Link>
          </>
        )}
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            {isCartEmpty}
          </Grid>
          <Grid item xs={12} md={4}>
            <Cards title="Summary">
              <Box>
                <Stack
                  direction="row"
                  sx={{ marginTop: 2 }}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {/* NaN */}
                  <Box>
                    <Content>
                      Subtotal:{" "}
                      {isNaN(total) ? "Not Available" : priceFormat(total)}
                    </Content>
                  </Box>
                  {/* <Title variant="h6">{priceFormat(getTotal())}.00</Title> */}
                </Stack>
                <Content sx={{ fontSize: 12 }}>
                  Delivery fees not included yet.
                </Content>
                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
                {redirectUrl}
              </Box>
            </Cards>
            <Box sx={{ marginTop: 5 }}></Box>
            <Cards title="Returns are easy">
              <Content sx={{ fontSize: 12, marginTop: 2 }}>
                {/* Free return within 15 days for Official Store items and 7 days
                for other eligible items */}
                <Typography sx={{ mb: 2, fontSize: "12px" }}>
                  This package has been sealed and should only be opended by he
                  intented recipient. If you are not the intented recipient or
                  it has been mistakenly delivered to you, kindly return to us
                  by calling +234(0) 1 7005571.
                </Typography>
                <Typography sx={{ mb: 2, fontSize: "12px" }}>
                  To return a product, kindly contact Customer Service Rep. via
                  call – 01700557 or your designated sales representative within
                  48 hours of receiving the product. A return officer will
                  schedule a pickup upon confirmation from any of our team.
                </Typography>
                <Typography sx={{ mb: 2, fontSize: "12px" }}>
                  Goods received cannot be returned after 72hours (3 days) of
                  delivery. Cold chain products, Injectibles and Controlled
                  drugs cannot be returned once received. They can only be
                  returned immediately at the point of supply.
                </Typography>
                <Typography sx={{ mb: 2, fontSize: "12px" }}>
                  Drugstoc E-hub reserves the right to reject any returned item
                  if:
                </Typography>
                <ul>
                  <li>Without a Goods Returned Notes</li>
                  <li>found damaged or compromised in any way,</li>
                  <li>found damaged or compromised in any way,</li>
                  <li>Tampered with using inks, tags etc</li>
                </ul>
              </Content>
            </Cards>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
          }}
        >
          {isLoading ? (page === 1 && cart?.results.length < 100 ? null : pagination) : null}
        </Box>
      </Box>
    </Layout>
  );
}

export default Cart;
