import * as Yup from 'yup';


interface Validator {
    loginValidation: object
    registerValidation1: object
    registerValidation2: object
    checkoutValidation: object
}


export const Validation : Validator = {
    loginValidation: Yup.object({
        email: Yup.string().email("This email is invalid").required("Your email is required"),
        password: Yup.string().required("Your password is required")
    }),
    registerValidation1: Yup.object({
        first_name: Yup.string().required("Your first name is required"),
        last_name: Yup.string().required("Your last name is required"),
        email: Yup.string().email("This email is invalid").required("Your email address is required"),
        phone: Yup.string().max(11, "Your phone number must be 11 digits").required("Your Phone Number is required"),
        password: Yup.string().required("Your Password is required"),
    }),
    registerValidation2: Yup.object({
        business_name: Yup.string().required("Your Business name is required"),
        category: Yup.string().required("Your Business category is required"),
        location: Yup.string().required("Your Business location is required"),
        discovery: Yup.string().required("let us know how you heard about us"),
        ref_code: Yup.string().default(""),
    }),
    checkoutValidation: Yup.object({
        payment_method: Yup.string().required("Your Payment Method is required"),
        shipping: Yup.number().required("Your Shipping Address is required")
    })
}

