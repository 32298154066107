import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Stack,
  Alert,
} from "@mui/material";
import Layout from "../components/Layout.component";
import { Content } from "../styles";
import Banners from "../element/banners";
import Carousel from "../components/Carousel/Carousel";
import Typography from "@mui/material/Typography";
import ListingPageContainer from "./ListingPageContainer/ListingPageContainer";
import { Link } from "react-router-dom";
import { useConnect } from "../utilities/connectHooks";
import { BUSINESS_PROFILE } from "../constants/_api.constant";
import { useEffect } from "react";

function Home() {
  const { data: profile } = useConnect({ path: BUSINESS_PROFILE });

  useEffect(() => {
    document.title = "Home | DrugStoc Online Ordering";
  }, []);

  const alert = () => {
    // profile?.is_verified ? null : (
    <Alert
      variant="filled"
      severity="info"
      sx={{
        position: "fixed",
        left: 10,
        bottom: 10,
        zIndex: 5,
        width: "auto",
        textDecoration: "underline",
      }}
    >
      <Link to="/account/business">
        Verify Account To Get Unlimited Access - Call +234(0) 1 7005571
      </Link>
    </Alert>;
    // )
  };

  useEffect(() => {
    const timer = setTimeout(() => alert, 1000);
    return () => clearTimeout(timer);
  }, [profile?.is_verified]);

  return (
    <Layout banners={<Carousel />}>
      <Box sx={{ marginTop: 3, mb: -5 }}>
        <Container fixed>
          <Paper elevation={0}>
            {/* {profile?.is_verified ? null : (
              <Alert
                variant="filled"
                severity="info"
                sx={{
                  position: "fixed",
                  left: 10,
                  bottom: 10,
                  zIndex: 5,
                  width: "auto",
                  textDecoration: "underline",
                }}
              >
                <Link to="/account/business">
                  Verify Account To Get Unlimited Access - Call +234(0) 1
                  7005571
                </Link>
              </Alert>
            )} */}
            <Typography variant="h5">Don't Miss Out On These.</Typography>
            <Divider sx={{ marginTop: 2 }} />
            <Banners />
          </Paper>
          <Stack
            direction="row"
            sx={{ marginTop: 4 }}
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h5">
                <span>Popular Products</span>
              </Typography>
              <Content sx={{ fontSize: 12 }}>
                Browse through popular products.
              </Content>
            </Box>
            {/* <Button
              variant="text"
              onClick={() => null}
              sx={{ textTransform: "capitalize" }}
              endIcon={<ArrowForwardIcon />}
            >
              See all popular products
            </Button> */}
          </Stack>
          <Divider sx={{ marginTop: 2 }} />

          <Grid
            sx={{ marginTop: 1, position: "relative" }}
            container
            spacing={0}
          >
            <ListingPageContainer />
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
}

export default Home;
