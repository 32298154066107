import { Box, Grid, Link } from "@mui/material";
import React from "react";

function Banners() {
  const itemData = [
    {
      img: "https://res.cloudinary.com/bizstak/image/upload/v1669740956/banner4_ws5yev.png",
      title: "Breakfast",
      link: "/products/wellman-prostate-x60",
      cols: 2,
    },
    {
      img: "https://res.cloudinary.com/bizstak/image/upload/v1669740951/banner2_lbcxqp.png",
      title: "Coffee",
      cols: 2,
      link: "/products/aiita-kn95-facemask-x-5",
    },
  ];

  return (
    <Grid sx={{ marginTop: 1 }} container spacing={3}>
      {itemData.map((item: any, index) => (
        <Grid key={index} item xs={12} md={6}>
          <Link style={{ cursor: "pointer" }} href={item?.link}>
            <Box
              component="img"
              sx={{
                width: "100%",
                height: 230,
                objectFit: { xs: "contain", md: "cover" },
                border: "1px solid #AEAAAE",
                borderRadius: 2,
              }}
              alt="ads image"
              src={item?.img}
            />
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

export default Banners;
