import {
  Box,
  Divider,
  Grid,
  Pagination,
  PaginationItem,
  FormControl,
  TextField,
  Button,
  Menu,
  Slider,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  KeyboardArrowDown,
  Equalizer,
  Money,
  VaccinesRounded,
  SortOutlined,
  FactoryRounded,
  Refresh,
} from "@mui/icons-material";
import { MenuProps } from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import { useEffect, useState } from "react";
import ProductListLayout from "../components/ProductListLayout.component";
import Layout from "../components/Layout.component";
import { Link, useLocation, useParams } from "react-router-dom";
import Products from "../components/Products.component";
import Loading from "./Loading";
import { Content } from "../styles";
import { BUSINESS_PROFILE, RANDOM_PRODUCT } from "../constants/_api.constant";
import { priceFormat } from "../utilities/priceFormatter";
import { useConnect } from "../utilities/connectHooks";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "8px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function Manufacturers(this: {
  path: string;
  component: () => JSX.Element;
  protected: true;
}) {
  const [params, setParams] = useState("");
  const { slug } = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorManufacturerEl, setAnchorManufacturerEl] =
    useState<null | HTMLElement>(null);
  const [anchorQunatityRangeEl, setAnchorQuantityRangeEl] =
    useState<null | HTMLElement>(null);
  const [anchorPriceRangeEl, setAnchorPriceRangeEl] =
    useState<null | HTMLElement>(null);
  const [anchorCompositionInputEl, setAnchorCompositionInputEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openManufacturer = Boolean(anchorManufacturerEl);
  const openQunatityRange = Boolean(anchorQunatityRangeEl);
  const openPriceRange = Boolean(anchorPriceRangeEl);
  const openCompositionInput = Boolean(anchorCompositionInputEl);
  const [productManufacturer, setProductManufacturer] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [pageQuery, setPageQuery] = useState(1);
  const [detail, setDetail] = useState<any>();
  const location = useLocation();
  const param = location.pathname;
  const { pathname } = useLocation();
  const arr = pathname.split("/");
  const productID = arr[arr.length - 1];
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const currPage = arr[arr.length - 2];
  const [compositionSort, setCompositionSort] = useState("");
  const [priceSort, setPriceSort] = useState("");
  const [manufacturerSort, setManufacturerSort] = useState("");
  const [quantitySort, setQuantitySort] = useState("");
  const [productNameSort, setProductNameSort] = useState("");
  const [createdSort, setcreatedSort] = useState("");
  const [selected, setSelected] = useState("");
  const [text, setText] = useState("");
  const { data: business } = useConnect({ path: BUSINESS_PROFILE });

  const brand = arr[arr.length - 3].replaceAll("-", " ");
  const titleInitialCapArr: any = brand.split(" ");
  const brandName: any = titleInitialCapArr
    .filter((item: any) => {
      return item !== "";
    })
    .map((item: any) => {
      return `${item[0].toUpperCase()}${item.slice(1)}`;
    })
    .join(",")
    .replaceAll(",", " ");

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setText("");
  };

  let ordering = "";
  if (compositionSort === "ascComposition") {
    ordering = "ordering=composition";
  } else if (compositionSort === "descComposition") {
    ordering = "ordering=-composition";
  }

  if (createdSort === "ascCreated") {
    ordering = "ordering=created_at";
  } else if (createdSort === "descCreated") {
    ordering = "ordering=-created_at";
  }

  if (priceSort === "ascPrice") {
    ordering = "ordering=price";
  } else if (priceSort === "descPrice") {
    ordering = "ordering=-price";
  }

  if (manufacturerSort === "ascManufacturer") {
    ordering = "ordering=manufacturer";
  } else if (manufacturerSort === "descManufacturer") {
    ordering = "ordering=-manufacturer";
  }

  if (quantitySort === "ascQuantity") {
    ordering = "ordering=quantity";
  } else if (quantitySort === "descQuantity") {
    ordering = "ordering=-quantity";
  }

  if (productNameSort === "ascProductName") {
    ordering = "ordering=name";
  } else if (productNameSort === "desc") {
    ordering = "ordering=-name";
  }

  const handleOrderByClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRangeQunatityRangeClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorQuantityRangeEl(event.currentTarget);
  };

  const handleRangePriceRangeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPriceRangeEl(event.currentTarget);
  };

  const handleCompositionInputClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorCompositionInputEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleQuantityRangeClose = () => {
    setAnchorQuantityRangeEl(null);
  };

  const handlePriceRangeClose = () => {
    setAnchorPriceRangeEl(null);
  };

  const handleCompositionInputClose = () => {
    setAnchorCompositionInputEl(null);
  };

  const handleQuantityRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setQuantityRange(newValue as number[]);
  };

  const handlePriceRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setPriceRange(newValue as number[]);
  };

  const handlePageChange = (e: any, value: any) => {
    setPageQuery(value);
  };

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  const handleCompositionSortChange = (event: any) => {
    setCompositionSort(event.target.value);
    setSelected(event.target.value);
  };

  const handleCreatedSortChange = (event: any) => {
    setcreatedSort(event.target.value);
    setSelected(event.target.value);
  };

  const handlePriceSortChange = (event: any) => {
    setPriceSort(event.currentTarget.value);
    setSelected(event.target.value);
  };

  const handleQuantitySortChange = (event: any) => {
    setQuantitySort(event.target.value);
    setSelected(event.target.value);
  };

  const handleProductNameSortChange = (event: any) => {
    setProductNameSort(event.target.value);
    setSelected(event.target.value);
  };

  const handleManufacturerClose = () => {
    setAnchorManufacturerEl(null);
  };

  const handleManufacturerClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorManufacturerEl(event.currentTarget);
  };

  const minQty = detail?.results
    .map((item: any) => {
      return item.quantity;
    })
    .sort((a: any, b: any) => a - b)[0];

  const maxQty = detail?.results
    .map((item: any) => {
      return item.quantity;
    })
    .sort((a: any, b: any) => a - b)[
    detail?.results
      .map((item: any) => {
        return item.quantity;
      })
      .sort((a: any, b: any) => a - b).length - 1
  ];

  const [maxQuantityRange, setMaxQuantityRange] = useState<any>();
  const [maxPriceRange, setMaxPriceRange] = useState<any>();

  const path = pathname;
  const names = path.split("/").slice(1, 3);
  const name = names[1].replaceAll("-", " ");

  useEffect(() => {
    const pathArr = pathname?.split("/");
    let currentPage: any;
    if (pathArr?.includes("manufacturer")) {
      currentPage = `Manufacturer`;
    }
    document.title = `${name[0].toUpperCase()}${name.slice(
      1
    )} | ${currentPage}`;
  }, [name, pathname]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com${RANDOM_PRODUCT}?&manufacturer=${productID}`,
        business && {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setMaxQuantityRange(
        data?.results
          .map((item: any) => item.quantity)
          .sort((a: any, b: any) => a - b)[
          data?.results
            .map((item: any) => item.quantity)
            .sort((a: any, b: any) => a - b).length - 1
        ]
      );
      setIsLoading(true);
    };
    fetchAPI();
  }, [business, pageQuery, productID]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com${RANDOM_PRODUCT}?&manufacturer=${productID}`,
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setMaxPriceRange(
        data?.results
          .map((item: any) => item.price)
          .sort((a: any, b: any) => a - b)[
          data?.results
            .map((item: any) => item.price)
            .sort((a: any, b: any) => a - b).length - 1
        ]
      );
      setIsLoading(true);
    };
    fetchAPI();
  }, [productID]);

  const [quantityRange, setQuantityRange] = useState<number[]>([
    minQty || 0,
    maxQty || Number.MAX_SAFE_INTEGER,
  ]);

  const minPrice = detail?.results
    .map((item: any) => {
      return item.price;
    })
    .sort((a: any, b: any) => {
      return a - b;
    })[0];

  const maxPrice: any = detail?.results
    .map((item: any) => {
      return item.price;
    })
    .sort((a: any, b: any) => {
      return a - b;
    })[
    detail?.results
      .map((item: any) => {
        return item.price;
      })
      .sort((a: any, b: any) => {
        return a - b;
      }).length - 1
  ];

  const [priceRange, setPriceRange] = useState<number[]>([
    minPrice || 0,
    maxPrice || Number.MAX_SAFE_INTEGER,
  ]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com/api/v2/inventory/products?manufacturer=${productID}&${ordering}&price__range=${priceRange}&quantity__range=${quantityRange}&page=${pageQuery}`,
        business && {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setDetail(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [business, ordering, pageQuery, priceRange, productID, quantityRange]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response: any = await fetch(
        `https://staging.drugstoc.com/api/v2/inventory/manufacturer/all?page=${currPage}`,
        business && {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setProductManufacturer(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [business, currPage, pageQuery]);

  const findById: any = productManufacturer?.results.find((item: any) => {
    return item.slug === arr[arr.length - 3];
  });

  const pagination =
    detail?.count < 100 ? null : (
      <Pagination
        page={page}
        count={Math.ceil(detail?.count / 100)}
        color="primary"
        shape="rounded"
        onChange={handlePageChange}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        renderItem={(item: any) => (
          <PaginationItem
            component={Link}
            to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
            {...item}
          />
        )}
      />
    );

  const compositions = detail?.results.map((item: any) => {
    return item.composition;
  });

  const composite = compositions?.join();
  const arrComposition = composite?.split(",");
  let removeDuplicate: any = arrComposition
    ?.filter((item: any) => {
      return item !== "";
    })
    .filter((item: any) => {
      return item.toLowerCase().includes(text);
    })
    .map((item: any) => {
      if (item.includes(" ")) {
        let char = item[item.indexOf(" ") + 1].toUpperCase();
        item = item.replace(item[item.indexOf(" ") + 1], char);
      }
      return item;
    });

  let search = detail?.results?.filter((item: any) => {
    return Object?.keys(item)?.some((key: any) =>
      item[key]
        ?.toString()
        .toLowerCase()
        .includes(text?.toString().toLowerCase())
    );
  });

  removeDuplicate = [...new Set(removeDuplicate)];
  if (removeDuplicate.length > 0 && text) {
    removeDuplicate.unshift("✔️ Keep Searching...");
  } else if (removeDuplicate.length === 0 && text && search.length === 0) {
    removeDuplicate.unshift("❌ Invalid Search");
  } else if (search?.length > 0 && removeDuplicate.length === 0) {
    removeDuplicate.unshift("✔️ Image, Product Name or Price Search");
  } else {
    removeDuplicate.unshift("✔️ List of Composition");
  }

  const manufacturerListing = productManufacturer?.results.map((item: any) => {
    return (
      <Link
        to={`/manufacturer/${item.slug}/${currPage}/${item.id}`}
        key={item.id}
      >
        <FormControlLabel
          sx={{
            fontWeight: 400,
            color: "#8692a6",
            fontSize: "14px",
          }}
          value={item.name}
          onChange={(e: any) => setProductManufacturer(e.currentTarget.value)}
          control={<Radio />}
          label={item.name.replaceAll(",", ", ").replaceAll("and", "&")}
          checked={slug === item.slug}
        />
      </Link>
    );
  });

  return (
    <Layout>
      <Box>
        <ProductListLayout setParams={setParams}>
          <Box>
            {/* <Divider sx={{ mt: 2.2, mb: 1 }} /> */}

            {isLoading ? (
              <>
                <div
                  className="productListImg"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {productManufacturer?.length > 0 || findById !== undefined ? (
                    <div>
                      <img
                        width={156}
                        style={{
                          margin: "10px 15px 10px 0",
                        }}
                        src={findById?.logo}
                        alt={`${findById?.name} logo`}
                      />
                    </div>
                  ) : null}

                  <div>
                    <h1>
                      {detail?.results.length === 0
                        ? brandName
                        : detail?.results[0].manufacturer}
                    </h1>
                  </div>
                </div>
                <p>
                  {detail?.count === 0
                    ? "Products are currently unavailable"
                    : "All products listed are owned by above brand."}
                </p>
              </>
            ) : (
              <Loading />
            )}
            <Box
              sx={{
                overflowX: "auto",
                display: "flex",
                flexWrap: "nowrap",
                marginTop: "32px",
                mb: 2.2,
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Button
                sx={{
                  mr: 2,
                  fontSize: "12px",
                  textTransform: "initial",
                  minWidth: "176px",
                  maxHeight: "33px",
                  backgroundColor: "#00000014",
                  color: "#000000de",
                  borderRadius: "16px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.12);",
                  },
                }}
                id="manufacturer-customized-button"
                aria-controls={
                  openManufacturer ? "manufacturer-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openManufacturer ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleManufacturerClick}
                endIcon={
                  <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                }
                startIcon={<FactoryRounded sx={{ color: "#616161" }} />}
              >
                Manufacturer
              </Button>
              <StyledMenu
                id="manufacturer-products"
                MenuListProps={{
                  "aria-labelledby": "manufacturer",
                }}
                anchorEl={anchorManufacturerEl}
                open={openManufacturer}
                onClose={handleManufacturerClose}
              >
                <FormControl
                  sx={{ padding: "16px", width: "400px", height: "300px" }}
                >
                  {manufacturerListing}
                </FormControl>
              </StyledMenu>

              <Button
                sx={{
                  mr: 2,
                  fontSize: "12px",
                  textTransform: "initial",
                  minWidth: "176px",
                  maxHeight: "33px",
                  backgroundColor: "#00000014",
                  color: "#000000de",
                  borderRadius: "16px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.12);",
                  },
                }}
                id="composition-customized-button"
                aria-controls={
                  openCompositionInput
                    ? "composition-customized-menu"
                    : undefined
                }
                aria-haspopup="true"
                aria-expanded={openCompositionInput ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleCompositionInputClick}
                endIcon={
                  <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                }
                startIcon={<VaccinesRounded sx={{ color: "#616161" }} />}
              >
                Composition
              </Button>

              <StyledMenu
                id="composition-list"
                MenuListProps={{
                  "aria-labelledby": "composition-list",
                }}
                anchorEl={anchorCompositionInputEl}
                open={openCompositionInput}
                onClose={handleCompositionInputClose}
              >
                <Box
                  sx={{
                    width: "{ xs: 300, sm: 450, lg: 550, xl: 600 }",
                    minHeigth: 92.688,
                    maxHeight: 300,
                    overflowY: "auto",
                  }}
                >
                  <Box>
                    <Box sx={{ padding: "0 16px 6px 8px" }}>
                      <form onSubmit={handleSubmit}>
                        <TextField
                          sx={{ mb: -5 }}
                          placeholder="Search..."
                          value={`${text.toLowerCase()}`}
                          onChange={handleChange.bind(this)}
                        />
                      </form>
                    </Box>
                    {removeDuplicate[0] ===
                    "✔️ Image, Product Name or Price Search" ? (
                      <Box sx={{ marginTop: 6 }}>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            color: "#8692A6",
                            fontSize: "14px",
                            padding: "0 16px 6px 16px",
                          }}
                        >
                          ✔️ Image, Product Name or Price Search
                        </p>
                        {search?.map((item: any) => {
                          return (
                            <p
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "400",
                                color: "#8692A6",
                                fontSize: "14px",
                                padding: "0 16px 6px 16px",
                              }}
                            >
                              {item.name} &mdash; {priceFormat(item.price)}
                            </p>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box sx={{ marginTop: 6 }}>
                        {removeDuplicate?.map((item: any) => {
                          return (
                            <p
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "400",
                                color: "#8692A6",
                                fontSize: "14px",
                                padding: "0 16px 6px 16px",
                              }}
                            >
                              {item.toUpperCase()[0]}
                              {item.slice(1)}
                            </p>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                </Box>
              </StyledMenu>

              <Button
                sx={{
                  mr: 2,
                  fontSize: "12px",
                  textTransform: "initial",
                  minWidth: "176px",
                  maxHeight: "33px",
                  backgroundColor: "#00000014",
                  color: "#000000de",
                  borderRadius: "16px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.12);",
                  },
                }}
                id="price-customized-button"
                aria-controls={
                  openPriceRange ? "price-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openPriceRange ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleRangePriceRangeClick}
                endIcon={
                  <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                }
                startIcon={<Money sx={{ color: "#616161" }} />}
              >
                Price Range
              </Button>
              <StyledMenu
                id="price-products"
                MenuListProps={{
                  "aria-labelledby": "price-range",
                }}
                anchorEl={anchorPriceRangeEl}
                open={openPriceRange}
                onClose={handlePriceRangeClose}
              >
                <Box
                  sx={{
                    width: "400px",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Slider
                    sx={{
                      width: 300,
                    }}
                    min={0}
                    max={maxPriceRange}
                    step={1}
                    getAriaLabel={() => "range"}
                    value={priceRange}
                    onChange={handlePriceRangeChange}
                    valueLabelDisplay="auto"
                  />
                  <Box
                    sx={{
                      width: "313px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#8692A6",
                    }}
                  >
                    <div>{0}</div>
                    <div>{maxPriceRange}</div>
                  </Box>
                </Box>
              </StyledMenu>

              <Button
                sx={{
                  mr: 2,
                  fontSize: "12px",
                  textTransform: "initial",
                  minWidth: "176px",
                  maxHeight: "33px",
                  backgroundColor: "#00000014",
                  color: "#000000de",
                  borderRadius: "16px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.12);",
                  },
                }}
                id="quantity-customized-button"
                aria-controls={
                  openQunatityRange ? "quantity-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openQunatityRange ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleRangeQunatityRangeClick}
                endIcon={
                  <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                }
                startIcon={<Equalizer sx={{ color: "#616161" }} />}
              >
                Qunatity Range
              </Button>
              <StyledMenu
                id="qty-products"
                MenuListProps={{
                  "aria-labelledby": "quantity-range",
                }}
                anchorEl={anchorQunatityRangeEl}
                open={openQunatityRange}
                onClose={handleQuantityRangeClose}
              >
                <Box
                  sx={{
                    width: "400px",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Slider
                    sx={{
                      width: 300,
                    }}
                    min={0}
                    max={maxQuantityRange}
                    step={1}
                    getAriaLabel={() => "range"}
                    value={quantityRange}
                    onChange={handleQuantityRangeChange}
                    valueLabelDisplay="auto"
                  />
                  <Box
                    sx={{
                      width: "313px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#8692A6",
                    }}
                  >
                    <div>{0}</div>
                    <div>{maxQuantityRange}</div>
                  </Box>
                </Box>
              </StyledMenu>

              <Button
                sx={{
                  mr: 2,
                  fontSize: "12px",
                  textTransform: "initial",
                  minWidth: "176px",
                  maxHeight: "33px",
                  backgroundColor: "#00000014",
                  color: "#000000de",
                  borderRadius: "16px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.12);",
                  },
                }}
                id="order-customized-button"
                aria-controls={open ? "order-customized-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleOrderByClick}
                endIcon={
                  <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                }
                startIcon={<SortOutlined sx={{ color: "#616161" }} />}
              >
                Order by
              </Button>
              <StyledMenu
                id="order-products"
                MenuListProps={{
                  "aria-labelledby": "order",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <FormControl
                  sx={{ padding: "16px", width: "400px", height: "300px" }}
                >
                  {/*<FormLabel id="demo-radio-buttons-group-label">
                    Order By
              </FormLabel>*/}
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value=""
                      onChange={handleCreatedSortChange}
                      control={<Radio />}
                      label="Relevant Products"
                      checked={selected === ""}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="descComposition"
                      onChange={handleCompositionSortChange}
                      control={<Radio />}
                      label="Composition (A - Z)"
                      checked={selected === "descComposition"}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="ascComposition"
                      onChange={handleCompositionSortChange}
                      control={<Radio />}
                      label="Composition (Z - A)"
                      checked={selected === "ascComposition"}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="ascCreated"
                      onChange={handleCreatedSortChange}
                      control={<Radio />}
                      label="Newest Products"
                      checked={selected === "ascCreated"}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="ascQuantity"
                      onChange={handleQuantitySortChange}
                      control={<Radio />}
                      label="Quantity: Low to High"
                      checked={selected === "ascQuantity"}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="descQuantity"
                      onChange={handleQuantitySortChange}
                      control={<Radio />}
                      label="Quantity: High to Low"
                      checked={selected === "descQuantity"}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="ascProductName"
                      onChange={handleProductNameSortChange}
                      control={<Radio />}
                      label="Product Name (A - Z)"
                      checked={selected === "ascProductName"}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="descProductName"
                      onChange={handleProductNameSortChange}
                      control={<Radio />}
                      label="Product Name (Z - A)"
                      checked={selected === "descProductName"}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="ascPrice"
                      onChange={handlePriceSortChange}
                      control={<Radio />}
                      label="Price: Low to High"
                      checked={selected === "ascPrice"}
                    />
                    <FormControlLabel
                      sx={{
                        fontWeight: 400,
                        color: "#8692a6",
                        fontSize: "14px",
                      }}
                      value="descPrice"
                      onChange={handlePriceSortChange}
                      control={<Radio />}
                      label="Price: High to Low"
                      checked={selected === "descPrice"}
                    />
                  </RadioGroup>
                </FormControl>
              </StyledMenu>
            </Box>
            <Divider sx={{ mb: 1 }} />
            {(
              <Content>
                results:{" "}
                {detail?.count > 1
                  ? `${detail?.count} products`
                  : detail?.count === "No Product Available"
                  ? 0
                  : detail?.count === undefined
                  ? "Loading..."
                  : `${detail?.count} product`}
              </Content>
            ) && (
              <Content>
                results:{" "}
                {search?.length === 0
                  ? "No Product Available"
                  : search?.length === 1
                  ? `${search?.length} product`
                  : search?.length === undefined
                  ? "loading..."
                  : `${search?.length} products`}
              </Content>
            )}
            {isLoading ? (
              <Grid sx={{ marginTop: 1 }} container spacing={3}>
                {detail?.results.length === 0 || search?.length === 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItem: "center",
                      flexDirection: "column",
                      height: 150,
                      ml: "auto",
                      mr: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "359px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#8692A6",
                      }}
                    >
                      No Item Available Under Brand,{" "}
                      {detail?.results.length === 0
                        ? brandName
                        : detail?.results[0].manufacturer}{" "}
                    </Box>
                    <Box>
                      {navigator.onLine ? null : (
                        <span style={{ color: "red", fontWeight: 300 }}>
                          Internet Connection Lost
                        </span>
                      )}
                    </Box>
                  </Box>
                ) : (
                  search?.map((element: any, index: number) => (
                    <Grid key={index} item xs={6} sm={4} md={3}>
                      <Products
                        id={element?.id}
                        title={element?.name}
                        image={element?.image}
                        description={element?.desc}
                        trigger={params}
                        price={element?.price}
                        slug={element?.slug}
                        quantity_in_cart={element?.quantity_in_cart}
                        in_cart={element?.in_cart}
                        quantity={element?.quantity}
                        is_favorite={element?.is_favorite}
                        manufacturer={element?.manufacturer}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            ) : (
              <>
                <Loading />
                <Box
                  sx={{
                    width: "100%",
                    height: "359px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#8692A6",
                  }}
                >
                  <Box>No Item Available On This Page</Box>
                  <Box>
                    <Button
                      variant="outlined"
                      startIcon={<Refresh />}
                      href={pathname}
                    >
                      Refresh
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </ProductListLayout>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            top:
              detail?.results.length === 0 || search?.length === 0
                ? "300px"
                : "50px",
          }}
        >
          {detail?.next ? pagination : detail?.previous ? pagination : null}
        </Box>
      </Box>
    </Layout>
  );
}

export default Manufacturers;
