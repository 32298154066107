import { Add } from "@mui/icons-material";
import { Box, Button, Pagination, PaginationItem } from "@mui/material";
import "./Account.General.css";
import {
  CogIcon,
  Dialog,
  EmptyState,
  IconButton,
  majorScale,
  Menu,
  Popover,
  Position,
  SearchIcon,
  Table,
} from "evergreen-ui";
import { useState, useEffect, useReducer } from "react";
import AccountLayout from "../../components/AccountLayout.component";
import { SHIPPING_ADDRESS } from "../../constants/_api.constant";
import { shippingAddress } from "../../constants/_form.constant";
import Cards from "../../element/card";
import FormRender from "../../forms/formRender";
import { Content, Title } from "../../styles";
import { request } from "../../utilities/connectHooks";
import { Link, useLocation } from "react-router-dom";
import Loading from "../Loading";

function Shipping() {
  const [open, setOpen] = useState(false);
  const [pageQuery, setPageQuery] = useState(1);
  const [data, setData] = useState<any>();
  const [deleteState, setDeleteState] = useState<any>();
  const [isDefaultAddress, setIsDefaultAddress] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { key } = useLocation();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        "https://staging.drugstoc.com/api/v2/shipping/shipping-address",
        {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setData(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [pageQuery, isDefaultAddress, key, reducerValue]);

  const location = useLocation();
  const param = location.pathname;
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);

  const pathArr = param?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1).replace('_', ' ')}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  const handlePageChange = (e: any, value: any) => {
    setPageQuery(value);
  };

  const pagination = (
    <Pagination
      page={page}
      count={Math.ceil(data?.count / 100)}
      color="primary"
      shape="rounded"
      onChange={handlePageChange}
      onClick={() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      }}
      renderItem={(item: any) => (
        <PaginationItem
          component={Link}
          to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
          {...item}
        />
      )}
    />
  );

  const submit = (e: any) => {
    e.is_default_address = false;
    request
      .post({ path: SHIPPING_ADDRESS, trigger: SHIPPING_ADDRESS, payload: e })
      .then((res) => {
        if (res) {
          setOpen(false);
        }
      });
    forceUpdate();
  };

  const handleClickDelete = async (id: number) => {
    await fetch(`https://staging.drugstoc.com${SHIPPING_ADDRESS}/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `token ${localStorage.getItem("token")}`,
      },
    });
    // if (window.confirm("Are you sure you want to delete?")) {
    setDeleteState(
      deleteState?.results.map((item: any) => {
        return item.id !== id;
      })
    );
    forceUpdate();
    // }
  };

  const handleClickDefault = async (id: number) => {
    const defaultValue = {
      is_default_address: true,
    };
    const response = await fetch(
      `https://staging.drugstoc.com${SHIPPING_ADDRESS}/${id}`,
      {
        method: "PUT",
        headers: {
          Authorization: `token ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(defaultValue),
      }
    );
    const data = await response.json();
    setIsDefaultAddress(data);
  };

  const Modal = () => {
    return (
      <Box>
        <Dialog
          isShown={open}
          title="Add Shipping Address"
          preventBodyScrolling
          hasFooter={false}
          onCloseComplete={() => setOpen(!open)}
        >
          <FormRender data={shippingAddress} onSubmitForm={submit} />
        </Dialog>

        <Button
          disableElevation
          variant="contained"
          onClick={() => setOpen(!open)}
          sx={{
            height: 40,
            fontSize: "10px",
            textTransform: "initial",
          }}
          startIcon={<Add />}
        >
          Add New Address
        </Button>
      </Box>
    );
  };

  return (
    <AccountLayout>
      <Box>
        <Box mb={5}>
          <Title variant="h4" sx={{ paddingTop: 1, paddingBottom: 1 }}>
            Shipping Address
          </Title>
          <Content>Let`s get you started with DrugStoc</Content>
        </Box>
        <Cards title="Addresses" action={<Modal />}>
          <Box mt={5} sx={{ overflow: "auto" }}>
            <Table style={{ width: 800 }}>
              <Table.Head>
                <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                <Table.TextHeaderCell>Address</Table.TextHeaderCell>
                <Table.TextHeaderCell>Phone number</Table.TextHeaderCell>
                <Table.TextHeaderCell>City</Table.TextHeaderCell>
                <Table.TextHeaderCell>Default</Table.TextHeaderCell>
                <Table.TextHeaderCell>action</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body height="auto">
                {isLoading ? (
                  data?.results.length === 0 ? (
                    <EmptyState
                      background="light"
                      title="No tracked events for this Source"
                      orientation="horizontal"
                      icon={<SearchIcon color="#C1C4D6" />}
                      iconBgColor="#EDEFF5"
                      description="Events appear when the Source starts sending data to Segment about your users and their activity."
                    />
                  ) : (
                    data?.results.map((profile: any) => (
                      <Table.Row key={profile.id} isSelectable>
                        <Table.TextCell>
                          {profile.first_name} {profile.last_name}
                        </Table.TextCell>
                        <Table.TextCell>
                          {profile.address_line1} {profile.address_line2}
                        </Table.TextCell>
                        <Table.TextCell textAlign="center" isNumber>
                          {profile.phone_number1}
                        </Table.TextCell>
                        <Table.TextCell>{profile.region}</Table.TextCell>
                        <Table.TextCell>
                          {profile.is_default ? "True" : "False"}
                        </Table.TextCell>
                        <Table.TextCell>
                          <Popover
                            position={Position.BOTTOM_RIGHT}
                            content={
                              <Menu>
                                <Menu.Group>
                                  <Menu.Item
                                    onSelect={() => console.log("oooop")}
                                  >
                                    Edit
                                  </Menu.Item>
                                  <Menu.Item
                                    onSelect={() =>
                                      handleClickDelete(profile.id)
                                    }
                                  >
                                    Delete
                                  </Menu.Item>
                                  <Menu.Item
                                    onSelect={() =>
                                      handleClickDefault(profile.id)
                                    }
                                  >
                                    Set As Default
                                  </Menu.Item>
                                </Menu.Group>
                              </Menu>
                            }
                          >
                            <IconButton
                              icon={CogIcon}
                              marginRight={majorScale(2)}
                            />
                          </Popover>
                        </Table.TextCell>
                      </Table.Row>
                    ))
                  )
                ) : (
                  <Loading />
                )}
              </Table.Body>
            </Table>
          </Box>
        </Cards>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
            top: "50px",
          }}
        >
          {isLoading
            ? data?.next
              ? pagination
              : data?.previous
              ? pagination
              : null
            : null}
        </Box>
      </Box>
    </AccountLayout>
  );
}

export default Shipping;
