import { Box, Link, Stack } from "@mui/material";
import { Alert } from "evergreen-ui";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import AuthLayout from "../components/AuthLayout.component";
import { LOGIN_USER } from "../constants/_api.constant";
import { LoginFormData } from "../constants/_form.constant";
import FormRender from "../forms/formRender";
import { Validation } from "../forms/_validations";
import { Center, Content, Title } from "../styles";
import { authenticate } from "../utilities/connectHooks";

function Login() {
  const [err, setErr] = useState([]);
  const { pathname } = useLocation();

  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1)}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  const SubmitData = (e: any) => {
    authenticate(LOGIN_USER, e).then((resp) => {
      if (!resp.success) {
        setErr(resp.error);
      } else {
        console.log(resp);
        window.location.href = "/account";
        setErr([]);
      }
    });
  };

  return (
    <AuthLayout>
      <Box>
        <Content
          display="block"
          gutterBottom
          sx={{ width: "fit-content", marginLeft: "auto" }}
        >
          Don`t have an account?{" "}
          <Link underline="none" sx={{ cursor: "pointer" }} href="/register">
            Sign up
          </Link>
        </Content>

        <Center>
          <Title variant="h5">Welcome Back to DrugStoc</Title>
          <Box mb={2}>
            <Stack>
              {err.map((element: any, index: number) => (
                <Alert intent="danger" key={index} title={element.message} />
              ))}
            </Stack>
          </Box>
          <Box mb={5}>
            <FormRender
              data={LoginFormData}
              submitButtonText={"Login"}
              validationSchema={Validation.loginValidation}
              fullButton
              onSubmitForm={SubmitData}
            />
          </Box>
        </Center>
      </Box>
    </AuthLayout>
  );
}

export default Login;
