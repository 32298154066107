import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { Badge, Table } from "evergreen-ui";
import React, { useEffect } from "react";
import Layout from "../components/Layout.component";
import {
  CART_ITEMS,
  PURCHASE_HISTORY,
  SHIPPING_ADDRESS,
  USER_PROFILE,
} from "../constants/_api.constant";
import { orderForm } from "../constants/_form.constant";
import Cards from "../element/card";
import FormRender from "../forms/formRender";
import { Validation } from "../forms/_validations";
import { Content } from "../styles";
import { request, useConnect } from "../utilities/connectHooks";
import { usePaystackPayment } from "react-paystack";
import { priceFormatDecimal } from "../utilities/priceFormatter";
import { Link, useLocation } from "react-router-dom";

function Checkout() {
  const { data: personal } = useConnect({ path: USER_PROFILE });
  const { data, isFetching, hasError } = useConnect({ path: CART_ITEMS });
  const { data: address } = useConnect({ path: SHIPPING_ADDRESS });
  const [isOpen, SetIsOpen] = React.useState(true);

  const closeModal = () => {
    SetIsOpen((prev) => {
      return !prev;
    });
  };

  const { pathname } = useLocation();

  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1)}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  function getTotal() {
    return data?.results.reduce(function (a: any, b: any) {
      return a + b.product.price * b.quantity;
    }, 0);
  }

  const config: any = {
    reference: new Date().getTime(),
    email: personal?.email,
    amount: getTotal() * 100,
    publicKey: "pk_live_45ee06a67470f003a29bacea44b2117e90c0fec9",
  };

  const initializePayment = usePaystackPayment(config);

  // you can call this function anything
  const onSuccess = (reference: any, data: any) => {
    data.transaction_no = reference.trxref;
    request
      .post({
        path: PURCHASE_HISTORY,
        trigger: PURCHASE_HISTORY,
        payload: data,
      })
      .then((resp) => {
        if (resp) {
          window.location.replace("/account/purchase_history");
        }
      });
    // Implementation for whatever you want to do with reference and after success call.
    // console.log(reference, data);
  };

  // you can call this function anything
  const onClose = (e: void) => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    // console.log(e);
  };

  let shipping = address?.results.map((e: any, index: number) => ({
    key: index,
    value: e.id,
    text: `${e.address_line1} ${e.address_line2} ${e.region}`,
  }));

  const checkOut = (data: any) => {
    if (data.payment_method === "card_payment") {
      initializePayment((ref: void) => onSuccess(ref, data), onClose);
    } else {
      request
        .post({
          path: PURCHASE_HISTORY,
          trigger: PURCHASE_HISTORY,
          payload: data,
        })
        .then((resp) => {
          if (resp) {
            window.location.replace("/account/purchase_history");
            alert("Your Order is confirmed");
          }
        });
    }
  };
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Layout>
      <div id="checkout">
        <Box mt={5}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
              <Table>
                <Table.Head>
                  <Table.TextHeaderCell>Image</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Name</Table.TextHeaderCell>
                  <Table.TextHeaderCell>Quantity</Table.TextHeaderCell>
                  <Table.TextHeaderCell>total amount</Table.TextHeaderCell>
                </Table.Head>
                <Table.Body height="auto">
                  {data?.results.map((profile: any, index: number) => (
                    <Table.Row key={index}>
                      <Table.TextCell>
                        <Box
                          component="img"
                          loading="lazy"
                          sx={{
                            width: "100%",
                            height: { xs: 60, md: 60 },
                            borderRadius: 2,
                            cursor: "pointer",
                            objectFit: "contain",
                          }}
                          alt="product image"
                          src={profile.product.image}
                        />
                      </Table.TextCell>
                      <Table.TextCell>{profile.product.name}</Table.TextCell>
                      <Table.TextCell textAlign="center">
                        {profile.quantity}
                      </Table.TextCell>
                      <Table.TextCell isNumber>
                        {priceFormatDecimal(
                          profile.product.price * profile.quantity
                        )}
                      </Table.TextCell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Box mt={2} />
              <Cards title="Select Options">
                <Box>
                  <FormRender
                    data={orderForm}
                    validationSchema={Validation.checkoutValidation}
                    options={{ name: "shipping", data: shipping }}
                    onSubmitForm={checkOut}
                    submitButtonText="Create Order"
                  />
                </Box>
              </Cards>
              {address?.results.length === 0 ? (
                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                  sx={{ display: isOpen ? "block" : "none" }}
                >
                  <Box sx={style}>
                    <Typography
                      id="modal-modal-title"
                      variant="h6"
                      component="h2"
                      sx={{ textAlign: "center" }}
                    >
                      Shipping Setting
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Button
                          variant="contained"
                          href="/account/shipping_address"
                          sx={{ mr: 8 }}
                        >
                          Create
                        </Button>
                        {/* MuiBox-root css-1wnsr1i */}
                        <div onClick={closeModal}>
                          <Button variant="contained" sx={{ ml: 8 }}>
                            Cancel
                          </Button>
                        </div>
                      </Box>
                    </Typography>
                  </Box>
                </Modal>
              ) : null}
            </Grid>
            <Grid item xs={12} md={4}>
              <Cards title="Returns are easy">
                <Content sx={{ fontSize: 12, marginTop: 2 }}>
                  <Typography sx={{ mb: 2, fontSize: "12px" }}>
                    This package has been sealed and should only be opended by
                    he intented recipient. If you are not the intented recipient
                    or it has been mistakenly delivered to you, kindly return to
                    us by calling +234(0) 1 7005571.
                  </Typography>
                  <Typography sx={{ mb: 2, fontSize: "12px" }}>
                    To return a product, kindly contact Customer Service Rep.
                    via call – 01700557 or your designated sales representative
                    within 48 hours of receiving the product. A return officer
                    will schedule a pickup upon confirmation from any of our
                    team.
                  </Typography>
                  <Typography sx={{ mb: 2, fontSize: "12px" }}>
                    Goods received cannot be returned after 72hours (3 days) of
                    delivery. Cold chain products, Injectibles and Controlled
                    drugs cannot be returned once received. They can only be
                    returned immediately at the point of supply.
                  </Typography>
                  <Typography sx={{ mb: 2, fontSize: "12px" }}>
                    Drugstoc E-hub reserves the right to reject any returned
                    item if:
                  </Typography>
                  <ul>
                    <li>Without a Goods Returned Notes</li>
                    <li>found damaged or compromised in any way,</li>
                    <li>found damaged or compromised in any way,</li>
                    <li>Tampered with using inks, tags etc</li>
                  </ul>
                </Content>
              </Cards>
            </Grid>
          </Grid>
        </Box>
      </div>
    </Layout>
  );
}

export default Checkout;
