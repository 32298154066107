import {
  Box,
  Grid,
  Pagination,
  PaginationItem,
  FormControl,
  TextField,
  Button,
  Menu,
  Slider,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import {
  KeyboardArrowDown,
  Equalizer,
  Money,
  VaccinesRounded,
  CategoryRounded,
  Refresh,
} from "@mui/icons-material";
import { MenuProps } from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import { useEffect, useState } from "react";
import ProductListLayout from "../components/ProductListLayout.component";
import Layout from "../components/Layout.component";
import { getResultUrl, useConnect } from "../utilities/connectHooks";
import { Link, useLocation, useParams } from "react-router-dom";
import Products from "../components/Products.component";
import Loading from "./Loading";
import Divider from "@mui/material/Divider";
import { SortOutlined } from "@mui/icons-material";
import {
  BUSINESS_PROFILE,
  CATEGORY_PRODUCT,
  PRODUCT_CATEGORY,
  SEARCH_PRODUCT,
} from "../constants/_api.constant";
import { Content } from "../styles";
import { priceFormat } from "../utilities/priceFormatter";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "8px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function Results(this: {
  path: string;
  component: () => JSX.Element;
  protected: boolean;
}) {
  const router = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorQunatityRangeEl, setAnchorQuantityRangeEl] =
    useState<null | HTMLElement>(null);
  const [anchorCategoryEl, setAnchorCategoryEl] = useState<null | HTMLElement>(
    null
  );
  const [anchorPriceRangeEl, setAnchorPriceRangeEl] =
    useState<null | HTMLElement>(null);
  const [anchorCompositionInputEl, setAnchorCompositionInputEl] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openQunatityRange = Boolean(anchorQunatityRangeEl);
  const openCategory = Boolean(anchorCategoryEl);
  const openPriceRange = Boolean(anchorPriceRangeEl);
  const openCompositionInput = Boolean(anchorCompositionInputEl);
  const { url } = getResultUrl(router);
  const [params, setParams] = useState("");
  const path = url + params;
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const param = location.pathname;
  const query = new URLSearchParams(location.search);
  const page = parseInt(query.get("page") || "1", 10);
  const [compositionSort, setCompositionSort] = useState("");
  const [categorySort, setCategorySort] = useState("");
  const [priceSort, setPriceSort] = useState("");
  const [manufacturerSort, setManufacturerSort] = useState("");
  const [quantitySort, setQuantitySort] = useState("");
  const [productNameSort, setProductNameSort] = useState("");
  const [createdSort, setcreatedSort] = useState("");
  const [selected, setSelected] = useState("");
  const [sort, setSort] = useState<any>();
  const [text, setText] = useState("");
  const [error, setError] = useState("");
  const [category, setCategory] = useState<any>();
  const [searchQuery, setSearchQuery] = useState<any>();
  const { slug }: any = useParams();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setText("");
  };

  let ordering = "category";
  if (compositionSort === "ascComposition") {
    ordering = "ordering=composition";
  } else if (compositionSort === "descComposition") {
    ordering = "ordering=-composition";
  }

  if (createdSort === "ascCreated") {
    ordering = "ordering=created_at";
  } else if (createdSort === "descCreated") {
    ordering = "ordering=-created_at";
  }

  if (priceSort === "ascPrice") {
    ordering = "ordering=price";
  } else if (priceSort === "descPrice") {
    ordering = "ordering=-price";
  }

  if (manufacturerSort === "ascManufacturer") {
    ordering = "ordering=manufacturer";
  } else if (manufacturerSort === "descManufacturer") {
    ordering = "ordering=-manufacturer";
  }

  if (quantitySort === "ascQuantity") {
    ordering = "ordering=quantity";
  } else if (quantitySort === "descQuantity") {
    ordering = "ordering=-quantity";
  }

  if (productNameSort === "ascProductName") {
    ordering = "ordering=name";
  } else if (productNameSort === "desc") {
    ordering = "ordering=-name";
  }

  const [pageQuery, setPageQuery] = useState(1);

  const handleOrderByClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRangeQunatityRangeClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorQuantityRangeEl(event.currentTarget);
  };

  const handleRangePriceRangeClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorPriceRangeEl(event.currentTarget);
  };

  const handleCompositionInputClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorCompositionInputEl(event.currentTarget);
  };

  const handleCategoryClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorCategoryEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCategoryClose = () => {
    setAnchorCategoryEl(null);
  };

  const handleQuantityRangeClose = () => {
    setAnchorQuantityRangeEl(null);
  };

  const handlePriceRangeClose = () => {
    setAnchorPriceRangeEl(null);
  };

  const handleCompositionInputClose = () => {
    setAnchorCompositionInputEl(null);
  };

  const handleQuantityRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setQuantityRange(newValue as number[]);
  };

  const handlePriceRangeChange = (
    event: Event,
    newValue: number | number[]
  ) => {
    setPriceRange(newValue as number[]);
  };

  const handlePageChange = (e: any, value: any) => {
    setPageQuery(value);
  };

  const handleChange = (e: any) => {
    setText(e.target.value);
  };

  const handleCategorySortChange = (event: any) => {
    setCategorySort(event.target.value);
    setSelected(event.target.value);
  };

  const handleCompositionSortChange = (event: any) => {
    setCompositionSort(event.target.value);
    setSelected(event.target.value);
  };

  const handleCreatedSortChange = (event: any) => {
    setcreatedSort(event.target.value);
    setSelected(event.target.value);
  };

  const handlePriceSortChange = (event: any) => {
    setPriceSort(event.currentTarget.value);
    setSelected(event.target.value);
  };

  const handleManufacturerSortChange = (event: any) => {
    setManufacturerSort(event.target.value);
    setSelected(event.target.value);
  };

  const handleQuantitySortChange = (event: any) => {
    setQuantitySort(event.target.value);
    setSelected(event.target.value);
  };

  const handleProductNameSortChange = (event: any) => {
    setProductNameSort(event.target.value);
    setSelected(event.target.value);
  };

  // const minQty = sort?.results
  //   .map((item: any) => {
  //     return item.quantity;
  //   })
  //   .sort((a: any, b: any) => a - b)[0];

  const urlPath = document.location.href.split("/");

  const maxQty = sort?.results
    .map((item: any) => {
      return item.quantity;
    })
    .sort((a: any, b: any) => a - b)[
    sort?.results
      .map((item: any) => {
        return item.quantity;
      })
      .sort((a: any, b: any) => a - b).length - 1
  ];

  const [maxQuantityRange, setMaxQuantityRange] = useState<any>();
  const [maxPriceRange, setMaxPriceRange] = useState<any>();

  const { data: business } = useConnect({ path: BUSINESS_PROFILE });


  const categoryListing = category?.results.map((item: any) => {
    return (
      <Link to={`/category/${item.slug}`} key={item.id}>
        <FormControlLabel
          sx={{
            fontWeight: 400,
            color: "#8692a6",
            fontSize: "14px",
          }}
          value={item.name}
          onChange={(e: any) => setCategory(e.currentTarget.value)}
          control={<Radio />}
          label={item.name.replaceAll(",", ", ").replaceAll("and", "&")}
          checked={slug === item.slug}
        />
      </Link>
    );
  });

  const titleSearch = document.location.href.split("=");

  const { pathname } = useLocation();
  const name = pathname
    .replaceAll("-", " ")
    .replaceAll("/", " ")
    .replace("category", "")
    .trim();
  useEffect(() => {
    const pathArr = pathname?.split("/");
    let currentPage: any;
    if (pathArr?.includes("category")) {
      currentPage = `Category`;
    } else if (pathArr?.includes("search")) {
      currentPage = titleSearch[titleSearch.length - 1];
    }
    document.title = `${name[0].toUpperCase()}${name.slice(
      1
    )} | ${currentPage}`;
  }, [name, pathname, titleSearch]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com${CATEGORY_PRODUCT}/${slug}?&page=${pageQuery}`,
        business && {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status === 401) {
        setError(`Operation Is ${response.statusText}, Login First`);
      }
      const data = await response.json();
      setMaxQuantityRange(
        data?.results
          .map((item: any) => item.quantity)
          .sort((a: any, b: any) => a - b)[
          data?.results
            .map((item: any) => item.quantity)
            .sort((a: any, b: any) => a - b).length - 1
        ]
      );
      setMaxPriceRange(
        data?.results
          .map((item: any) => item.price)
          .sort((a: any, b: any) => a - b)[
          data?.results
            .map((item: any) => item.price)
            .sort((a: any, b: any) => a - b).length - 1
        ]
      );
      setIsLoading(true);
    };
    fetchAPI();
  }, [pageQuery, slug]);

  const [quantityRange, setQuantityRange] = useState<number[]>([
    maxQty || 0,
    maxQty || Number.MAX_SAFE_INTEGER,
  ]);

  const minPrice = sort?.results
    .map((item: any) => {
      return item.price;
    })
    .sort((a: any, b: any) => {
      return a - b;
    })[0];

  const maxPrice = sort?.results
    .map((item: any) => {
      return item.price;
    })
    .sort((a: any, b: any) => {
      return a - b;
    })[
    sort?.results
      .map((item: any) => {
        return item.price;
      })
      .sort((a: any, b: any) => {
        return a - b;
      }).length - 1
  ];

  const [priceRange, setPriceRange] = useState<number[]>([
    minPrice || 0,
    maxPrice || Number.MAX_SAFE_INTEGER,
  ]);

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com${PRODUCT_CATEGORY}`,
        business && {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setCategory(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [business]);

  const slugID = category?.results.find((item: any) => {
    return item.slug === slug;
  });

  useEffect(() => {
    const fetchAPI = async () => {
      const response = await fetch(
        `https://staging.drugstoc.com${CATEGORY_PRODUCT}/${
          categorySort || slug
        }?category=${
          slugID?.id
        }&${ordering}&price__range=${priceRange}&quantity__range=${quantityRange}&page=${pageQuery}`,
        business && {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await response.json();
      setSort(data);
      setIsLoading(true);
    };
    fetchAPI();
  }, [business, categorySort, ordering, pageQuery, priceRange, quantityRange, slug, slugID?.id]);

  const compositions = sort?.results.map((item: any) => {
    return item.composition;
  });

  const composite = compositions?.join();
  const arrComposition = composite?.split(",");
  let removeDuplicate: any = arrComposition
    ?.filter((item: any) => {
      return item !== "";
    })
    .filter((item: any) => {
      return item.toLowerCase().includes(text);
    })
    .map((item: any) => {
      if (item.includes(" ")) {
        let char = item[item.indexOf(" ") + 1].toUpperCase();
        item = item.replace(item[item.indexOf(" ") + 1], char);
      }
      return item;
    });

  const paramURL = document.location.href.split("/");
  const lastIndex = document.location.href.split("=");
  const searchParam = lastIndex[lastIndex.length - 1];

  const checkParams = paramURL.includes("search");


  useEffect(() => {
    const fetchAPI = async () => {
      const res: Response = await fetch(
        `https://staging.drugstoc.com${SEARCH_PRODUCT}${searchParam}?page=${pageQuery}`,
        business && {
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
          },
        }
      );
      const data = await res.json();
      setSearchQuery(data);
    };
    fetchAPI();
  }, [business, pageQuery, searchParam]);

  let search: any;
  if (router.search === `?q=${searchParam}`) {
    search = searchQuery?.results?.filter((item: any) => {
      return Object?.keys(item)?.some((key: any) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(text?.toString().toLowerCase())
      );
    });
  } else {
    search = sort?.results?.filter((item: any) => {
      return Object?.keys(item)?.some((key: any) =>
        item[key]
          ?.toString()
          .toLowerCase()
          .includes(text?.toString().toLowerCase())
      );
    });
  }

  removeDuplicate = [...new Set(removeDuplicate)];
  if (removeDuplicate.length > 0 && text) {
    removeDuplicate.unshift("✔️ Keep Searching...");
  } else if (removeDuplicate.length === 0 && text && search.length === 0) {
    removeDuplicate.unshift("❌ Invalid Search");
  } else if (search?.length > 0 && removeDuplicate.length === 0) {
    removeDuplicate.unshift("✔️ Brand, Image, Product Name or Price Search");
  } else {
    removeDuplicate.unshift("✔️ List of Composition");
  }

  let pagination: any;

  if (paramURL.includes("category")) {
    pagination = (
      <Pagination
        page={page}
        count={Math.ceil(sort?.count / 100)}
        color="primary"
        shape="rounded"
        onChange={handlePageChange}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        renderItem={(item: any) => (
          <PaginationItem
            component={Link}
            to={`${param}${item.page === 1 ? "" : `?page=${item.page}`}`}
            {...item}
          />
        )}
      />
    );
  } else {
    pagination = (
      <Pagination
        page={page}
        count={Math.ceil(searchQuery?.count / 100)}
        color="primary"
        shape="rounded"
        onChange={handlePageChange}
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        renderItem={(item: any) => (
          <PaginationItem
            component={Link}
            to={`${param}?q=${searchParam}${
              item.page === 1 ? "" : `&page=${item.page}`
            }`}
            {...item}
          />
        )}
      />
    );
  }

  return (
    <Layout>
      <Box>
        <ProductListLayout setParams={setParams}>
          <Box>
            <Box
              sx={{
                overflowX: "auto",
                display: "flex",
                flexWrap: "nowrap",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {paramURL.includes("category") && (
                <Button
                  sx={{
                    mr: 2,
                    fontSize: "12px",
                    textTransform: "initial",
                    minWidth: "176px",
                    maxHeight: "33px",
                    backgroundColor: "#00000014",
                    color: "#000000de",
                    borderRadius: "16px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.12);",
                    },
                  }}
                  id="category-customized-button"
                  aria-controls={
                    openCategory ? "category-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openCategory ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleCategoryClick}
                  endIcon={
                    <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                  }
                  startIcon={<CategoryRounded sx={{ color: "#616161" }} />}
                >
                  Category
                </Button>
              )}
              <StyledMenu
                id="category-products"
                MenuListProps={{
                  "aria-labelledby": "category",
                }}
                anchorEl={anchorCategoryEl}
                open={openCategory}
                onClose={handleCategoryClose}
              >
                <FormControl
                  sx={{ padding: "16px", width: "400px", height: "300px" }}
                >
                  {categoryListing}
                </FormControl>
              </StyledMenu>

              <Button
                sx={{
                  mr: 2,
                  fontSize: "12px",
                  textTransform: "initial",
                  minWidth: "176px",
                  maxHeight: "33px",
                  backgroundColor: "#00000014",
                  color: "#000000de",
                  borderRadius: "16px",
                  "&:hover": {
                    backgroundColor: "rgba(0, 0, 0, 0.12);",
                  },
                }}
                id="composition-customized-button"
                aria-controls={
                  openCompositionInput
                    ? "composition-customized-menu"
                    : undefined
                }
                aria-haspopup="true"
                aria-expanded={openCompositionInput ? "true" : undefined}
                variant="contained"
                disableElevation
                onClick={handleCompositionInputClick}
                endIcon={
                  <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                }
                startIcon={<VaccinesRounded sx={{ color: "#616161" }} />}
              >
                Composition
              </Button>

              <StyledMenu
                id="composition-list"
                MenuListProps={{
                  "aria-labelledby": "composition-list",
                }}
                anchorEl={anchorCompositionInputEl}
                open={openCompositionInput}
                onClose={handleCompositionInputClose}
              >
                <Box
                  sx={{
                    width: "{ xs: 300, sm: 450, lg: 550, xl: 600 }",
                    minHeigth: 92.688,
                    maxHeight: 300,
                    overflowY: "auto",
                  }}
                >
                  <Box>
                    <Box sx={{ padding: "0 16px 6px 8px" }}>
                      <form onSubmit={handleSubmit}>
                        <TextField
                          sx={{ mb: -5 }}
                          placeholder="Search..."
                          value={`${text.toLowerCase()}`}
                          onChange={handleChange.bind(this)}
                        />
                      </form>
                    </Box>

                    {removeDuplicate[0] ===
                    "✔️ Brand, Image, Product Name or Price Search" ? (
                      <Box sx={{ marginTop: 6 }}>
                        <p
                          style={{
                            fontFamily: "Inter",
                            fontWeight: "400",
                            color: "#8692A6",
                            fontSize: "14px",
                            padding: "0 16px 6px 16px",
                          }}
                        >
                          ✔️ Brand, Image, Product Name or Price Search
                        </p>
                        {search?.map((item: any) => {
                          return (
                            <p
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "400",
                                color: "#8692A6",
                                fontSize: "14px",
                                padding: "0 16px 6px 16px",
                              }}
                            >
                              {item.name} &mdash; {priceFormat(item.price)}
                            </p>
                          );
                        })}
                      </Box>
                    ) : (
                      <Box sx={{ marginTop: 6 }}>
                        {removeDuplicate?.map((item: any) => {
                          return (
                            <p
                              style={{
                                fontFamily: "Inter",
                                fontWeight: "400",
                                color: "#8692A6",
                                fontSize: "14px",
                                padding: "0 16px 6px 16px",
                              }}
                            >
                              {item.toUpperCase()[0]}
                              {item.slice(1)}
                            </p>
                          );
                        })}
                      </Box>
                    )}
                  </Box>
                </Box>
              </StyledMenu>

              {paramURL.includes("category") && (
                <Button
                  sx={{
                    mr: 2,
                    fontSize: "12px",
                    textTransform: "initial",
                    minWidth: "176px",
                    maxHeight: "33px",
                    backgroundColor: "#00000014",
                    color: "#000000de",
                    borderRadius: "16px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.12);",
                    },
                  }}
                  id="price-customized-button"
                  aria-controls={
                    openPriceRange ? "price-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openPriceRange ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleRangePriceRangeClick}
                  endIcon={
                    <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                  }
                  startIcon={<Money sx={{ color: "#616161" }} />}
                >
                  Price Range
                </Button>
              )}
              <StyledMenu
                id="price-products"
                MenuListProps={{
                  "aria-labelledby": "price-range",
                }}
                anchorEl={anchorPriceRangeEl}
                open={openPriceRange}
                onClose={handlePriceRangeClose}
              >
                <Box
                  sx={{
                    width: "400px",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Slider
                    sx={{
                      width: 300,
                    }}
                    min={0}
                    max={maxPriceRange}
                    step={1}
                    getAriaLabel={() => "price range"}
                    value={priceRange}
                    onChange={handlePriceRangeChange}
                    valueLabelDisplay="auto"
                  />
                  <Box
                    sx={{
                      width: "313px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#8692A6",
                    }}
                  >
                    <div>{0}</div>
                    <div>{maxPriceRange}</div>
                  </Box>
                </Box>
              </StyledMenu>

              {paramURL.includes("category") && (
                <Button
                  sx={{
                    mr: 2,
                    fontSize: "12px",
                    textTransform: "initial",
                    minWidth: "176px",
                    maxHeight: "33px",
                    backgroundColor: "#00000014",
                    color: "#000000de",
                    borderRadius: "16px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.12);",
                    },
                  }}
                  id="quantity-customized-button"
                  aria-controls={
                    openQunatityRange ? "quantity-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openQunatityRange ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleRangeQunatityRangeClick}
                  endIcon={
                    <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                  }
                  startIcon={<Equalizer sx={{ color: "#616161" }} />}
                >
                  Qunatity Range
                </Button>
              )}
              <StyledMenu
                id="quantity-products"
                MenuListProps={{
                  "aria-labelledby": "quantity-range",
                }}
                anchorEl={anchorQunatityRangeEl}
                open={openQunatityRange}
                onClose={handleQuantityRangeClose}
              >
                <Box
                  sx={{
                    width: "400px",
                    height: "100px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Slider
                    sx={{
                      width: 300,
                    }}
                    min={0}
                    max={maxQuantityRange}
                    step={1}
                    getAriaLabel={() => "quantity range"}
                    value={quantityRange}
                    onChange={handleQuantityRangeChange}
                    valueLabelDisplay="auto"
                  />
                  <Box
                    sx={{
                      width: "313px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#8692A6",
                    }}
                  >
                    <div>{0}</div>
                    <div>{maxQuantityRange}</div>
                  </Box>
                </Box>
              </StyledMenu>

              {paramURL.includes("category") && (
                <Button
                  sx={{
                    mr: 2,
                    fontSize: "12px",
                    textTransform: "initial",
                    minWidth: "176px",
                    maxHeight: "33px",
                    backgroundColor: "#00000014",
                    color: "#000000de",
                    borderRadius: "16px",
                    "&:hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.12);",
                    },
                  }}
                  id="order-customized-button"
                  aria-controls={open ? "order-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleOrderByClick}
                  endIcon={
                    <KeyboardArrowDown sx={{ color: "rgba(0, 0, 0, 0.26)" }} />
                  }
                  startIcon={<SortOutlined sx={{ color: "#616161" }} />}
                >
                  Order by
                </Button>
              )}

              <StyledMenu
                id="order-products"
                MenuListProps={{
                  "aria-labelledby": "order",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <FormControl
                  sx={{ padding: "16px", width: "400px", height: "300px" }}
                >
                  {/*<FormLabel id="demo-radio-buttons-group-label">
                    Order By
              </FormLabel>*/}
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value=""
                      onChange={handleCreatedSortChange}
                      control={<Radio />}
                      label="Relevant Products"
                      checked={selected === ""}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="descComposition"
                      onChange={handleCompositionSortChange}
                      control={<Radio />}
                      label="Composition (A - Z)"
                      checked={selected === "descComposition"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="ascComposition"
                      onChange={handleCompositionSortChange}
                      control={<Radio />}
                      label="Composition (Z - A)"
                      checked={selected === "ascComposition"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="ascManufacturer"
                      onChange={handleManufacturerSortChange}
                      control={<Radio />}
                      label="Brand (A - Z)"
                      checked={selected === "ascManufacturer"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="descManufacturer"
                      onChange={handleManufacturerSortChange}
                      control={<Radio />}
                      label="Brand (Z - A)"
                      checked={selected === "descManufacturer"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="ascCreated"
                      onChange={handleCreatedSortChange}
                      control={<Radio />}
                      label="Newest Products"
                      checked={selected === "ascCreated"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="ascQuantity"
                      onChange={handleQuantitySortChange}
                      control={<Radio />}
                      label="Quantity: Low to High"
                      checked={selected === "ascQuantity"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="descQuantity"
                      onChange={handleQuantitySortChange}
                      control={<Radio />}
                      label="Quantity: High to Low"
                      checked={selected === "descQuantity"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="ascProductName"
                      onChange={handleProductNameSortChange}
                      control={<Radio />}
                      label="Product Name (A - Z)"
                      checked={selected === "ascProductName"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="descProductName"
                      onChange={handleProductNameSortChange}
                      control={<Radio />}
                      label="Product Name (Z - A)"
                      checked={selected === "descProductName"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="ascPrice"
                      onChange={handlePriceSortChange}
                      control={<Radio />}
                      label="Price: Low to High"
                      checked={selected === "ascPrice"}
                    />
                    <FormControlLabel
                      sx={{
                        color: "#8692a6",
                      }}
                      value="descPrice"
                      onChange={handlePriceSortChange}
                      control={<Radio />}
                      label="Price: High to Low"
                      checked={selected === "descPrice"}
                    />
                  </RadioGroup>
                </FormControl>
              </StyledMenu>
            </Box>

            <Divider sx={{ mt: 2, mb: 1 }} />

            {urlPath.includes("category") ? (
              <Content>
                results:{" "}
                {search?.length === 0
                  ? "No Product Available"
                  : search?.length === 1
                  ? `${search?.length} product`
                  : search?.length === undefined
                  ? "loading..."
                  : `${search?.length} products`}
              </Content>
            ) : (
              <Content>
                results:{" "}
                {searchQuery?.results.length === 0
                  ? "No Product Available"
                  : searchQuery?.results.length === 1
                  ? `${searchQuery?.results.length} product`
                  : searchQuery?.results.length === undefined
                  ? "loading..."
                  : `${searchQuery?.results.length} products`}
              </Content>
            )}

            {isLoading ? (
              <Grid sx={{ marginTop: 1 }} container spacing={3}>
                {slug !== undefined ? (
                  checkParams ||
                  (sort?.results.length > 0 && search?.length > 0) ? (
                    search.map((element: any, index: number) => (
                      <Grid key={index} item xs={6} sm={4} md={3}>
                        <Products
                          id={element?.id}
                          title={element?.name}
                          quantity={element?.quantity}
                          description={element?.desc}
                          trigger={path}
                          price={element?.price}
                          slug={element?.slug}
                          // sku={element?.SKU}
                          quantity_in_cart={element?.quantity_in_cart}
                          in_cart={element?.in_cart}
                          image={element?.image}
                          // category={element?.category}
                          is_favorite={element?.is_favorite}
                          manufacturer={element?.manufacturer}
                          // composition={element?.composition || text}
                        />
                      </Grid>
                    ))
                  ) : (
                    <Box
                      sx={{
                        width: "100%",
                        height: "359px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#8692A6",
                      }}
                    >
                      <Box>No Item Available On This Page</Box>
                      <Box>
                        <Button
                          variant="outlined"
                          startIcon={<Refresh />}
                          href={pathname}
                        >
                          Refresh
                        </Button>
                      </Box>
                      <Box>
                        {navigator.onLine ? null : (
                          <span style={{ color: "red", fontWeight: 300 }}>
                            Internet Connection Lost
                          </span>
                        )}
                      </Box>
                    </Box>
                  )
                ) : search?.length === 0 ||
                  searchQuery?.results.length === 0 ? (
                  <Box
                    sx={{
                      width: "100%",
                      height: "359px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#8692A6",
                      flexDirection: "column",
                      textAlign: "center",
                    }}
                  >
                    <Box>You Are In A Wrong Page &mdash; Search Is Invalid</Box>
                    <Box sx={{ fontSize: "13px" }}>
                      Hint: Click on a specific link from the search dropdown
                      list
                    </Box>
                  </Box>
                ) : (
                  // searchQuery?.results.map((element: any, index: number) => (
                  search?.map((element: any, index: number) => (
                    <Grid key={index} item xs={6} sm={4} md={3}>
                      <Products
                        id={element?.id}
                        title={element?.name}
                        quantity={element?.quantity}
                        description={element?.desc}
                        trigger={SEARCH_PRODUCT}
                        price={element?.price}
                        slug={element?.slug}
                        // sku={element?.SKU}
                        quantity_in_cart={element?.quantity_in_cart}
                        in_cart={element?.in_cart}
                        image={element?.image}
                        // category={element?.category}
                        is_favorite={element?.is_favorite}
                        manufacturer={element?.manufacturer}
                        // composition={element?.composition || text}
                      />
                    </Grid>
                  ))
                )}
              </Grid>
            ) : (
              <Loading />
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginLeft: "auto",
                marginRight: "auto",
                position: "relative",
                top:
                  sort?.results.length === 0 && search?.length === 0
                    ? "300px"
                    : "50px",
              }}
            >
              {/* {sort?.next
                ? pagination
                : sort?.previous
                ? pagination
                : searchQuery?.next
                ? pagination
                : searchQuery?.previous
                ? pagination
                : null} */}

              {sort?.next ? pagination : sort?.previous ? pagination : null}
            </Box>
          </Box>
        </ProductListLayout>
      </Box>
    </Layout>
  );
}

export default Results;
