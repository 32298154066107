import { useField } from "formik";
import { Forms } from "../models/_form.models";
import { Box } from "@mui/material";
import { optionsModel } from "../models/_options.models";
import { SelectField, TextareaField, TextInputField } from "evergreen-ui";
import { useConnect } from "../utilities/connectHooks";
import { SHIPPING_ADDRESS } from "../constants/_api.constant";

function Input({
  type = "Text",
  form_type = "text",
  disabled = false,
  ...props
}: Forms) {
  const [field, meta] = useField(props);

  const { data: addresses } = useConnect({ path: SHIPPING_ADDRESS });
  const newAddress = addresses?.results.find((item: any) => {
    return item.is_default === true;
  });

  return (
    <Box width="100%">
      {type === "Select" ? (
        <SelectField
          width="100%"
          isInvalid={meta.touched && meta.error !== undefined}
          validationMessage={meta.touched ? meta.error : null}
          inputHeight={44}
          label={props.label}
          {...field}
        >
          <option value="" defaultValue="" selected={false}>
            {/* {props.label === "Select Your Shipping Address*" &&
            newAddress?.is_default === true
              ? `${newAddress?.address_line1} ${newAddress?.region}`
              : props.placeholder} */}
            {props.placeholder}
          </option>
          {props?.options?.map((element: optionsModel, index: number) =>
            props.label === "Select Your Shipping Address*" ? (
              <option
                key={index}
                value={element.value}
                // selected={
                //   props.label === "Select Your Shipping Address*" &&
                //   index === addresses?.results.indexOf(newAddress)
                //     ? true
                //     : false
                // }
                style={{
                  display:
                    index === addresses?.results.indexOf(newAddress)
                      ? "block"
                      : "none",
                }}
              >
                {element.text}
              </option>
            ) : (
              <option key={index} value={element.value}>
                {element.text}
              </option>
            )
          )}
        </SelectField>
      ) : type === "TextArea" ? (
        <TextareaField
          isInvalid={meta.touched && meta.error !== undefined}
          validationMessage={meta.touched === true ? meta.error : null}
          disabled={disabled}
          inputHeight={70}
          {...field}
          width="100%"
          label={props.label}
          placeholder={props.placeholder}
        />
      ) : (
        <TextInputField
          isInvalid={meta.touched && meta.error !== undefined}
          validationMessage={meta.touched === true ? meta.error : null}
          disabled={disabled}
          type={form_type}
          inputHeight={40}
          {...field}
          width="100%"
          label={props.label}
          placeholder={props.placeholder}
        />
      )}
    </Box>
  );
}

export default Input;
