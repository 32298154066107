import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AuthLayout from "../components/AuthLayout.component";
import { Center, Content, Title } from "../styles";
import FormRender from "../forms/formRender";
import {
  RegisterForm1,
  RegisterForm2,
  RegisterForm3,
} from "../constants/_form.constant";
import {
  business_category,
  business_category2,
} from "../constants/_options.constant";
import { Validation } from "../forms/_validations";
import { Alert } from "evergreen-ui";
import { authenticate } from "../utilities/connectHooks";
import { REGISTER_USER } from "../constants/_api.constant";
import { useLocation } from "react-router-dom";

enum Steps {
  PersonalInfo = "PERSONAL_INFO",
  ChooseCategory = "CHOOSE_CATEGORY",
  BusinessInfo = "BUSINESS_INFO",
}

enum Category {
  Individual = "INDIVIDUAL",
  Professionals = "PROFESSIONALS",
}

function Register() {
  const [err, setErr] = useState([]);
  const [step, setSteps] = useState<Steps>(Steps.PersonalInfo);
  const [category, setCategory] = useState<Category>(Category.Professionals);

  const [personalInfo, setPersonalInfo] = useState({});
  const [businessInfo, setBusinessInfo] = useState({});

  const submittedData1 = (e: any) => {
    setPersonalInfo(e);
    setSteps(Steps.ChooseCategory);
  };

  const submittedData2 = (e: any) => {
    if (!("ref_code" in e)) {
      e["ref_code"] = "";
    }
    let data = Object.assign(personalInfo, e);
    authenticate(REGISTER_USER, data).then((resp) => {
      if (!resp.success) {
        setErr(resp.error);
      } else {
        window.location.href = "/account";
        setErr([]);
      }
    });
  };

  const {pathname} = useLocation()

  const pathArr = pathname?.split("/");
  const lastIndex = pathArr[pathArr.length - 1];
  const title = `${lastIndex[0].toUpperCase()}${lastIndex.slice(1)}`;

  useEffect(() => {
    document.title = `${title} | DrugStoc`;
  }, [title]);

  return (
    <AuthLayout>
      <Box>
        {step === Steps.PersonalInfo ? (
          <Content
            display="block"
            gutterBottom
            sx={{ width: "fit-content", marginLeft: "auto" }}
          >
            Already have an account?{" "}
            <Link underline="none" sx={{ cursor: "pointer" }} href="/login">
              Sign in
            </Link>
          </Content>
        ) : (
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between" }}
            spacing={2}
          >
            <Button
              variant="text"
              onClick={
                step === Steps.BusinessInfo
                  ? () => setSteps(Steps.ChooseCategory)
                  : step === Steps.ChooseCategory
                  ? () => setSteps(Steps.PersonalInfo)
                  : () => setSteps(Steps.PersonalInfo)
              }
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            <Box>
              <Content
                sx={{ fontSize: 12, fontWeight: "300", textAlign: "right" }}
              >
                STEP {step === Steps.ChooseCategory ? "02" : "03"} /03
              </Content>
              <Content>
                {step === Steps.ChooseCategory
                  ? "Your Category"
                  : "Business Info."}
              </Content>
            </Box>
          </Stack>
        )}
        <Center>
          <Title variant="h5">
            {step === Steps.ChooseCategory
              ? "Choose a category"
              : step === Steps.BusinessInfo
              ? "Complete Your Account Information"
              : "Join Us!"}
          </Title>
          <Box>
            <Stack>
              {err.map((element: any, index: number) => (
                <Alert intent="danger" key={index} title={element.message} />
              ))}
            </Stack>
          </Box>
          {step === Steps.ChooseCategory ? (
            <Stack spacing={3} mt={5} marginX="5%">
              <Card
                sx={{ maxWidth: { xs: "100%", md: "80%" } }}
                variant="outlined"
              >
                <CardActionArea
                  onClick={() => {
                    setCategory(Category.Individual);
                    setSteps(Steps.BusinessInfo);
                  }}
                >
                  <CardContent>
                    <Typography gutterBottom textAlign="center" variant="h6">
                      Licensed Health Worker
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign="center"
                      color="text.secondary"
                    >
                      (Pharmacist, Doctor, Nurse, etc.)
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              <Card
                sx={{ maxWidth: { xs: "100%", md: "80%" } }}
                variant="outlined"
              >
                <CardActionArea
                  onClick={() => {
                    setCategory(Category.Professionals);
                    setSteps(Steps.BusinessInfo);
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      textAlign="center"
                      variant="h6"
                      component="div"
                    >
                      Licensed Health Facility
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      textAlign="center"
                      color="text.secondary"
                    >
                      (Pharmacy, Hospital, Clinics, etc.)
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Stack>
          ) : (
            <Box marginY={4}>
              {step === Steps.PersonalInfo ? (
                <FormRender
                  data={RegisterForm1}
                  submitButtonText={"Continue"}
                  initialState={personalInfo}
                  validationSchema={Validation.registerValidation1}
                  fullButton
                  onSubmitForm={submittedData1}
                />
              ) : (
                <FormRender
                  data={
                    category === "INDIVIDUAL" ? RegisterForm3 : RegisterForm2
                  }
                  submitButtonText={"Submit"}
                  initialState={businessInfo}
                  validationSchema={Validation.registerValidation2}
                  options={{
                    name: "category",
                    data:
                      category === Category.Professionals
                        ? business_category
                        : business_category2,
                  }}
                  fullButton
                  onSubmitForm={submittedData2}
                />
              )}
            </Box>
          )}
        </Center>
      </Box>
    </AuthLayout>
  );
}

export default Register;
