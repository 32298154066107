import { Box, Breadcrumbs, Container, Grid } from "@mui/material";
import { Link as Ln } from "react-router-dom";
import React from "react";
import { USER_PROFILE } from "../constants/_api.constant";
import ElevationScroll from "../element/elevation";
import Sidebar from "../element/sidebar";
import { Content } from "../styles";
import { useConnect } from "../utilities/connectHooks";
import Navbar from "./Navbar.component";

interface Props {
  window?: () => Window;
  children: React.ReactElement;
}

function AccountLayout(props: Props) {
  const { data } = useConnect({ path: USER_PROFILE });

  return (
    <Box sx={{ flexGrow: 1 }}>
      <ElevationScroll {...props}>
        <Navbar />
      </ElevationScroll>
      <Container sx={{ marginTop: 2 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <Ln
            style={{
              cursor: "pointer",
              color: "inherit",
              textDecoration: "underline",
            }}
            to="/account"
          >
            My account
          </Ln>
          <Content sx={{ fontWeight: "500", color: "#000" }}>Home</Content>
        </Breadcrumbs>
        <Grid sx={{ marginTop: 5 }} container spacing={5}>
          <Grid
            item
            xs={3}
            sx={{
              borderRight: "1px solid #d7d7d7",
              display: { xs: "none", md: "block" },
            }}
          >
            <Sidebar user={data} />
          </Grid>
          <Grid item xs={12} md={9}>
            {props.children}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default AccountLayout;
