import axios from "axios";
import { toast } from "react-toastify";
import useSWR, { mutate } from "swr";
import {
  PRODUCT_BY_CATEGORY,
  PRODUCT_CATEGORY,
  PRODUCT_DETAIL,
  SEARCH_PRODUCT,
} from "../constants/_api.constant";

// axios.defaults.baseURL = "http://localhost:8000";
axios.defaults.baseURL = "https://staging.drugstoc.com";
axios.defaults.headers.common =
  localStorage.getItem("token") !== null
    ? { Authorization: `token ${localStorage.getItem("token")}` }
    : { Authorization: "" };

export interface Request {
  path: string;
  type?: "get" | "update" | "post";
  payload?: any;
}

interface Response {
  data: any;
  isFetching: boolean;
  hasError: string;
}

interface APIRequest {
  post: (e: {
    path: string;
    trigger: string;
    payload: object;
  }) => Promise<boolean>;
  patch: (e: {
    path: string;
    trigger: string;
    payload: object;
  }) => Promise<boolean>;
  delete: (e: { path: string; trigger: string }) => Promise<boolean>;
  verifyAccount: (bankCode: string, accountNumber: string) => Promise<any>;
}

const fetcher = (url: string) => axios.get(url).then((res) => res.data);

export function useConnect(request: Request): Response {
  const { data, error } = useSWR(request.path, fetcher, {
    dedupingInterval: 5000,
  });

  return {
    data: data,
    isFetching: !error && !data,
    hasError: error,
  };
}

export const authenticate = async (
  path: string,
  payload: object
): Promise<{ success: boolean; error: any }> => {
  const data = axios.post(path, payload, {
    headers: { "Content-Type": "application/json" },
  });
  try {
    let resp = await toast.promise(data, {
      pending: "Authenticating Please wait...",
      success: "Successful",
      error: "Request Unauthorized",
    });
    const { token } = resp.data;
    localStorage.setItem("token", token);
    return { success: true, error: null };
  } catch (error: any) {
    let response = getError(error?.response?.data);
    return { success: false, error: response };
  }
};

export const request: APIRequest = {
  post: async ({ path, trigger, payload }) => {
    const data = axios.post(path, payload, {
      headers: { "Content-Type": "application/json" },
    });
    try {
      let resp = await toast.promise(data, {
        // pending: "Please wait...",
        success: "Successful",
        // error: "Request Unauthorized",
      });
      mutate(trigger);
      console.log(resp.data);
      return true;
    } catch (error: any) {
      console.log(error?.response);
      return false;
    }
  },
  patch: async ({ path, trigger, payload }) => {
    const data = axios.patch(path, payload, {
      headers: { "Content-Type": "application/json" },
    });
    try {
      let resp = await toast.promise(data, {
        // pending: "Please wait...",
        // success: "Successful",
        // error: "Request Unauthorized",
      });
      mutate(trigger);
      console.log(resp.data);
      return true;
    } catch (error: any) {
      console.log(error?.response);
      return false;
    }
  },
  delete: async ({ path, trigger }) => {
    const data = axios.delete(path, {
      headers: { "Content-Type": "application/json" },
    });
    try {
      let resp = await toast.promise(data, {
        pending: "Please wait...",
        success: "Successful",
        error: "Request Unauthorized",
      });
      mutate(trigger);
      console.log(resp.data);
      return true;
    } catch (error: any) {
      return false;
    }
  },
  verifyAccount: async (bankCode, accountName) => {
    const data = axios.post(
      "/api/v2/drugstocpay/transaction/utils/account/verify",
      { bank_code: bankCode, account_number: accountName },
      {
        headers: { "Content-Type": "application/json" },
      }
    );
    try {
      let resp = await toast.promise(data, {
        pending: "Please wait...",
        success: "Successful",
        error: "Request Unauthorized",
      });
      console.log(resp.data);
      return resp.data;
    } catch (error: any) {
      console.log(error?.response);
      return null;
    }
  },
};

export const getResultUrl = (route: any): { url: string } => {
  let url = "";
  let res = route.pathname.split("/");

  switch (res[1]) {
    case "category":
      url = PRODUCT_BY_CATEGORY + res[2];
      break;
    case "products":
      url = PRODUCT_DETAIL + res[2];
      break;
    case "search":
      url = SEARCH_PRODUCT + route.search.replace("?q=", "");
      break;
    default:
      url = "";
  }

  return {
    url,
  };
};

export const getFilterResultUrl = (route: string): { url: string } => {
  let url = "";
  let res = route;

  switch (res) {
    case "category":
      url = PRODUCT_CATEGORY;
      break;
    default:
      url = "";
  }

  return {
    url,
  };
};

const getError = (response: any) => {
  let err: any = Object.entries(response);
  let errResponse: any = [];
  for (let i = 0; i < err.length; i++) {
    let obj = {
      type: err[i][0],
      message: err[i][1][0],
    };
    errResponse.push(obj);
  }
  return errResponse;
};
