import styled from "@emotion/styled";
import { FavoriteBorder, AddShoppingCart } from "@mui/icons-material";
import { Box, Divider, Stack, TextField, Tooltip, Alert } from "@mui/material";
import { FC, useEffect, /* useRef */ useState, memo } from "react";
import { Link as Ln, Outlet, useParams } from "react-router-dom";
import {
  BUSINESS_PROFILE,
  CART_ITEMS,
  FAVORITE_PRODUCT,
} from "../constants/_api.constant";
import { Delete, Favorite } from "../element/icons";
import {
  AddToCartButton,
  Content,
  ProductsContainer,
  Title,
  IconButton,
} from "../styles";
import { request, useConnect } from "../utilities/connectHooks";
import { priceFormat } from "../utilities/priceFormatter";
import { toast } from "react-toastify";
// import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

interface Props {
  quantity_in_cart?: any;
  is_favorite?: boolean;
  in_cart?: any;
  id?: any;
  trigger?: string;
  addItemToCart?: (arg0: { quantity: number; product_id: any }) => Promise<any>;
  loadCartItem?: () => void;
  addItemToFav?: (arg0: { product_id: any }) => Promise<any>;
  deleteItemToFav?: (arg0: any) => Promise<any>;
  updateItemInCart?: (
    arg0?: { quantity: any; product_id: any },
    arg1?: any
  ) => Promise<any>;
  deleteItemInCart?: (arg0: any) => Promise<any>;
  slug?: any;
  image?: any;
  title?: any;
  price?: any;
  description?: any;
  quantity?: number;
  in_fav?: boolean;
  href?: any;
  name?: any;
  manufacturer?: any;
  composition?: any;
}

const Products: FC<Props> = (props: Props) => {
  const [loading_increase, setLoadingIncrease] = useState(false);
  const [inFav, setFav] = useState(props.is_favorite);
  const [inCart, setCart] = useState(props.in_cart);
  const [loading_cart, setLoadingCart] = useState(false);
  const [loading_fav, setLoadingFav] = useState(false);
  const [loading_del, setLoadingDelete] = useState(false);
  const [trackerEvent, setTrackerEvent] = useState<any>();
  const [trackerProduct, setTrackerProduct] = useState();
  const [action, setAction] = useState<string>("view");
  const [product, setProduct] = useState<number>(0);
  const { data: verify } = useConnect({ path: BUSINESS_PROFILE });
  const [value, setValue] = useState(props.quantity_in_cart);
  const [alertMsg, setAlertMsg] = useState("");
  // const [data, setData] = useState<any>();
  const { slug } = useParams();
  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  // useEffect(() => {
  //   const fetchAPI = async () => {
  //     const response = await fetch(
  //       `https://staging/drugstoc.com/${BUSINESS_PROFILE}`
  //     );
  //     const data: any = await response.json();
  //     setData(data);
  //   };
  //   fetchAPI();
  // }, [props.slug, slug]);

  const message = "Not allowed";
  const { pathname } = useLocation();
  const toArrPathname = pathname.split("/");
  let currentPage = "";
  if (toArrPathname.includes("manufacturer")) {
    currentPage = "Manufacturer page";
  } else if (toArrPathname.includes("category")) {
    currentPage = "Category page";
  } else if (toArrPathname.includes("products")) {
    currentPage = "Product Detail Page";
  } else if (toArrPathname.includes("favorite")) {
    currentPage = "Favorite Page";
  } else {
    currentPage = "Home Page";
  }
  const { data: profile } = useConnect({
    path: "https://staging.drugstoc.com/api/v2/account/profile",
  });

  const AddToCart = styled(AddToCartButton)({
    textTransform: "initial",
  });
  const buttonStyles = {
    fontSize: { xs: 8, sm: 10, xl: 14 },
    fontWeight: "900",
    whiteSpace: "pre",
    width: "50%",
  };

  // const alert = () => {
  //   <Alert
  //     variant="filled"
  //     severity="info"
  //     sx={{
  //       position: "fixed",
  //       left: 10,
  //       bottom: 10,
  //       zIndex: 5,
  //       width: "auto",
  //       textDecoration: "underline",
  //     }}
  //   >
  //     <Link href="/account/business">
  //       {alertMsg}
  //     </Link>
  //   </Alert>;
  // };

  // useEffect(() => {
  //   const timer: any = profile?.is_verified ? setTimeout(() => alert, 1000): null;
  //   return () => clearTimeout(timer);
  // }, [profile?.is_verified, setAlertMsg]);

  // console.log(profile)

  function add_to_cart() {
    let data = {
      quantity: 1,
      product_id: props.id,
    };
    setLoadingCart(true);
    setAlertMsg(
      "Verify Account To Get Unlimited Access - Call +234(0) 1 7005571"
    );

    request
      .post({ path: CART_ITEMS, trigger: CART_ITEMS, payload: data })
      .then((res) => {
        if (res) {
          setLoadingIncrease(false);
          setLoadingCart(false);
          setCart(true);
          setAction("add_to_cart");
          setProduct(data.product_id);
        } else {
          setLoadingCart(false);
        }
      });
  }

  function add_to_fav() {
    let data = {
      product_id: props.id,
    };
    request
      .post({ path: FAVORITE_PRODUCT, trigger: "", payload: data })
      .then(() => {
        setFav(true);
        setLoadingFav(false);
        setAction("add_to_favorite");
        setProduct(data.product_id);
        const customId = "custom-id-yes";
        try {
          toast(profile === undefined ? message : `Added to favorite page`, {
            toastId: customId,
          });
        } catch {
          console.log(null);
        }
      });
  }

  function delete_fav() {
    setLoadingDelete(true);
    request
      .delete({
        path: `${FAVORITE_PRODUCT}/${props.id}`,
        trigger: FAVORITE_PRODUCT,
      })
      .then(() => {
        setLoadingDelete(false);
        setAction("delete_fav");
        setProduct(verify.product_id);
      });
  }

  function increase_item() {
    setLoadingIncrease(true);
    let data = {
      quantity: value,
      product_id: props.id,
    };
    request
      .patch({
        path: `${CART_ITEMS}/${props.id}`,
        trigger: CART_ITEMS,
        payload: data,
      })
      .then((resp) => {
        if (resp) {
          setValue(value);
        }
        setLoadingIncrease(false);
        setAction("add_to_cart");
        setProduct(data.product_id);
        // setAlertMsg('Verify Account To Get Unlimited Access - Call +234(0) 1 7005571');
      });
    const customId = "custom-id-yes";

    // if (value > 1) {
    try {
      toast(
        profile === undefined
          ? message
          : // : profile.is_verified === false ? "Verify Account To Add to Cart"
            `${value === undefined ? 1 : value} quantities added to Cart!`,
        {
          toastId: customId,
        }
      );
    } catch {
      console.log(null);
    }
  }
  // else if (value !== 0) {
  //   try {
  //     toast(
  //       profile === undefined
  //         ? message
  //         : `${value} quantities added to Cart!`,
  //       {
  //         toastId: customId,
  //       }
  //     );
  //   } catch {
  //     console.log(null);
  //   }
  // }

  //
  let previousPage = document.referrer;
  const toArrPage = previousPage.split("/");
  if (toArrPage === null && "view") {
    previousPage = "Cart Page";
  } else if (toArrPage.includes("manufacturer") && action === "view") {
    previousPage = "Manufacturer Page";
  } else if (toArrPage.includes("category") && action === "view") {
    previousPage = "Category page";
  } else if (toArrPage.includes("products") && action === "view") {
    previousPage = "Product Detail Page";
  } else if (toArrPage.includes("favorite") && action === "view") {
    previousPage = "Favorite Page";
  } else if (toArrPage[toArrPage.length - 1] === "" && action === "view") {
    previousPage = "Home Page";
  } else {
    previousPage = "Event: Click Event";
  }

  useEffect(() => {
    const fetchTrackerProduct = async () => {
      const trackerProductData = {
        action,
        product,
      };
      const response = await fetch(
        `https://staging.drugstoc.com/api/v2/activity/tracking/products-tracking`,
        {
          method: "POST",
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
            "Content-type": "application/json",
          },
          body: JSON.stringify(trackerProductData),
        }
      );
      const data = await response.json();
      setTrackerProduct(data);
    };
    fetchTrackerProduct();
  }, [action, product]);

  useEffect(() => {
    const fetchTrackerEvents = async () => {
      const trackerEventData = {
        device_type: "web",
        app_page: `Current Page: ${currentPage}`,
        action,
        action_property:
          action !== "view"
            ? "Event: Click Event"
            : `Previous Page: ${previousPage}`,
      };
      const response = await fetch(
        `https://staging.drugstoc.com/api/v2/activity/tracking/event-tracking`,
        {
          method: "POST",
          headers: {
            Authorization: `token ${localStorage.getItem("token")}`,
            "Content-type": "application/json",
          },
          body: JSON.stringify(trackerEventData),
        }
      );
      const data = await response.json();
      setTrackerEvent(data);
    };

    fetchTrackerEvents();
  }, [action, currentPage, previousPage]);

  return (
    <ProductsContainer
      sx={{
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
        borderRadius: 2,
        width: { xs: "calc(100% + 19px)", md: "100%" },
        marginLeft: -1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        position: "relative",
        marginTop: 0,
      }}
    >
      {verify?.is_verified ? (
        props.quantity === 0 ? (
          <Tooltip title="No item available" followCursor>
            <Ln to={`/products/${props.slug}`}>
              <Box
                component="img"
                loading="lazy"
                sx={{
                  height: { xs: 100, sm: 150 },
                  width: "100%",
                  cursor: "pointer",
                  objectFit: "contain",
                }}
                alt={`${props.description} from ${props.manufacturer}`}
                src={
                  props.image
                    ? props.image
                    : "https://cdn.dribbble.com/users/2224455/screenshots/4428927/loading.gif"
                }
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              />
              <Outlet />
            </Ln>
          </Tooltip>
        ) : (
          <Tooltip title={`${props.quantity} items available`} followCursor>
            <Ln to={`/products/${props.slug}`}>
              <Box
                component="img"
                loading="lazy"
                sx={{
                  height: { xs: 100, sm: 150 },
                  width: "100%",
                  cursor: "pointer",
                  objectFit: "contain",
                }}
                alt={`${props.description} from ${props.manufacturer}`}
                src={
                  props.image
                    ? props.image
                    : "https://cdn.dribbble.com/users/2224455/screenshots/4428927/loading.gif"
                }
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
              />
              <Outlet />
            </Ln>
          </Tooltip>
        )
      ) : (
        <Tooltip title="View alternative item(s)" followCursor>
          <Ln to={`/products/${props.slug}`}>
            <Box
              component="img"
              loading="lazy"
              sx={{
                height: { xs: 100, sm: 150 },
                width: "100%",
                cursor: "pointer",
                objectFit: "contain",
              }}
              alt={`${props.description} from ${props.manufacturer}`}
              src={
                props.image
                  ? props.image
                  : "https://cdn.dribbble.com/users/2224455/screenshots/4428927/loading.gif"
              }
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              }}
            />
            <Outlet />
          </Ln>
        </Tooltip>
      )}

      <Box sx={{ padding: 2 }}>
        <Content
          sx={{
            fontSize: 12,
            textOverflow: "ellipsis",
            overflow: "hidden",
            color: "#000",
            whiteSpace: "nowrap",
          }}
        >
          {props.title}
        </Content>
        <Divider sx={{ marginTop: 0.5 }} />
        <Title
          variant="h6"
          sx={{
            filter: props.price ? "blur(0px)" : "blur(5px)",
            fontSize: { xs: 10, sm: 13, md: 14, xl: "1.25rem" },
          }}
        >
          {props.price ? priceFormat(props.price) : "PRICE NOT AVAILABLE"}
        </Title>
        <Divider sx={{ marginBottom: 0.5 }} />
        <Content
          sx={{
            fontSize: 10,
            textOverflow: "ellipsis",
            overflow: "hidden",
            height: 30,
          }}
        >
          {props.description}
        </Content>

        {props.manufacturer === null ? (
          <Content
            sx={{
              fontSize: 10,
              mt: 1,
              textOverflow: "ellipsis",
              overflow: "hidden",
              height: 30,
            }}
          >
            Brand: Unknown
          </Content>
        ) : pathname.split("/").includes("manufacturer") ? null : (
          <Content
            sx={{
              fontSize: 10,
              mt: 1,
              textOverflow: "ellipsis",
              overflow: "hidden",
              height: 30,
            }}
          >
            Brand: {props.manufacturer}
          </Content>
        )}

        <Stack
          direction="row"
          sx={{
            marginTop: 2,
            height: 40,
            mx: "auto",
            justifyContent: "space-Between",
            display: "flex",
            alignItems: "center",
            xs: {
              position: "relative",
              right: "6px",
              sm: {
                position: "static",
              },
            },
          }}
          spacing={1}
        >
          {(!inCart && !value) || props?.quantity === 0 ? (
            <AddToCart
              variant="contained"
              disabled={props.quantity === 0}
              onClick={add_to_cart}
              loading={loading_cart}
              sx={buttonStyles}
              disableElevation
            >
              <span>
                {props.quantity === 0 ? "Out of Stock" : "Add to cart"}
              </span>
            </AddToCart>
          ) : (
            <Stack
              direction="row"
              justifyContent="space-between"
              spacing={3}
              sx={{
                width: { xs: "100%", sm: "100%" },
                position: "relative",
              }}
            >
              <Tooltip title="Add to Cart">
                <IconButton
                  onClick={increase_item}
                  variant="contained"
                  sx={{
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    position: "relative",
                    "&:hover": {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  }}
                >
                  <AddShoppingCart
                    onClick={increase_item}
                    sx={{
                      width: "30px",
                      height: "30px",
                      color: "#51b0e2",
                      marginRight: "-24px",
                      "&:active": {
                        backgroundColor: "#f0f0f0",
                        boxShadow: "none",
                      },
                    }}
                  />
                </IconButton>
              </Tooltip>
              <TextField
                id="outlined-number"
                // sx={{
                //   display: profile.is_verified === false ? "none" : "block",
                // }}
                type="number"
                variant="standard"
                InputProps={{
                  inputProps: {
                    min: "1",
                    max: `${props?.quantity}`,
                    step: "1",
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder={`${profile === undefined ? message : ""}`}
                // defaultValue={props?.quantity_in_cart}
                value={`${!value ? 1 : props?.quantity_in_cart || value}`}
                onChange={handleChange}
              />
            </Stack>
          )}
          {!props.in_fav ? (
            <Tooltip title="Add to Favorites">
              <IconButton
                onClick={add_to_fav}
                loading={loading_fav}
                disabled={props.in_fav || inFav}
                variant="outlined"
                sx={{
                  width: "auto",
                  height: "37.5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span>
                  {props.is_favorite || inFav ? (
                    <Favorite sx={{ position: "relative", top: "3px" }} />
                  ) : (
                    <FavoriteBorder sx={{ position: "relative", top: "3px" }} />
                  )}
                </span>
              </IconButton>
            </Tooltip>
          ) : (
            <IconButton
              onClick={delete_fav}
              loading={loading_del}
              color="error"
              variant="outlined"
            >
              <Delete />
            </IconButton>
          )}
        </Stack>
      </Box>
    </ProductsContainer>
  );
};

export default memo(Products);
